import { useState } from 'react';
import { Spin, Row, Col, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { URL_GET_NON_RELIABLE_APPLICATIONS } from '../../api/application/constants';
import { useDataForApplication } from '../../api/application/use-get-data-for-application';
import { NonReliableCitizenInfo } from './components/applications/non-reliable-citizen-info';
import { HeaderNonReliable } from './components/header-non-relaible';
// import { Header as LayoutHeader } from '../../components/layouts/header';
import { ReactComponent as Gerb } from '../../icons/gerb.svg';

const Wrapper = styled.div`
  width: 21cm;
  background: white;
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 17px;
  font: 12pt 'Sylfaen';
  border: 6px solid #456fae;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const InnerWrapper = styled.div`
  background: #ffffff;
  border: 2px solid #456fae;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  padding: 30px;
  box-sizing: border-box;
  .ant-layout-header {
    padding: 0px;
  }
`;

export const NonReliableApplicationPrint = () => {
  const params = useParams();
  const [result, setResult] = useState([]);

  const { isLoading } = useDataForApplication(
    URL_GET_NON_RELIABLE_APPLICATIONS,
    { applicationId: params.id },
    {
      onSuccess: (data) => {
        setResult(data);
      },
      enabled: !!params.id,
    }
  );
  return (
    <Spin spinning={isLoading}>
      <Wrapper className="print">
        <InnerWrapper className="page">
          <Gerb />
          <HeaderNonReliable
            number={` ԴԻՄՈՒՄ  N ${result.num}`}
            createdDate={result.createdDate}
            title="ԱՆՀՈՒՍԱԼԻ ՎԱՐԿԵՐ ՈՒՆԵՑՈՂ ԱՆՁԱՆՑ ՏՆՏԵՍԱԿԱՆ ԱԿՏԻՎՈՒԹՅԱՆ ԽԹԱՆՄԱՆ ԱՋԱԿՑՈՒԹՅՈՒՆ ԾՐԱԳԻՐ"
          />
          <>
            <NonReliableCitizenInfo data={result} />
          </>
          <Row justify="space-between">
            <Col>
              <Typography.Text strong>{result.createdDate}</Typography.Text>
            </Col>
            <Col>
              <Typography.Text strong>www.e-social.am</Typography.Text>
            </Col>
          </Row>
        </InnerWrapper>
      </Wrapper>
    </Spin>
  );
};
