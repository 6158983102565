import {
  CalendarOutlined,
  FileOutlined,
  SettingOutlined,
  CopyOutlined,
  BellOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  GlobalOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';

import { PATHS } from '../../helpers/constants';

export const MENU_TYPES = {
  SERVICES: {
    label: 'Ծառայություններ',
    url: `${PATHS.SERVICES}`,
    icon: <FileOutlined />,
  },
  APPLICATIONS: {
    label: 'Իմ դիմումները',
    url: `${PATHS.APPLICATIONS}`,
    icon: <FileDoneOutlined />,
  },
  MAP: {
    label: 'Քարտեզ',
    url: `${PATHS.MAPS}`,
    icon: <GlobalOutlined />,
  },
  SETTINGS: {
    label: 'Կարգավորումներ',
    url: `${PATHS.SETTINGS}`,
    icon: <SettingOutlined />,
  },
  NOTIFICATIONS: {
    label: 'Ծանուցումներ',
    url: `${PATHS.NOTIFICATIONS}`,
    icon: <BellOutlined />,
  },
  CALENDAR: {
    label: 'Օրացույց',
    url: `${PATHS.CALENDAR}`,
    icon: <CalendarOutlined />,
  },
  AGREEEMENTS: {
    label: 'Իմ համաձայնագրերը',
    url: `${PATHS.AGREEEMENTS}`,
    icon: <FileDoneOutlined />,
  },
  SELF_ASSESSMENT: {
    label: 'Ինքնագնահատում',
    url: `${PATHS.SELF_ASSESSMENT}`,
    icon: <CopyOutlined />,
  },
};

export const COMPANY_MENU_TYPES = {
  SERVICES: {
    label: 'Ծառայություններ',
    url: `${PATHS.SERVICES}`,
    icon: <FileTextOutlined />,
  },
  PROGRAMS: {
    label: 'Իմ դիմումները',
    url: `${PATHS.APPLICATIONS}`,
    icon: <FileDoneOutlined />,
  },
  MAP: {
    label: 'Քարտեզ',
    url: `${PATHS.MAPS}`,
    icon: <GlobalOutlined />,
  },
  AUTHORITY: {
    label: 'Լիազորություն',
    url: `${PATHS.AUTHORITY}`,
    icon: <UserSwitchOutlined />,
  },
  NOTIFICATIONS: {
    label: 'Ծանուցումներ',
    url: `${PATHS.NOTIFICATIONS}`,
    icon: <BellOutlined />,
  },
  AGREEEMENTS: {
    label: 'Իմ Համաձայնագրերը',
    url: `${PATHS.AGREEEMENTS}`,
    icon: <FileDoneOutlined />,
  },
  SETTINGS: {
    label: 'Կարգավորումներ',
    url: `${PATHS.SETTINGS}`,
    icon: <SettingOutlined />,
  },
};
