import { useWatch } from 'antd/es/form/Form';

import { formItemActualAddress, formItemRegAddress } from '../constnats';
import { Text } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { Form, Radio } from 'antd';
import { DIPersonAddress } from 'pages/rapid-need/components/person-address-form';
import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { VerticalSpace } from 'components/space/vertical-space';

export const DonorAddress = ({ data, isLegal }) => {
  const doAddressesMatch = useWatch(['mainData', 'isRegAddressActual'], {
    preserve: true,
  });
  return (
    <VerticalSpace>
      <DIPersonAddress
        formItemAddress={formItemRegAddress}
        isActualAddress={false}
        viewMode={true}
        data={isLegal ? data.address : data.registrationAddress}
        title={isLegal ? 'Իրավաբանական հասցե' : 'Հաշվառման հասցե'}
        notRequired={true}
      />
      <FormSectionFullCard>
        <Form.Item
          label={
            <Text color={COLORS.PRIMARY.BLUE}>
              {isLegal
                ? ' Իրավաբանական հասցեն համընկնում է գործունեության հասցեի հետ'
                : 'Հաշվառման հասցեն համընկնում է փաստացի բնակության հասցեի հետ'}
            </Text>
          }
          name={['mainData', 'isRegAddressActual']}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </Form.Item>
      </FormSectionFullCard>
      {doAddressesMatch !== undefined && (
        <DIPersonAddress
          formItemAddress={doAddressesMatch ? formItemRegAddress : formItemActualAddress}
          isActualAddress={true}
          viewMode={doAddressesMatch ? true : false}
          title={isLegal ? 'Կազմակերպության գործունեության հասցե' : 'Փաստացի բնակության հասցե'}
          doAddressesMatch={doAddressesMatch}
          isView={false}
          notRequired={true}
        />
      )}
    </VerticalSpace>
  );
};
