import { Col, Divider, Form, Input, Modal, Row, Select } from 'antd';
import { URL_GET_CITIZEN_FOR_LEGAL } from 'api/benefit/constants';
import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { Text, Title } from 'components/typography';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';
import { useMemo } from 'react';
import { DisabilityPersonDataSearchForm, formItemProp, onReset } from './disability-person-data-search-form';
import { ArmenianInput } from 'components/input/armenian-input';
import { Datepicker } from 'components/datepicker';
import { COLORS, PATHS, armenianLettersPattern } from 'helpers/constants';
import dayjs from 'dayjs';
import { WarningSection } from 'components/card/warning-section';
import { PhoneNumberFormItem } from 'components/form-item/phone-number-form-item';
import { useNavigate, useParams } from 'react-router-dom';

const armenianPattern = [
  {
    pattern: armenianLettersPattern,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
];

const getTitleText = (subtypeId) => {
  switch (subtypeId) {
    case '7':
    case '8':
    case '9':
    case '11':
      return 'Բողոքարկող անձի տվյալներ';
    case '10':
      return 'Դիմումատուի տվյալներ';
    default:
      return 'Գնահատվող անձի տվյալներ';
  }
};

export const DisabilityPersonData = () => {
  const navigate = useNavigate();
  const params = useParams();
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const documents = Form.useWatch(['applicationForm', 'documents'], { preserve: true });
  const form = Form.useFormInstance();
  const isApprovedApplicant = Form.useWatch([...(formItemProp?.isApproved?.name || [])], { preserve: true });
  const isDisabled = useMemo(() => applicantType === APPLICATION_FOR_HIMSELF, [applicantType]);

  return (
    <VerticalSpace>
      <Title level={2} color="#0B847F">
        {getTitleText(params.subtypeId)}
      </Title>
      <VerticalSpace size="small">
        <Row justify="space-between">
          <Col span={7}>
            <FormItem
              name={['applicationForm', 'firstName']}
              label="Անուն"
              rules={[{ required: true }, ...armenianPattern]}
            >
              <ArmenianInput disabled={isDisabled || isApprovedApplicant} />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem
              name={['applicationForm', 'lastName']}
              label="Ազգանուն"
              rules={[{ required: true }, ...armenianPattern]}
            >
              <ArmenianInput disabled={isDisabled || isApprovedApplicant} />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem
              name={['applicationForm', 'patronymicName']}
              label="Հայրանուն"
              rules={[{ required: true }, ...armenianPattern]}
            >
              <ArmenianInput disabled={isDisabled || isApprovedApplicant} />
            </FormItem>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={7}>
            <FormItem
              name={['applicationForm', 'ssn']}
              label="ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանք"
              rules={[
                { required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === getFieldValue(['applicationForm', 'legalRepresentative', 'ssn'])) {
                      return Promise.reject(
                        new Error('Դիմումատուն և Օրինական ներկայուցուցչը չեն կարող լինել նույն մարդը')
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input disabled={isDisabled || isApprovedApplicant} />
            </FormItem>
          </Col>
          {isDisabled && (
            <Col span={7}>
              <FormItem
                name={['applicationForm', 'gender']}
                label="Սեռ"
                rules={[{ required: true }, ...armenianPattern]}
              >
                <Input disabled />
              </FormItem>
            </Col>
          )}
          <Col span={7}>
            <FormItem name={['applicationForm', 'birthDate']} label="Ծննդյան ամսաթիվ " rules={[{ required: true }]}>
              <Datepicker disabled={isDisabled} style={{ width: '100%' }} format="DD/MM/YYYY" />
            </FormItem>
          </Col>
        </Row>
        {!isDisabled && (
          <Row justify="center">
            <Col span={7}>
              <DisabilityPersonDataSearchForm
                url={URL_GET_CITIZEN_FOR_LEGAL}
                onSuccess={(data) => {

                  let messageContent;

                  if (
                    (form.getFieldValue(['applicationForm', 'likeLegalRepresentativeName'])?.value === 1 && data.age >= 16)
                  ) {
                    messageContent = 'Դիմումատուի տարիքը բարձր է 16-ից։ Խնդրում ենք վերանայել լրացված տվյալները։';
                  } else if (
                    (form.getFieldValue(['applicationForm', 'likeLegalRepresentativeName'])?.value === 2 && data.age < 16)
                  ) {
                    messageContent = 'Դիմումատուի տարիքը ցածր է 16-ից։ Խնդրում ենք վերանայել լրացված տվյալները։';
                  }

                  if (messageContent) {
                    Modal.warning({
                      title: 'Ուշադրություն',
                      onCancel: () => navigate(PATHS.DISABILITY_TYPE),
                      onOk: () => navigate(PATHS.DISABILITY_TYPE),
                      content: (
                        <div>
                          <p>{messageContent}</p>
                        </div>
                      ),
                    });
                    onReset(form);
                    return false;
                  }

                  form.setFieldsValue({
                    applicationForm: {
                      ...data,
                      validSSN: data.ssn,
                      birthDate: dayjs(data.birthdate),
                      gender: data.sex,
                      ...(data.documents.length === 1
                        ? {
                            documentType: data.documents[0].documentType,
                            documentNumber: data.documents[0].documentNumber,
                          }
                        : {}),
                    },
                    // registrationAddress: data.registrationAddress,
                  });
                  return true;
                }}
                onSelect={() => {}}
              />
            </Col>
          </Row>
        )}
        {applicantType && !isDisabled && (
          <WarningSection>
            <Text color={COLORS.SECONDARY.GRAY_DARK}>
              Նույնականացման տվյալները անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան
            </Text>
          </WarningSection>
        )}
        <Row justify="space-between">
          <Col span={11}>
            <PhoneNumberFormItem name={['applicationForm', 'phoneNumber']} rules={[{ required: true }]} />
          </Col>
          <Col span={11}>
            <FormItem name={['applicationForm', 'email']} label="Էլեկտրոնային փոստի հասցե ">
              <Input disabled={isDisabled} />
            </FormItem>
          </Col>
        </Row>
      </VerticalSpace>
      <Divider />
      <Title level={2} color="#0B847F">
        Անձը հաստատող փաստաթուղթ
      </Title>
      <Row justify="space-between">
        <Col span={10}>
          <FormItem name={['applicationForm', 'documentType']} label="ԱՀՓ տեսակ" rules={[{ required: true }]}>
            <Select
              options={documents}
              labelInValue
              fieldNames={{ label: 'documentType', value: 'documentNumber' }}
              onChange={(option) => {
                form.setFieldsValue({
                  applicationForm: {
                    documentNumber: option.value,
                  },
                });
              }}
            ></Select>
          </FormItem>
        </Col>
        <Col span={10}>
          <FormItem name={['applicationForm', 'documentNumber']} label="ԱՀՓ համար" rules={[{ required: true }]}>
            <Input disabled={isDisabled} />
          </FormItem>
        </Col>
      </Row>
    </VerticalSpace>
  );
};
