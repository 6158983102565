import { Select, Skeleton } from 'antd';
import { useGetFfromBenefit } from '../../api/benefit/use-get-dictionary';
import { URL_GET_USER_COMPANIES } from 'api/urrp/constants';
import { useAuth } from 'context/auth-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from 'helpers/constants';

export const UserCompanySelect = (props) => {
  const { data, isInitialLoading } = useGetFfromBenefit(URL_GET_USER_COMPANIES);
  const { user, addUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  if (isInitialLoading) {
    return <Skeleton.Input active style={{ width: '350px', margin: '25px 0px 15px 0px' }} />;
  }

  const redirectTo = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const handleChange = (e) => {
    const userData = data.find((profile) => {
      return profile.companyId === e || profile.profileId === e;
    });

    addUser({
      ...user,
      ...userData,
      isLegal: userData?.companyId || userData?.isRepresentative ? true : false,
    });
    redirectTo(PATHS.SERVICES);
  };

  const options = data.map((person) => ({
    label: person?.fullName || person?.companyName,
    value: person?.profileId || person?.companyId,
  }));

  const getValue = () => {
    if (user?.isLegal && !user.isRepresentative) return user.companyId;
    return user?.profileId;
  };

  return (
    <Select
      defaultValue={user?.profileId}
      value={getValue()}
      style={{ width: '350px', margin: '25px 0px 15px 0px' }}
      options={options}
      {...props}
      onChange={handleChange}
    />
  );
};
