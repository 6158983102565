import { VerticalSpace } from 'components/space/vertical-space';
import { ContactForm } from '../components/contact-form';
import { ProgramInfo } from '../components/program-info';
import { SupportInfo } from '../components/support-info';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_CITIZEN_INFO } from 'api/application/constants';
import { Spin } from 'antd';
import { useMatches } from 'react-router-dom';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';
import { ApplicationDetails } from '../components/application-details';
import { useAuth } from 'context/auth-context';
import { CompanyContactForm } from '../components/company-contact-form';

export const FirstStep = () => {
  const { data: userData, isLoading } = useGetFfromBenefit(URL_CITIZEN_INFO);
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;
  const {
    user: { isLegal },
  } = useAuth();

  return (
    <Spin spinning={isLoading}>
      <VerticalSpace>
        {type !== APPLICATION_MODE.FILL && <ApplicationDetails />}
        <ProgramInfo />
        <SupportInfo />
        {!isLoading && (
          <>
            {isLegal ? (
              <CompanyContactForm phoneNumber={userData?.phoneNumber} email={userData.email} />
            ) : (
              <ContactForm phoneNumber={userData?.phoneNumber} email={userData.email} />
            )}
          </>
        )}
      </VerticalSpace>
    </Spin>
  );
};
