import { Col, Input, Row } from 'antd';

import { PostIndexSelect } from 'components/select/post-index-select';
import { VerticalSpace } from 'components/space/vertical-space';
import { RegionsSelect } from 'components/select/regions-select';
import { CommunitySelect } from 'components/select/community-select';
import { SettlementSelect } from 'components/select/settlement-select';
import { BuildingTypesSelect } from 'components/select/building-types-select';
import { Form } from 'components/form/form';
import { FormItem } from 'components/form/form-item';

export const DisabilityPersonAddress = ({ formItemAddress = {}, viewMode = false, isActualAddress = false }) => {
  const regionId = Form.useWatch(formItemAddress.regionId.name);
  const communityId = Form.useWatch(formItemAddress.communityId.name);
  const form = Form.useFormInstance();

  const onRegionChange = () => {
    form.resetFields([
      formItemAddress.communityId.name,
      formItemAddress.settlementId.name,
      ...(formItemAddress.postIndex ? [formItemAddress.postIndex.name] : []),
    ]);
  };

  const onCommunityChange = () => {
    form.resetFields([formItemAddress.settlementId.name]);
    form.resetFields(['assessmentAt', 'location']);
  };

  return (
    <VerticalSpace>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <FormItem label="Մարզ" {...formItemAddress.regionId}>
            {viewMode ? (
              <Input disabled={viewMode} />
            ) : (
              <RegionsSelect
                labelInValue
                onChange={onRegionChange}
                disabled={!!formItemAddress.regionId?.readOnly || viewMode}
              />
            )}
          </FormItem>
          <FormItem hidden {...formItemAddress.region} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <FormItem label="Համայնք" {...formItemAddress.communityId}>
            {viewMode ? (
              <Input disabled={viewMode} />
            ) : (
              <CommunitySelect
                labelInValue
                onChange={onCommunityChange}
                regionId={regionId?.value}
                disabled={!!formItemAddress.communityId?.readOnly || viewMode}
              />
            )}
          </FormItem>
          <FormItem hidden {...formItemAddress.community} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <FormItem label="Բնակավայր" {...formItemAddress.settlementId}>
            {viewMode ? (
              <Input disabled={viewMode} />
            ) : (
              <SettlementSelect
                labelInValue
                communityId={communityId?.value}
                disabled={!!formItemAddress.settlementId?.readOnly || viewMode}
              />
            )}
          </FormItem>
          <FormItem hidden {...formItemAddress.settlement} />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <FormItem label="Փողոց" {...formItemAddress.street}>
            <Input disabled={!!formItemAddress.street?.readOnly || viewMode} />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <FormItem label="Կառույցի տեսակ" {...formItemAddress.buildingType}>
            {isActualAddress ? (
              <BuildingTypesSelect labelInValue />
            ) : (
              <Input disabled={!!formItemAddress.buildingType?.readOnly || viewMode} />
            )}
          </FormItem>
          <FormItem hidden {...formItemAddress.buildingTypeId} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <FormItem label="Կառույցի համար" {...formItemAddress.building}>
            <Input disabled={!!formItemAddress.building?.readOnly || viewMode} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <FormItem label="Բնակարան" {...formItemAddress.apartment}>
            <Input disabled={!!formItemAddress.apartment?.readOnly || viewMode} />
          </FormItem>
        </Col>
        {formItemAddress.postIndex && (
          <Col xs={24} sm={24} md={12} lg={8}>
            <FormItem label="Փոստային դասիչ" {...formItemAddress.postIndex}>
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <PostIndexSelect
                  regionId={regionId?.value}
                  disabled={!!formItemAddress.communityId?.readOnly || viewMode}
                />
              )}
            </FormItem>
          </Col>
        )}
      </Row>
    </VerticalSpace>
  );
};
