import { PlusOutlined } from '@ant-design/icons';
import { Row, Tabs } from 'antd';
import { Button } from 'components/button';
import { AUTHORITY_TYPES } from 'pages/applications/constants';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from 'helpers/constants';
import { AuthoritiesList } from './components/authorities-list';
import { useAuth } from 'context/auth-context';
import { RestrictedPage } from 'components/restricted-page';

export const Authority = () => {
  const [activeTab, setActiveTab] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const onChange = (val) => {
    setActiveTab(val);
  };
  const {
    user: { isCompanyHead },
  } = useAuth();
  useEffect(() => {
    params.type && setActiveTab(+params.type);
  }, [params.type]);

  const items = [
    {
      key: AUTHORITY_TYPES.MY_AUTHORITIES,
      label: `Աշխատակիցներ`,
      children: <AuthoritiesList type={AUTHORITY_TYPES.MY_AUTHORITIES} />,
    },
    {
      key: AUTHORITY_TYPES.OTHER_COMPANIES,
      label: `Այլ կազմակերպություններ`,
      children: <AuthoritiesList type={AUTHORITY_TYPES.OTHER_COMPANIES} />,
    },
  ];

  return (
    <>
      {isCompanyHead ? (
        <>
          <Row justify={'end'}>
            <Button
              type="primary"
              onClick={() => navigate(PATHS.ADD_AUTHORITY, { state: { type: activeTab || 1 } })}
              size="large"
            >
              <PlusOutlined />
              Ավելացնել
            </Button>
          </Row>
          <Tabs
            defaultActiveKey={1}
            activeKey={activeTab}
            items={items}
            onChange={onChange}
            destroyInactiveTabPane={true}
          />
        </>
      ) : (
        <RestrictedPage />
      )}
    </>
  );
};
