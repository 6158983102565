import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { Col, Form, Radio, Row } from 'antd';
import { InputNumber } from 'components/input-number';
import { Select } from 'components/select';
import { requiredMessage, smokeItemList } from '../constants';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';

export const DisabiityQuestionSmoke = () => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const areYouSmoker = Form.useWatch(['questionnaire', 'areYouSmoker', 'isSmoker']);
  const personData = Form.useWatch(['applicationForm'], { preserve: true });

  return (
    <VerticalSpace>
      <Row>
        <Col span={7}>
          <FormItem
            name={['questionnaire', 'areYouSmoker', 'isSmoker']}
            rules={[{ required: true }]}
            label={
              <Title level={3} color="#0B847F">
                {applicantType === APPLICATION_FOR_HIMSELF ? 'Ծխո՞ւմ եք' : 'Ծխո՞ւմ է'}
              </Title>
            }
          >
            <Select
              options={[
                { label: 'Այո', value: true },
                { label: 'Ոչ', value: false },
              ]}
            />
          </FormItem>
        </Col>
      </Row>
      {areYouSmoker && areYouSmoker === true && (
        <Row>
          <Col span={3}>
            <FormItem
              label="քանի տարի"
              name={['questionnaire', 'areYouSmoker', 'ifSmoker', 'smokeYears']}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </FormItem>
          </Col>
          <Col span={3}>
            <FormItem
              label="ծխել սկսելու տարիքը"
              name={['questionnaire', 'areYouSmoker', 'ifSmoker', 'startAge']}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </FormItem>
          </Col>
        </Row>
      )}
      {areYouSmoker === false && (
        <Row>
          <Col span={7}>
            <FormItem
              name={['questionnaire', 'areYouSmoker', 'ifNotSmoker']}
              rules={[{ required: true, message: requiredMessage }]}
            >
              <Radio.Group
                options={smokeItemList(applicantType).filter(item => {
                  if (personData?.age >= 16) {
                    return !item.IsChild;
                  } else {
                    return item.IsChild;
                  }
                })}
                style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'baseline' }}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </VerticalSpace>
  );
};
