import { Checkbox } from 'components/checkbox';
import { FormItem } from 'components/form/form-item';
import { Form } from 'antd';

export const DisabilityTrustApprove = () => {
  const form = Form.useFormInstance();

  const handleCheckChange = (e) => {
    if (!e.target.checked) {
      form.setFieldValue('IApproved', null);
    }
  };
  return (
    <FormItem name="IApproved" rules={[{ required: true }]} valuePropName="checked" >
      <Checkbox onChange={handleCheckChange} >Տալիս եմ համաձայնությունս իմ անձնական տվյալները ՀՀ Օրենսդրությամբ սահմանված կարգով մշակելու համար	</Checkbox>
    </FormItem>
  );
};
