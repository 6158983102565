import { FormItem } from 'components/form/form-item';
import { Title } from 'components/typography';
import { Col, Radio, Row } from 'antd';

export const DisabiityQuestionWithYesNo = ({ title, name }) => {
  return (
    <Row>
      <Col xxl={7} xs={12}>
        <FormItem
          name={name}
          rules={[{ required: true }]}
          label={
            <Title level={3} color="#0B847F">
              {title}
            </Title>
          }
        >
          <Radio.Group
            options={[
              { label: 'Այո', value: true },
              { label: 'Ոչ', value: false },
            ]}
          />
        </FormItem>
      </Col>
    </Row>
  );
};
