import { FormItem } from 'components/form/form-item';
import { Select } from 'components/select';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { Col, Form, Row } from 'antd';
import { ArmenianInput } from 'components/input/armenian-input';

export const DisabiityQuestionWithDetails = ({ title, name, infoName, infoPlaceholder }) => {
  const nameValue = Form.useWatch(name);

  return (
    <VerticalSpace>
      <Row>
        <Col xxl={7} xs={12}>
          <FormItem
            name={name}
            rules={[{ required: true }]}
            label={
              <Title level={3} color="#0B847F">
                {title}
              </Title>
            }
          >
            <Select
              options={[
                { label: 'Այո', value: true },
                { label: 'Ոչ', value: false },
              ]}
            />
          </FormItem>
        </Col>
      </Row>
      {nameValue === true && (
        <Row>
          <Col span={10}>
            <FormItem name={infoName} rules={[{ required: true }]}>
              <ArmenianInput placeholder={infoPlaceholder} />
            </FormItem>
          </Col>
        </Row>
      )}
    </VerticalSpace>
  );
};
