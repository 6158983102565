import { Form, Checkbox, Divider, Spin, Modal } from 'antd';
import { Title } from '../../components/typography';
import { VerticalSpace } from '../../components/space/vertical-space';
import { ApplicantPersonalData } from './applicant-personal-data';
import { InitialConfirmStep } from './initial-confirm-step';
import { IdentificationDocument } from './identification-document';
import { SubmitButton } from '../../components/button';
import { useNavigate } from 'react-router-dom';
import { COLORS, PATHS } from '../../helpers/constants';
import { URL_CITIZEN_INFO, URL_POST_APPLICATION } from '../../api/application/constants';
import { useDataForApplication } from '../../api/application/use-get-data-for-application';
import dayjs from 'dayjs';
import { FormItem } from '../../components/form/form-item';
import { errorMessage } from '../../helpers/utils';
import { useSaveApplication } from '../../api/application/use-save-application';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';

export const HavingBadCreditApplication = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { isLoading } = useDataForApplication(
    URL_CITIZEN_INFO,
    {},
    {
      onSuccess: ({ birthdate, documents, ...restData }) => {
        const [document] = documents || [];
        const documentFields = document || {};

        form.setFieldsValue({
          ...restData,
          ...documentFields,
          birthdate: birthdate && dayjs(birthdate),
        });
      },
      onError: errorMessage,
      enabled: true,
    }
  );

  const { mutate: applicationSubmit } = useSaveApplication(URL_POST_APPLICATION, {
    onSuccess: (res) => {
      Modal.success({
        title: 'Շնորհակալություն',
        content: (
          <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: res?.data?.notificationMessage }} />
        ),
        footer: false,
        closable: true,
        icon: <CheckCircleOutlined style={{ color: COLORS.PRIMARY.BLUE }} />,
        onCancel: () => navigate(PATHS.APPLICATIONS),
      });
      setSubmitLoading(false);
    },
    onError: (error) => {
      errorMessage(error);
      setSubmitLoading(false);
    },
  });

  const onFinish = (values) => {
    setSubmitLoading(true);
    const birthDate = dayjs(values.birthdate).format('YYYY-MM-DD');
    values.receiveNotification = values.receiveNotification?.length === 2 ? 3 : values.receiveNotification[0];
    const result = {
      ...values,
      birthdate: birthDate,
      agreementForInfo: values.agreementForInfo[0],
      agreementForInfoDb: values.agreementForInfoDb[0],
    };
    applicationSubmit(result);
    setDisabled(true);
  };

  return (
    <Spin spinning={isLoading || submitLoading}>
      <VerticalSpace>
        <Form
          name="application-form"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          preserve={true}
        >
          <VerticalSpace>
            <Title level={1}>Դիմումատուի անձնական տվյալներ</Title>
            <InitialConfirmStep />
            <ApplicantPersonalData />
            <Divider style={{ margin: 0, backgroundColor: '#C0C1C2' }} />
            <Title level={1}>Անձը հաստատող փաստաթուղթ</Title>
            <IdentificationDocument />
            <Divider style={{ margin: 0, backgroundColor: '#C0C1C2' }} />
            <Title level={1}>Ծանուցումը նախընտրում եմ ստանալ՝</Title>
            <FormItem name="receiveNotification" rules={[{ required: true }]}>
              <Checkbox.Group>
                <VerticalSpace>
                  <Checkbox value={1}>Էլ. փոստով</Checkbox>
                  <Checkbox value={2}>Հեռախոսահամարով</Checkbox>
                </VerticalSpace>
              </Checkbox.Group>
            </FormItem>
            <Divider style={{ margin: 0, backgroundColor: '#C0C1C2' }} />
            <FormItem name="agreementForInfo" rules={[{ required: true }]}>
              <Checkbox.Group>
                <Checkbox value={true}>Հավաստիացնում եմ, որ ներկայացված տվյալները ճիշտ են</Checkbox>
              </Checkbox.Group>
            </FormItem>
            <FormItem name="agreementForInfoDb" rules={[{ required: true }]}>
              <Checkbox.Group>
                <Checkbox value={true}>
                  Տալիս եմ իմ համաձայնությունը համապատասխան շտեմարաններից իմ տվյալները ստանալու, մշակելու, օգտագործելու
                  և փոխանցելու վերաբերյալ։
                </Checkbox>
              </Checkbox.Group>
            </FormItem>
            <SubmitButton
              submitText="Հաստատել"
              canceltext="Նախորդ"
              onCancel={() => {
                navigate(PATHS.APPLICATIONS);
              }}
              disabled={disabled}
            />
          </VerticalSpace>
        </Form>
      </VerticalSpace>
    </Spin>
  );
};
