import { FormItem } from 'components/form/form-item';
import { Select } from 'components/select';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { requiredMessage } from '../constants';
import { Checkbox, Col, Form, Row } from 'antd';
import { useMemo } from 'react';

export const DisabiityQuestionWithOptions = ({ title, items, selectName, checkName }) => {
  const selectValue = Form.useWatch(selectName);

  const selectOptions = useMemo(() => items.map(({ label, value }) => ({ label, value })), [items]);

  const checkOptions = useMemo(
    () => (selectValue ? items.find((item) => item?.value === selectValue)?.options : []),
    [items, selectValue]
  );

  return (
    <VerticalSpace>
      <Row>
        <Col xxl={7} xs={12}>
          <FormItem
            name={selectName}
            rules={[{ required: true }]}
            label={
              <Title level={3} color="#0B847F">
                {title}
              </Title>
            }
          >
            <Select options={selectOptions} />
          </FormItem>
        </Col>
      </Row>
      {!!checkOptions.length && (
        <Row>
          <Col span={7}>
            <FormItem name={checkName} rules={[{ required: true, message: requiredMessage }]}>
              <Checkbox.Group
                options={checkOptions}
                style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'baseline' }}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </VerticalSpace>
  );
};
