import { useEffect, useState } from 'react';
import { ActionButtons } from './components/action-buttons';
import { VerticalSpace } from 'components/space/vertical-space';
import { RapidNeedSteps } from './components/stepper';
import { APPLICATION_MODE, DIsteps } from './constants';
import { Form, Spin } from 'antd';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_URRP_APPLICATION_BY_ID } from 'api/urrp/constants';

import { modifyViewValues } from './utils';
import { useMatches } from 'react-router';

export const RapidNeed = () => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const items = DIsteps.map((item) => ({ key: item.title, title: item.title }));
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const id = match?.params?.id;
  const type = match?.handle?.type;

  const { isLoading } = useGetFfromBenefit(
    URL_GET_URRP_APPLICATION_BY_ID,
    {
      applicationId: id,
    },
    {
      onSuccess: (data) => {
        const formData = modifyViewValues(data);
        form.setFieldsValue({ ...formData });
      },
      enabled: !!(id && !!(type === APPLICATION_MODE.EDIT || type === APPLICATION_MODE.VIEW)),
    }
  );

  const next = () => {
    setCurrent(current + 1);
    document.getElementById('rapid-need-page').scrollIntoView();
  };

  const prev = () => {
    setCurrent(current - 1);
    document.getElementById('rapid-need-page').scrollIntoView();
  };

  const onStepChange = (newCurrent) => {
    if (newCurrent < DIsteps.length) {
      setCurrent(newCurrent);
      document.getElementById('rapid-need-page').scrollIntoView();
    }
  };

  useEffect(() => {
    if (type === APPLICATION_MODE.EDIT) {
      setCurrent(2);
    }
  }, [type]);

  return (
    <Spin spinning={type === 'view' && isLoading}>
      <RapidNeedSteps current={current} items={items} onChange={type === 'view' && onStepChange} />
      <Form
        name="need-form"
        form={form}
        layout="vertical"
        autoComplete="off"
        preserve={true}
        disabled={type === APPLICATION_MODE.VIEW}
      >
        <VerticalSpace>
          <div id="rapid-need-page">{DIsteps[current].content}</div>
          <ActionButtons
            next={next}
            prev={prev}
            step={current}
            isView={type === APPLICATION_MODE.VIEW}
            isEdit={type === APPLICATION_MODE.EDIT}
            applicationId={id}
          />
        </VerticalSpace>
      </Form>
    </Spin>
  );
};
