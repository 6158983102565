import { Menu as MenuComponent } from 'antd';
import { DownSquareOutlined } from '@ant-design/icons';
import { Link, useMatches } from 'react-router-dom';
import { COLORS, PATHS } from '../../helpers/constants';
import styled from 'styled-components';
import { useAuth } from '../../context/auth-context';
import { menuText } from '../../pages/services/constant';

const getItem = (label, key, children, type) => {
  return {
    key,
    children,
    label,
    type,
  };
};

const SubMenu = styled(MenuComponent)`
  .ant-menu-submenu {
    max-height: 185px;
    overflow: auto;
  }

  .ant-menu-submenu > .ant-menu-submenu-title {
    height: fit-content;
    color: #ffffff;
  }

  .ant-menu-title-content {
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledSpan = styled.span`
  color: white;
`;

const white = {
  color: COLORS.PRIMARY.WHITE,
};

export const ServicesNavbar = () => {
  const match = useMatches();
  const { user } = useAuth();

  const items = [
    getItem(<StyledSpan title={menuText.lostJob.label}>{menuText.lostJob.label}</StyledSpan>, '1', []),
    getItem(<StyledSpan title={menuText.needAllowance.label}>{menuText.needAllowance.label}</StyledSpan>, '3', [
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.needAllowance.subMenu.insecurityAllowance}
          style={white}
        >
          {menuText.needAllowance.subMenu.insecurityAllowance}
        </Link>,
        '4'
      ),
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.needAllowance.subMenu.applicationAppeal}
          style={white}
        >
          {menuText.needAllowance.subMenu.applicationAppeal}
        </Link>,
        '5'
      ),
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.needAllowance.subMenu.firstGrade}
          style={white}
        >
          {menuText.needAllowance.subMenu.firstGrade}
        </Link>,
        '6'
      ),
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.needAllowance.subMenu.emergencyAssistance}
          style={white}
        >
          {menuText.needAllowance.subMenu.emergencyAssistance}
        </Link>,
        '7'
      ),
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.needAllowance.subMenu.familyComposition}
          style={white}
        >
          {menuText.needAllowance.subMenu.familyComposition}
        </Link>,
        '8'
      ),
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.needAllowance.subMenu.changeAddress}
          style={white}
        >
          {menuText.needAllowance.subMenu.changeAddress}
        </Link>,
        '9'
      ),
    ]),
    getItem(<StyledSpan title={menuText.receivePension}>{menuText.receivePension}</StyledSpan>, '10', []),
    getItem(<StyledSpan title={menuText.goingHaveBaby}>{menuText.goingHaveBaby}</StyledSpan>, '11', []),
    getItem(<StyledSpan title={menuText.needCare}>{menuText.needCare}</StyledSpan>, '12', []),
    getItem(<StyledSpan title={menuText.needApartment}>{menuText.needApartment}</StyledSpan>, '13', []),
    getItem(<StyledSpan title={menuText.functionalEvaluation}>{menuText.functionalEvaluation}</StyledSpan>, '14', []),
    getItem(<StyledSpan title={menuText.depositRefund}>{menuText.depositRefund}</StyledSpan>, '15', []),
    getItem(<StyledSpan title={menuText.difficultSituation}>{menuText.difficultSituation.label}</StyledSpan>, '16', [
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.difficultSituation.subMenu.badCredit}
          style={white}
        >
          {menuText.difficultSituation.subMenu.badCredit}
        </Link>,
        '17'
      ),
      getItem(
        <Link
          to={user ? PATHS.APPLICATION : PATHS.SIGN_IN}
          title={menuText.difficultSituation.subMenu.unifiedRapidResponse}
          style={white}
        >
          {menuText.difficultSituation.subMenu.unifiedRapidResponse}
        </Link>,
        '18'
      )
    ]),
    getItem(<StyledSpan title={menuText.provideSupport}>{menuText.provideSupport}</StyledSpan>, '19', []),
    getItem(<StyledSpan title={menuText.displaced}>{menuText.displaced}</StyledSpan>, '20', []),
  ];

  return (
    <>
      <SubMenu
        style={{
          width: '100%',
          height: 'fit-content',
          background: 'none',
          color: COLORS.PRIMARY.WHITE,
        }}
        expandIcon={<DownSquareOutlined style={{ color: '#ffffff' }} />}
        selectedKeys={match.map((item) => item.pathname)}
        mode="inline"
        items={items}
      />
    </>
  );
};
