import { useQuery } from '@tanstack/react-query';
import { client } from 'api/client';

export const useGetData = (url, params, options = { enabled: true }) => {
  const result = useQuery([url, params], () => client.get(url, { params }), {
    ...options,
    select: (data) => data,
  });

  const { data, isSuccess } = result;

  return {
    ...result,
    data: isSuccess ? data.data : [],
  };
};
