import { Select as SelectComponent } from 'antd';
import styled from 'styled-components';

export const Select = styled(SelectComponent)`
  &.ant-select {
    width: 100%;
    height: auto;

    &.ant-select-selector {
      background-color: #ffffff;
      color: #7e7c7c;
    }

    .ant-select-selector {
      min-height: 37px;
      border: 1px solid #c0c1c2;
      height: auto;
    }
  }

  .ant-select-selection-item {
    color: #555965;
    margin-right: 10px;
    height: auto;

    @media (max-width: 800px) {
      overflow: auto;
      white-space: normal;
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      min-height: 45px;
      height: 100%;
    }
  }
`;

export { FamilyRelationshipSelect } from './family-relationship-select';
