import { Col, Form, message, Row, Spin } from 'antd';
import { URL_UPDATE_PHONE_NUMBER } from 'api/application/constants';
import { useSaveApplication } from 'api/application/use-save-application';
import { FormSectionCard } from 'components/card/form-section-card';
import { VerticalSpace } from 'components/space/vertical-space';
import dayjs from 'dayjs';
import { errorMessage } from 'helpers/utils';
import { useState } from 'react';

import { FormItem } from 'components/form/form-item';
import { Input } from 'components/input';
import { Datepicker } from 'components/datepicker';
import { COLORS, phonePattern } from 'helpers/constants';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { URL_GET_USER_DATA_FROM_BPR } from 'api/urrp/constants';
import { DIPersonAddress } from 'pages/rapid-need/components/person-address-form';
import { formItemRegAddress } from 'pages/rapid-need/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { useGetPhotoForUser } from 'api/account/use-get-photo-for-user';
import { URL_GET_PHOTO_FOR_USER } from 'api/benefit/constants';

export const PersonalData = () => {
  const [form] = Form.useForm();
  const [phoneReadOnly, setPhoneReadOnly] = useState(true);
  const { data: photo } = useGetPhotoForUser(URL_GET_PHOTO_FOR_USER);

  const { data, isLoading } = useGetFfromBenefit(
    URL_GET_USER_DATA_FROM_BPR,
    {},
    {
      onSuccess: ({ ...data }) => {
        form.setFieldsValue({
          ...data.data,
          birthdate: data.data?.birthdate && dayjs(data.data?.birthdate),
        });
      },
      enabled: true,
    }
  );
  const { mutate } = useSaveApplication(URL_UPDATE_PHONE_NUMBER, {
    onSuccess: () => {
      message.success(`Տվյալները հաջողությամբ պահվեցին`);
      setPhoneReadOnly(!phoneReadOnly);
    },
    onError: errorMessage,
  });

  const handleEdit = () => {
    setPhoneReadOnly(!phoneReadOnly);
  };
  const handleSave = () => {
    const phonenumber = form.getFieldValue('phoneNumber');
    mutate({ phonenumber });
  };
  return (
    <VerticalSpace>
      <Form autoComplete="off" layout="vertical" form={form}>
        <Spin spinning={isLoading}>
          <VerticalSpace>
            <FormSectionCard title="Անձնական տվյալներ">
              <Row gutter={16} align="middle">
                <Col xs={24} md={6} style={{ textAlign: 'center' }}>
                  <img
                    src={'data:image/png;base64,' + photo?.photo}
                    style={{ objectFit: 'contain', height: 230, width: 230, borderRadius: 200, border: `2px solid ${COLORS.SECONDARY.AQUA_BLUE}` }}
                    alt="Profile"
                  />
                </Col>
                <Col xs={24} sm={24} md={18}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                      <FormItem label="Անուն" name="firstName">
                        <Input size="middle" disabled />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item name="ssn" label="ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանքի համար">
                        <Input placeholder="ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանքի համար" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item name="lastName" label="Ազգանուն">
                        <Input placeholder="Ազգանուն" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item name="patronymicName" label="Հայրանուն">
                        <Input placeholder="Հայրանուն" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <FormItem label="Սեռ" name="sex">
                        <Input size="middle" disabled />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={12}>
                      <FormItem label="Ծննդյան ամսաթիվ " name="birthdate">
                        <Datepicker format="DD/MM/YYYY" style={{ borderRadius: 10, width: '100%' }} disabled />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormSectionCard>
            <FormSectionCard title={'Կոնտակտային  տվյալներ'}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <FormItem label="Էլ․փոստի հասցե" name="email">
                    <Input size="middle" disabled />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <FormItem
                    label="Հեռախոս"
                    name="phoneNumber"
                    rules={[{ pattern: phonePattern, message: 'Ֆորմատը սխալ է {+37400000000}' }]}
                  >
                    <Input
                      size="middle"
                      readOnly={phoneReadOnly}
                      suffix={
                        phoneReadOnly ? (
                          <EditOutlined onClick={() => handleEdit()} />
                        ) : (
                          <CheckOutlined onClick={() => handleSave()} />
                        )
                      }
                    />
                  </FormItem>
                </Col>
              </Row>
            </FormSectionCard>
            <DIPersonAddress
              isActualAddress={false}
              formItemAddress={formItemRegAddress}
              viewMode={true}
              data={data?.registrationAddress}
              title={'Հաշվառման հասցե'}
              notRequired={true}
            />
          </VerticalSpace>
        </Spin>
      </Form>
    </VerticalSpace>
  );
};
