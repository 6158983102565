import { Divider, Form, Modal, Spin } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { useNavigate, useParams } from 'react-router-dom';
import {
  URL_CHECK_APPLICATION_EXISTS,
  URL_CHECK_OTHER_APPLICATION_EXISTS,
  URL_CITIZEN_INFO,
} from 'api/application/constants';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';
import { errorMessage } from 'helpers/utils';
import dayjs from 'dayjs';
import { PATHS } from 'helpers/constants';
import { useEffect } from 'react';
import { useDataForDisabilityApplication } from 'api/application/use-get-data-for-disability-application';
import { DisabilityPersonData } from '../components/disability-person-data';
import { DisabilityPersonRegistrationAddress } from '../components/disability-person-registration-address';
import { DisabilityPersonActualAddress } from '../components/disability-person-actual-address';
import { DisabilityValuationPlaceSelect } from '../components/disability-valuation-place-select';
import { DisabilityDocumentsAttach } from '../components/disability-documents-attach';
import { DisabilityNotifyBy } from '../components/disability-notify-by';
import { usePostNoMutate } from 'api/application/use-post-no-mutate';
import { DisabilityTrustProvide } from '../components/disability-trust-provide';
import { DisabilityTrustApprove } from '../components/disability-trust-approve';
import { useGetDisabilityDecisionNumber } from 'pages/disability/hooks/use-get-disability-decision-number';
import { DisabilityDeathPersonDataSearchForm } from '../components/disability-death-person-data-search-form';
import { DisabilityDecisionInfo } from '../components/disability-decision-info';

export const DisabilityPersonalData = () => {
  const params = useParams();

  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const navigate = useNavigate();
  const form = Form.useFormInstance();

  const { isInitialLoading, data } = useDataForDisabilityApplication(
    URL_CITIZEN_INFO,
    {},
    {
      enabled: applicantType === APPLICATION_FOR_HIMSELF,
      onError: (err) => {
        errorMessage(err);
        navigate(PATHS.SERVICES);
      },
    }
  );
  const { dataDecisionIsLoading, dataDecision } = useGetDisabilityDecisionNumber();

  const { isInitialLoading: isCheckLoading } = usePostNoMutate(
    ['1', '2', '3'].includes(params.subtypeId) ? URL_CHECK_OTHER_APPLICATION_EXISTS : URL_CHECK_APPLICATION_EXISTS,
    { subtypeId: params.subtypeId, ssn: data.ssn },
    {
      retry: false,
      enabled: !!(
        data.ssn &&
        params.subtypeId &&
        applicantType === APPLICATION_FOR_HIMSELF &&
        ['1', '2', '3', '4', '5', '8', '9'].includes(params.subtypeId)
      ),
      onError: (err) => {
        errorMessage(err);
        navigate(PATHS.SERVICES);
      },
    }
  );

  useEffect(() => {
    if (data.age && data.age < 16 && applicantType === 1) {
      Modal.warning({
        title: 'Ուշադրություն',
        onCancel: () => navigate(PATHS.DISABILITY_TYPE),
        onOk: () => navigate(PATHS.DISABILITY_TYPE),
        content: (
          <div>
            <p>Դիմումը պետք է լրացվի անձի օրինական ներկայացուցչի կողմից։</p>
          </div>
        ),
      });
    }
    if (data.id && applicantType === APPLICATION_FOR_HIMSELF) {
      form.setFieldsValue({
        id: data.id,
        profileId: data.profileId,
        applicationForm: {
          ...data,
          validSSN: data.ssn,
          birthDate: dayjs(data.birthdate),
          gender: data.sex,
          ...(data.documents.length === 1
            ? {
                documentType: data.documents[0].documentType,
                documentNumber: data.documents[0].documentNumber,
              }
            : {}),
        },
        registrationAddress: data.registrationAddress,
      });
    }
  }, [applicantType, data, form, navigate]);

  return (
    <Spin spinning={isInitialLoading || isCheckLoading || dataDecisionIsLoading}>
      <VerticalSpace>
        <DisabilityPersonData />
        <DisabilityPersonRegistrationAddress />
        <DisabilityPersonActualAddress />
        {+params.subtypeId !== 7 ? (
          <>
            <DisabilityValuationPlaceSelect />
            <DisabilityDocumentsAttach />
          </>
        ) : (
          dataDecision.isAllowed === true && <DisabilityDecisionInfo dataDecision={dataDecision} />
        )}

        {[10].includes(+params.subtypeId) && (
          <>
            <Divider />
            <DisabilityDeathPersonDataSearchForm />
          </>
        )}
        <Divider />
        <DisabilityNotifyBy />
        {[6, 7, 8, 9, 10, 11].includes(+params.subtypeId) && (
          <>
            <DisabilityTrustProvide /> <DisabilityTrustApprove />
          </>
        )}
      </VerticalSpace>
    </Spin>
  );
};
