import { Col, Form, Row, Spin, Tabs } from 'antd';
import { Button } from 'components/button';
import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { Select } from 'components/select';
import { FIELD_NAMES_DEFAULT } from 'components/select/constants';
import { VerticalSpace } from 'components/space/vertical-space';
import { PATHS } from 'helpers/constants';
import { useMatches, useNavigate } from 'react-router-dom';
import { PersonSearchForm } from './components/person-search-form';
import { CompanySearchForm } from './components/company-search-form';
import { AuthorityData } from './components/authority-data';
import { useSaveApplication } from 'api/application/use-save-application';
import { URL_CREATE_REPRESENTATIVE, URL_EDIT_REPRESENTATIVE, URL_GET_REPRESENTATIVE_BY_ID } from 'api/urrp/constants';
import { errorMessage } from 'helpers/utils';
import dayjs from 'dayjs';
import { useAuth } from 'context/auth-context';
import { RestrictedPage } from 'components/restricted-page';
import { useGetData } from 'api/dictionary/use-get-data';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';

export const AddAuthorizedPerson = () => {
  const navigate = useNavigate();
  const {
    user: { companyId, isCompanyHead, isRepresentative, profileId, representativeProfileId },
  } = useAuth();
  const [form] = Form.useForm();
  const representativeType = Form.useWatch('representativeType', form);
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const id = match?.params?.id;
  const type = match?.handle?.type;
  const { mutate, isLoading } = useSaveApplication(
    type === APPLICATION_MODE.EDIT ? URL_EDIT_REPRESENTATIVE : URL_CREATE_REPRESENTATIVE,
    {
      onSuccess: () => navigate(PATHS.AUTHORITY),
      onError: errorMessage,
    }
  );
  const { isLoading: isApplicationLoading } = useGetData(
    `${URL_GET_REPRESENTATIVE_BY_ID}/${id}`,
    {},
    {
      enabled: !!(type !== APPLICATION_MODE.FILL),
      onSuccess: ({ data }) => {
        form.setFieldsValue({
          ...data,
          validFrom: dayjs(data.validFrom, 'DD/MM/YYYY'),
          validTo: data?.validTo ? dayjs(data.validTo, 'DD/MM/YYYY') : null,
        });
      },
    }
  );

  const onFinish = (values) => {
    let dataToSave = {
      taxNumber: null,
      firstName: null,
      lastName: null,
      patronymicName: null,
      companyName: null,
      companyType: null,
      ceo: null,
      systemAttributes: null,
      companyId: isRepresentative ? profileId : companyId,
      representativeProfileId: isRepresentative ? representativeProfileId : null,
      ...values,
      validFrom: dayjs(values.validFrom).format('YYYY-MM-DD'),
      validTo: values?.validTo ? dayjs(values.validTo).format('YYYY-MM-DD') : null,
    };
    mutate(
      type === APPLICATION_MODE.EDIT
        ? {
            representativeId: id,
            validTo: values?.validTo ? dayjs(values.validTo).format('YYYY-MM-DD') : null,
            isActive: values.isActive,
          }
        : dataToSave
    );
  };

  return (
    <Spin spinning={type !== APPLICATION_MODE.FILL && isApplicationLoading}>
      {isCompanyHead ? (
        <>
          <Tabs
            defaultActiveKey={1}
            items={[
              {
                key: 1,
                label: `Լիազորություններ`,
              },
            ]}
            size="large"
          />
          <Form
            layout="vertical"
            onFinish={onFinish}
            name="authority-form"
            form={form}
            autoComplete="off"
            preserve={true}
            disabled={type !== APPLICATION_MODE.FILL}
          >
            <FormSectionFullCard>
              <VerticalSpace>
                <Row>
                  <Col span={8}>
                    <Form.Item rules={[{ required: true }]} name="representativeType" label={'Լիազորվող անձի տեսակը'}>
                      <Select
                        placeholder="Ընտրել"
                        style={{ width: '220px' }}
                        fieldNames={FIELD_NAMES_DEFAULT}
                        options={[
                          {
                            id: 1,
                            name: 'Աշխատակից',
                            disabled: false,
                          },
                          {
                            id: 2,
                            name: 'Կազմակերպություն',
                            disabled: isRepresentative ? true : false,
                          },
                        ]}
                        onChange={(value) => {
                          form.resetFields();
                          form.setFieldValue('representativeType', value);
                        }}
                        disabled={type !== APPLICATION_MODE.FILL}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {representativeType === undefined ? (
                  <></>
                ) : representativeType === 1 ? (
                  <PersonSearchForm />
                ) : (
                  <CompanySearchForm />
                )}
                <AuthorityData />
                <Row gutter={16} justify={'center'} style={{ margin: 25 }}>
                  <Col>
                    <Button onClick={() => navigate(PATHS.AUTHORITY)} disabled={false}>
                      Փակել
                    </Button>
                  </Col>
                  {type !== APPLICATION_MODE.VIEW && (
                    <Col>
                      <Button type="primary" htmlType="submit" loading={isLoading} disabled={false}>
                        Հաստատել
                      </Button>
                    </Col>
                  )}
                </Row>
              </VerticalSpace>
            </FormSectionFullCard>
          </Form>
        </>
      ) : (
        <RestrictedPage />
      )}
    </Spin>
  );
};
