import { VerticalSpace } from 'components/space/vertical-space';
import { Text, Title } from 'components/typography';
import { Button, Card, Col, Modal, Row, Space } from 'antd';
import { COLORS } from 'helpers/constants';
import { useState } from 'react';

export const InitialConfirmStep = () => {
  const [open, setOpen] = useState(true);
  const confirmationModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      closable={false}
      title={<Title color={COLORS.PRIMARY.BLUE}>Ծանոթություն</Title>}
      footer={
        <div style={{ textAlign: 'left' }}>
          <Row align="center" justify="center">
            <Col span={8}>
              <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                <Button type="primary" shape="round" size="large" onClick={confirmationModal}>
                  Կարդացել եմ
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      }
      open={open}
      width="70%"
      destroyOnClose
    >
      <Card bordered={true} style={{ overflow: 'auto', height: '70vh' }}>
        <VerticalSpace>
          <Text strong>Հարգելի՛ օգտատեր</Text>
          <Text style={{ marginLeft: 10 }}>
            Ստորև կարող եք ծանոթանալ «Անհուսալի վարկեր ունեցող անձանց տնտեսական ակտիվության խթանման միջոցառման»
            պայմաններին։
          </Text>

          <Text strong>Միջոցառման շրջանակներում ֆինանսական աջակցություն կարող է ստանալ ֆիզիկական անձը, եթե նա․</Text>
          <ul>
            <li>ՀՀ քաղաքացի է կամ ունի ՀՀ-ում ժամանակավոր պաշտպանության կարգավիճակ,</li>
            <li>
              2024 թվականի հունիսի 1-ի դրությամբ ՀՀ կենտրոնական բանկի կողմից լիցենզավորված առևտրային բանկերում կամ
              վարկային կազմակերպություններում ունի մեկ կամ մի քանի վարկ, և այդ բոլոր վարկերն անհուսալի են (այդ թվում՝
              որպես համավարկառու, օրինական ժառանգորդ, ինչպես նաև որպես երաշխավոր, եթե տվյալ անհուսալի վարկի վարկառուն չի
              օգտվում այս միջոցառումից)։ Ընդ որում՝ վարկերի մայր գումարների հանրագումարը դիմելու օրվա դրությամբ չպետք է
              գերազանցի 1,000,000 ՀՀ դրամը կամ համարժեք արտարժույթը։
            </li>
            <li>
              այս միջոցառման շրջանակներում ֆինանսական աջակցություն ստանալու համար դիմում ներկայացնելու օրվա դրությամբ
              գրանցված աշխատող է (աշխատող լինելու փաստը ստուգվում է «Եկամտային հարկի, շահութահարկի և սոցիալական վճարի
              անձնավորված հաշվառման համակարգում» առկա տեղեկատվությամբ)։
            </li>
          </ul>
          <Text strong>Միջոցառման շրջանակում ֆինանսական աջակցությունը տրամադրվում է՝</Text>
          <ul>
            <li>
              միջոցառման շահառուի աշխատավարձից վճարված եկամտային հարկի 80%-ի (մինչև 2025 թվականի հունիսի 30-ը) կամ 50%-ի
              (2025 թվականի հուլիսի 1-ից հետո) չափով, բայց ոչ ավելի, քան ամսական 25,000 ՀՀ դրամ, որն ուղղվում է
              անհուսալի վարկի մարմանը,
            </li>
            <li>
              առավելագույնը 24 ամիս ժամկետով՝ սկսած միջոցառման շահառու ճանաչվելուն հաջորդող ամսվա 1-ից։ Աջակցության
              տրամադրումը դադարեցվում է, եթե անհուսալի վարկն ամբողջությամբ մարվել է, լրացել է աջակցության տրամադրման
              ժամկետը կամ օրենքով սահմանված հիմքով պարտավորությունը դադարել է։
            </li>
            {/* <li>
              Պարտադիր դաշտերը ցուցադրված են <span style={{ color: COLORS.ALERT.RED }}>*</span> նշանով։
            </li> */}
          </ul>

          <Text strong color={COLORS.ALERT.RED}>
            Ուշադրություն
          </Text>
          <Text>
            Սույն միջոցառման շրջանակում անձը կարող է ճանաչվել շահառու միայն մեկ անգամ և չի կարող կրկին դիմել միջոցառման
            շահառու դառնալու համար։
          </Text>
        </VerticalSpace>
      </Card>
    </Modal>
  );
};
