import { PATHS } from 'helpers/constants';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const List = styled.ul`
  li {
    a {
      color: rgba(151, 146, 146, 1);
    }
    &:hover {
      a {
        color: rgba(69, 111, 174, 1);
      }
    }
  }
`;

export const DisabilitySubtypes = ({ items }) => {
  return (
    <List>
      {items.map((item) => (
        <li key={item.id}>
          <Link
            to={
              PATHS.DISABILITY_APPLICATION.replace(':typeId', item.evaluationTypeId).replace(':subtypeId', item.id)
            }
          >
            {item.name}
          </Link>
        </li>
      ))}
    </List>
  );
};
