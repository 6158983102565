import { PlusCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Button } from 'components/button';
import React, { useEffect, useRef } from 'react';
import { FamilyMemberForm } from './family-member-form';
import { familyData } from '../constants';

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false);
  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add();
      hasInitialized.current = true;
    }
  }, [add, fields.length]);
};

const FamilyMemberData = ({ fields, add, remove, relativeTypes, educationTypes, vulnerability }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ManageFields fields={fields} add={add} />
      {fields.map((field) => (
        <React.Fragment key={field.name}>
          <FamilyMemberForm
            field={field}
            remove={remove}
            relativeTypes={relativeTypes}
            educationTypes={educationTypes}
            vulnerability={vulnerability}
          />
        </React.Fragment>
      ))}
      <Button type="primary" size="large" shape="round" onClick={() => add()} style={{ margin: '20px auto' }}>
        <PlusCircleOutlined />
        Ավելացնել ընտանիքի անդամ
      </Button>
    </div>
  );
};

export const AddFamilyMember = ({ viewMode = false, relativeTypes, educationTypes, vulnerability }) => {
  return (
    <Form.List name={[familyData, 'familyMembers']}>
      {(fields, { add, remove }) => (
        <FamilyMemberData
          relativeTypes={relativeTypes}
          educationTypes={educationTypes}
          vulnerability={vulnerability}
          viewMode={viewMode}
          fields={fields}
          add={add}
          remove={remove}
        />
      )}
    </Form.List>
  );
};
