import { useMatches } from 'react-router-dom';
import { APPLICATION_MODE } from './constnats';
import { CreateDonorAgreement } from './components/create-donor-agreement';

export const DonorAgreement = () => {
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;

  return <>{type === APPLICATION_MODE.CREATE && <CreateDonorAgreement />}</>;
};
