import { VerticalSpace } from 'components/space/vertical-space';
import { AgreemnetData } from 'pages/agreements/components/agreement-data';
import { AgreemnetStatus } from 'pages/agreements/components/agreement-status';

export const SecondTab = () => {
  return (
    <VerticalSpace>
      <AgreemnetData />
      <AgreemnetStatus />
    </VerticalSpace>
  );
};
