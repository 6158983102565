import { useState } from 'react';
import { Layout } from 'antd';
import { DefaultSider } from './default-sider';
import { DefaultContent } from './default-content';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SubHeader } from './sub-header';
import backgroundImage from './images/background.png';
import { ContentWrapper } from './content-wrapper';

export const BackgroundLayout = styled(Layout)`
  ${(props) =>
    props.inner
      ? css`
          background: transparent;
        `
      : css`
          /* background-image: url(${backgroundImage}); */
          /* background-size: cover; */
          /* background-position: center; */
          /* background-repeat: no-repeat; */
          /* background-color: #ffffff; */
        `}
`;

export const MainLayout = ({ sider, content, footer }) => {
  const [headerText, setHeaderText] = useState({ text: 'Անհատական կաբինետ' });
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SubHeader icon={headerText.icon} text={headerText.text} />
      <BackgroundLayout>
        {sider || <DefaultSider />}
        {content || (
          <DefaultContent>
            <ContentWrapper style={{ padding: '40px 24px 64px' }} fullHeight>
              <Outlet context={[headerText, setHeaderText]} />
            </ContentWrapper>
          </DefaultContent>
        )}
        {footer}
      </BackgroundLayout>
    </Layout>
  );
};
