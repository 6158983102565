import { Form, Radio, Spin } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { VerticalSpace } from 'components/space/vertical-space';
import { APPLICATION_MODE, familyData } from '../constants';
import { useWatch } from 'antd/es/form/Form';
import { AddFamilyMember } from '../components/manage-family-member';
import { FamilyDetails } from '../components/family-details';
import { DICTIONARY_URLS, useGetAllDictionaries } from 'api/urrp/use-get-all-dictionaries';

import { PersonDetails } from '../components/person-details';
import { ResideMemberCheckbox } from '../components/reside-member-checkbox';
import { CurrentLocationForm } from '../components/current-location-form';
import { useMatches } from 'react-router';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

export const SecondStep = () => {
  const hasfamilyMember = useWatch([familyData, 'hasFamilyMembers'], {
    preserve: true,
  });

  const form = useFormInstance();
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;
  const { data, isLoading } = useGetAllDictionaries(DICTIONARY_URLS.SECOND_STEP);

  const handleChange = (e) => {
    if (e.target.value) {
      form.setFieldsValue({
        [familyData]: {
          familyMembers: null,
        },
      });
    } else {
      form.setFieldsValue({
        [familyData]: {
          personDetails: null,
        },
      });
    }
    form.setFieldsValue({
      [familyData]: {
        hasFamilyMembers: e.target.value,
      },
    });
  };

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard title={'Ընտանիքի տվյալներ'}>
        <VerticalSpace style={{ width: '100%' }}>
          <CurrentLocationForm buildingTypes={data[0]} />
          <Form.Item
            label="Ունե՞ք ընտանիքի անդամներ"
            name={[familyData, 'hasFamilyMembers']}
            rules={[{ required: true }]}
          >
            <Radio.Group onChange={handleChange}>
              <Radio style={{ color: 'black' }} value={true}>Այո</Radio>
              <Radio style={{ color: 'black' }} value={false}>Ոչ</Radio>
            </Radio.Group>
          </Form.Item>
          {hasfamilyMember && (
            <AddFamilyMember relativeTypes={data[2]} educationTypes={data[1]} vulnerability={data[3]} />
          )}
          {hasfamilyMember === true ? (
            <FamilyDetails />
          ) : hasfamilyMember === false ? (
            <PersonDetails vulnerability={data[3]} educationTypes={data[1]} />
          ) : (
            <></>
          )}
          <ResideMemberCheckbox isView={type === APPLICATION_MODE.VIEW} />
        </VerticalSpace>
      </FormSectionCard>
    </Spin>
  );
};
