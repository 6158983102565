import { useMutation } from '@tanstack/react-query';
import { client } from '../client';

const url = '/EDisability/DeleteFile';
export const useFileDelete = (options = {}) => {
  const mutation = useMutation((values) => {
    return client.post(url, values);
  }, options);
  return mutation;
};
