import { PlusCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Button } from 'components/button';
import React, { useEffect, useRef } from 'react';
import { ClotheListItem } from './clothe-list-item';
import { VerticalSpace } from 'components/space/vertical-space';

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false);
  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add();
      hasInitialized.current = true;
    }
  }, [add, fields.length]);
};

const ClotheFormData = ({ fields, add, remove, parentProp, itemId, itemData }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ManageFields fields={fields} add={add} />
      <VerticalSpace>
        {fields.map((field) => (
          <React.Fragment key={field.name}>
            <ClotheListItem
              field={field}
              remove={remove}
              add={add}
              parentProp={parentProp}
              itemId={itemId}
              itemData={itemData}
            />
          </React.Fragment>
        ))}
      </VerticalSpace>
      <Button type="primary" size="large" shape="round" onClick={() => add()} style={{ margin: '20px auto' }}>
        <PlusCircleOutlined />
        Ավելացնել
      </Button>
    </div>
  );
};

export const AddClothe = ({ parentProp, itemId, itemData }) => {
  return (
    <Form.List name={[...parentProp]}>
      {(fields, { add, remove }) => (
        <ClotheFormData
          fields={fields}
          add={add}
          remove={remove}
          parentProp={parentProp}
          itemId={itemId}
          itemData={itemData}
        />
      )}
    </Form.List>
  );
};
