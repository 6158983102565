import { VerticalSpace } from 'components/space/vertical-space';
import { ApplicantInfo } from './applicant-info';
import { useForm } from 'antd/es/form/Form';
import { Col, Divider, Form, Modal, Row, Space, Spin } from 'antd';
import { Button } from 'components/button';
import { URL_CREATE_AGREEMENT, URL_GET_COMPANY_BY_ID, URL_GET_USER_DATA_FROM_BPR } from 'api/urrp/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { DonorAddress } from './donor-address';
import { SupportDetails } from './support-details';
import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { Text } from 'components/typography';
import { COLORS, PATHS } from 'helpers/constants';
import { useSaveApplication } from 'api/application/use-save-application';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from 'helpers/utils';
import { modifySaveValues } from '../utils';
import { useAuth } from 'context/auth-context';
import { CompanyInfo } from './company-info';
import { Checkbox } from 'components/checkbox';

export const CreateDonorAgreement = () => {
  const [form] = useForm();
  const {
    user: { isLegal, companyId, isRepresentative, profileId, representativeProfileId },
  } = useAuth();
  const { data, isLoading } = useGetFfromBenefit(isLegal ? URL_GET_COMPANY_BY_ID : URL_GET_USER_DATA_FROM_BPR, {
    ...(isLegal ? { companyId: companyId || profileId } : {}),
  });

  const navigate = useNavigate();
  const { mutate: createAgreement } = useSaveApplication(URL_CREATE_AGREEMENT, {
    onSuccess: (response) => {
      const modal = Modal.success({
        title: 'Ծանուցում',
        width: '60%',
        content: (
          <p>
            Շնորհակալություն։ Ձեր համագործակցության հայտն ընդունված է։ Հայտի համարն է {` `}
            <span
              style={{ textDecoration: 'underline', color: COLORS.PRIMARY.BLUE, cursor: 'pointer' }}
              onClick={() => {
                navigate(PATHS.AGREEEMENTS);
                modal.destroy();
              }}
            >
              {response?.data?.applicationNumber}
            </span>
            ։ <br /> Դուք կկարողանաք հայտագրել ձեր աջակցության միջոցառումը համագործակցության հայտի հաստատումից հետո, որը
            կարող է տևել առավելագույնը 5 աշխատանքային օր։ Լրացուցիչ հարցերի դեպքում կարող եք կապ հաստատել
            {` `}
            <a href="mailto:info@mlsa.am" target="_blank" rel="noreferrer noopener">
              info@mlsa.am
            </a>
            {` `}
            հասցեով:
          </p>
        ),
        footer: (
          <Row align="center" justify="center">
            <Col span={8}>
              <Space
                size="middle"
                align="center"
                style={{ width: '100%', justifyContent: 'center', marginTop: '16px' }}
              >
                <Button
                  size="medium"
                  type="primary"
                  onClick={() => {
                    navigate(PATHS.APPLICATIONS);
                    modal.destroy();
                  }}
                >
                  Լավ
                </Button>
              </Space>
            </Col>
          </Row>
        ),
        closable: true,
        onCancel: () => navigate(PATHS.APPLICATIONS),
      });
    },
    onError: errorMessage,
  });
  const onFinish = (formData) => {
    const dataToSave = modifySaveValues(
      formData,
      isLegal,
      profileId,
      companyId,
      isRepresentative,
      representativeProfileId
    );
    createAgreement(dataToSave);
  };

  return (
    <Spin spinning={isLegal ? false : isLoading}>
      <Form
        form={form}
        layout="vertical"
        preserve={true}
        autoComplete={false}
        name="donor-agreement-form"
        onFinish={onFinish}
      >
        <VerticalSpace>
          {isLegal ? <CompanyInfo companyData={data} /> : <ApplicantInfo personData={data} />}
          <DonorAddress data={data} isLegal={isLegal} />
          <SupportDetails isLegal={isLegal} />
          <FormSectionFullCard>
            <Form.Item
              name={'notifyTypeId'}
              rules={[{ required: true }]}
              label={<Text color={COLORS.PRIMARY.BLUE}>Հետադարձ կապը՝</Text>}
            >
              <Checkbox.Group>
                <Checkbox value={1} disabled>Հեռախոսահամարով</Checkbox>
                <Checkbox value={2}>Էլ. փոստով</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </FormSectionFullCard>
          <Divider style={{ borderWidth: '2px' }} />
          <Space style={{ justifyContent: 'center', display: 'flex' }}>
            <Button type="primary" htmlType="submit">
              Ուղարկել
            </Button>
          </Space>
        </VerticalSpace>
      </Form>
    </Spin>
  );
};
