import { Card, Col, Form, Row, Spin, Steps, message } from 'antd';
import { DisabilityButton } from 'components/button';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DisabilityApplicant } from './steps/disability-applicant';
import { VerticalSpace } from 'components/space/vertical-space';
import { DisabilityPersonalData } from './steps/disability-personal-data';
import { DisabilityGeneralInformation } from './steps/disability-general-information';
import { DisabilityVitalityDifficulties } from './steps/disability-vitality-difficulties';
import { DisabilityDeviceRequirement } from './steps/disability-device-requirement';
import { DisabilityDailyRoutine } from './steps/disability-daily-routine';
import { useNavigate, useParams } from 'react-router-dom';
import { useOnSave } from './use-on-save';
import { URL_GET_EVALUATION_WITH_SUBTYPES } from 'api/benefit/constants';
import { PATHS } from 'helpers/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';

const steps = [
  {
    title: 'Դիմումատու',
    content: <DisabilityApplicant />,
    subTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  {
    title: 'Անձնական տվյալներ ',
    content: <DisabilityPersonalData />,
    subTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  {
    title: 'Ընդհանուր տվյալներ',
    content: <DisabilityGeneralInformation />,
    subTypes: [1, 2, 3, 4, 5],
  },
  {
    title: 'Կենսագործունեություն',
    content: <DisabilityVitalityDifficulties />,
    subTypes: [1, 2, 3, 4, 5],
  },
  {
    title: 'Կարիքի նկարագրություն',
    content: <DisabilityDeviceRequirement />,
    subTypes: [1, 2, 3, 4, 5],
  },
  {
    title: 'Առօրյա գործունեություն',
    content: <DisabilityDailyRoutine />,
    subTypes: [1, 2, 3, 4, 5],
  },
];

const StepsWrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: #0b847f26;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSteps = styled(Steps)`
  .ant-steps-item-container {
    height: 80px;
  }
`;

const StyledCard = styled(Card)`
  min-height: 100vh;
  height: 100%;

  box-shadow: 2px 2px 4px #00000040;
  padding-bottom: 44px;
  .ant-card-body {
    min-height: 100vh;
    height: 100%;
    overflow: auto;
  }
`;

export const DisabilityApplication = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();

  const { data: disabilityTypes } = useGetFfromBenefit(URL_GET_EVALUATION_WITH_SUBTYPES);

  useEffect(() => {
    if (disabilityTypes) {
      const subTypeExists = disabilityTypes
        .find((item) => item.id === +params.typeId)
        ?.items.find((subItem) => subItem.id === +params.subtypeId);
      if (!subTypeExists) {
        navigate(PATHS.DISABILITY_TYPE);
      }
    }
  }, [disabilityTypes, navigate, params.subtypeId, params.typeId]);

  const items = steps
    .filter((item) => item.subTypes.includes(+params.subtypeId))
    .map((item) => ({
      key: item.title,
      title: item.title,
    }));

  const onFinish = useOnSave(form);

  const next = () => {
    setCurrent(current + 1);
    document.getElementById('disability-page').scrollIntoView();
  };
  const prev = () => {
    setCurrent(current - 1);
    document.getElementById('disability-page').scrollIntoView();
  };
  return (
    <>
      <Row>
        <Col xs={10} lg={6}>
          <StepsWrapper>
            <div style={{ width: '70%', margin: 'auto' }}>
              <StyledSteps current={current} items={items} direction="vertical" />
            </div>
          </StepsWrapper>
        </Col>
        <Col xs={14} lg={18}>
          <div style={{ padding: '40px 20px 0 20px' }}>
            <StyledCard>
              <VerticalSpace
                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100vh' }}
              >
                <Spin spinning={false}>
                  <Form
                    layout="vertical"
                    name="disability-application"
                    form={form}
                    autoComplete="off"
                    preserve
                    onFinish={onFinish}
                    onFinishFailed={(error) => {
                      // eslint-disable-next-line no-console
                      console.log('error', error);
                    }}
                    scrollToFirstError
                  >
                    <div id="disability-page">{steps[current].content}</div>
                  </Form>
                </Spin>
                <Row gutter={16} justify="center">
                  {current !== 0 && (
                    <Col span={6}>
                      <DisabilityButton type="default" block onClick={() => prev()}>
                        Նախորդ
                      </DisabilityButton>
                    </Col>
                  )}
                  {current + 1 === items.length && (
                    <Col span={6}>
                      <DisabilityButton
                        size="large"
                        block
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          form.submit();
                        }}
                      >
                        Հաստատել
                      </DisabilityButton>
                    </Col>
                  )}
                  {current + 1 !== items.length && (
                    <Col span={6}>
                      <DisabilityButton
                        type="primary"
                        block
                        onClick={async () => {
                          try {
                            await form.validateFields();
                            next();
                          } catch (errorInfo) {
                            message.error('Կան սխալ լրացված դաշտեր');
                          }
                        }}
                      >
                        Հաջորդ
                      </DisabilityButton>
                    </Col>
                  )}
                </Row>
              </VerticalSpace>
            </StyledCard>
          </div>
        </Col>
      </Row>
    </>
  );
};
