import { useAuth } from 'context/auth-context';
import { PATHS } from 'helpers/constants';
import { Navigate, Outlet } from 'react-router-dom';

export const CompanyRoute = () => {
  const {
    user: { isLegal },
  } = useAuth();

  if (!isLegal) {
    return <Navigate to={PATHS.SERVICES} />;
  }

  return <Outlet />;
};
