import { Col, Form, Row } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormSectionCard } from 'components/card/form-section-card';
import { Datepicker } from 'components/datepicker';
import { Input } from 'components/input';
import { PhoneNumber } from 'components/input/phone-number';
import dayjs from 'dayjs';
import { phonePattern } from 'helpers/constants';
import { useEffect } from 'react';

export const ApplicantInfo = ({ personData = {} }) => {
  const form = useFormInstance();
  useEffect(() => {
    form.setFields([
      {
        name: ['mainData', 'firstName'],
        value: personData.firstName,
      },
      {
        name: ['mainData', 'lastName'],
        value: personData.lastName,
      },
      {
        name: ['mainData', 'patronymicName'],
        value: personData.patronymicName,
      },
      {
        name: ['mainData', 'birthdate'],
        value: dayjs(personData.birthdate),
      },
      {
        name: ['mainData', 'ssn'],
        value: personData.ssn,
      },
      {
        name: ['mainData', 'gender'],
        value: personData.sex,
      },
      {
        name: ['mainData', 'phoneNumber'],
        value: personData?.phoneNumber,
      },
      {
        name: ['mainData', 'email'],
        value: personData?.email,
      },
      {
        name: ['mainData', 'profileId'],
        value: personData?.profileId,
      },
    ]);
  }, [form, personData]);
  return (
    <FormSectionCard title={'Դիմումատուի անձնական տվյալներ'}>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Անուն'} rules={[{ required: true }]} name={['mainData', 'firstName']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Ազգանուն'} rules={[{ required: true }]} name={['mainData', 'lastName']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Հայրանուն'} rules={[{ required: true }]} name={['mainData', 'patronymicName']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item label={'Ծննդյան ամսաթիվ'} rules={[{ required: true }]} name={['mainData', 'birthdate']}>
            <Datepicker format="DD/MM/YYYY" style={{ borderRadius: 10, width: '100%' }} disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item label={'Սեռ'} rules={[{ required: true }]} name={['mainData', 'gender']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
          <Form.Item
            label={'ՀԾՀ/ՀԾՀ չունենալու վերաբերյալ տեղեկանքի համար'}
            rules={[{ required: true }]}
            name={['mainData', 'ssn']}
          >
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Հեռախոսահամար'}
            rules={[
              {
                required: true,
                pattern: phonePattern,
                message: 'Սխալ հեռախոսի ֆորմատ, պետք է լինի +374XXXXXXXX ֆորմատով',
              },
            ]}
            name={['mainData', 'phoneNumber']}
          >
            <PhoneNumber />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={'Էլ․ փոստի հասցե'} rules={[{ required: true }]} name={['mainData', 'email']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item hidden name={['mainData', 'profileId']} />
    </FormSectionCard>
  );
};
