import { Form } from 'antd';
import { childFoodOptions, foodOptions } from '../constants';
import { Checkbox } from 'components/checkbox';

import { NeedMatchCard } from './need-match-card';

import { PATHS } from 'helpers/constants';
import { useMatches } from 'react-router-dom';
import { arePathsEqual } from '../utils';

export const FoodCheckbox = ({ parentFormItem, itemData = [], isView }) => {
  const checklist = Form.useWatch([...parentFormItem], {
    preserve: true,
  });
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const validateAtLeastOneChecked = (_, value) => {
    const hasChecked =
      value &&
      Object.values(value).some((v) => {
        if (typeof v === 'object') {
          return Object.values(v).some((subV) => subV === true);
        }
        return v === true;
      });

    if (value?.babyFood) {
      const hasSelected = Object.values(value?.babyFood).some((v) => {
        return v === true;
      });
      if (!hasSelected) {
        return Promise.reject(new Error('Խնդրում ենք ընտրել բացված արժեքներից առնվազն մեկը'));
      }
    }
    if (!hasChecked) {
      return Promise.reject(new Error('Խնդրում ենք ընտրել բացված արժեքներից առնվազն մեկը'));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form.Item name={parentFormItem} rules={[{ validator: validateAtLeastOneChecked }]}>
        <>
          {foodOptions.map((option) => {
            const [currentFoodData] = itemData.filter(
              (item) => item?.foodType[option.fieldName] && option.fieldName !== 'babyFood'
            );
            return (
              <div key={option.id}>
                <Form.Item name={[...parentFormItem, option.fieldName]} valuePropName="checked" style={{ margin: 0 }}>
                  <Checkbox value={option.id} style={{ marginLeft: '20px' }}>
                    {option.label}
                  </Checkbox>
                </Form.Item>
                {isView &&
                  currentFoodData?.foodType?.[option.fieldName] &&
                  arePathsEqual(match.pathname, PATHS.RAPID_NEED_VIEW) && <NeedMatchCard itemData={currentFoodData} />}
                {checklist?.babyFood &&
                  option.id === 3 &&
                  childFoodOptions.map((childOption) => {
                    const childFoodData = itemData.filter((item) => item?.foodType?.babyFood);
                    const [currentChildFoodData] = childFoodData.filter(
                      (item) => item?.foodType?.babyFood?.[childOption?.fieldName]
                    );
                    return (
                      <div key={childOption.id} style={{ marginLeft: '40px' }}>
                        <Form.Item
                          key={childOption.id}
                          name={[...parentFormItem, option.fieldName, childOption.fieldName]}
                          valuePropName="checked"
                          style={{ margin: 0 }}
                        >
                          <Checkbox value={childOption.id} style={{ marginLeft: '40px' }}>
                            {childOption.label}
                          </Checkbox>
                        </Form.Item>
                        {isView &&
                          currentChildFoodData?.foodType?.babyFood?.[childOption.fieldName] &&
                          arePathsEqual(match.pathname, PATHS.RAPID_NEED_VIEW) && (
                            <NeedMatchCard itemData={currentChildFoodData} />
                          )}
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </>
      </Form.Item>
    </>
  );
};
