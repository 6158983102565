import { Col, Form, Row } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';

export const ApplicationInfo = () => {
  return (
    <FormSectionCard title={'Հայտի տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="num" label="Համաձայնագրի հայտ">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name="createdDate" label="Ներկայացման ամսաթիվ">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
