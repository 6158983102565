import { AUTH_KEYS, DATE_FORMAT_YYYYMMDD, DATE_FORMAT_YYYY_MM_DD, DEFAULT_ICON } from './constants';
import { iconsList } from '../components/icon';
import dayjs from 'dayjs';
import { Modal } from 'antd';

export const noop = () => {
  return;
};

/** Checks if icon exists in our list */
export const checkIconInList = (iconName) => iconsList.includes(iconName);

export const getIconName = (iconName) => (checkIconInList(iconName) ? iconName : DEFAULT_ICON);

/** Get logged in user data from local storage */
export const logedInUser = localStorage.getItem(AUTH_KEYS.USER)
  ? JSON.parse(localStorage.getItem(AUTH_KEYS.USER) || '')
  : null;

export const stripTrailingSlash = (str) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export function validatePassword(password) {
  // Check complexity
  if (!/\d/.test(password) || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[^a-zA-Z\d]/.test(password)) {
    return false;
  }

  // If all checks pass, return true
  return true;
}

export const dateConvert = (date, format = DATE_FORMAT_YYYYMMDD) => dayjs(date).format(format);

export const convertOldDate = (date, format = DATE_FORMAT_YYYY_MM_DD) => dayjs(date).format(format);

export const errorMessage = (er) => {
  Modal.error({
    title: 'Տեղի է ունեցել սխալ',
    content: <div style={{ fontSize: '18px' }}>{er.response?.data?.Message}</div>,
    // width: '30%',
    footer: false,
    closable: true,
  });
};

export const errorMessageOTP = (er, path) => {
  Modal.error({
    title: 'Տեղի է ունեցել սխալ',
    content: er,
    onOk: () => {
      path;
    },
  });
};

export function calculateAge(birthdate) {
  return dayjs().diff(birthdate, 'year');
}

export const createOptionsFromArray = (data) => data.map((item) => ({ label: item.name, value: item.id }));
