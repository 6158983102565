import { Form, Spin } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { useNavigate } from 'react-router-dom';
import { URL_CITIZEN_INFO } from 'api/application/constants';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';
import { errorMessage } from 'helpers/utils';
import dayjs from 'dayjs';
import { PATHS } from 'helpers/constants';
import { useEffect } from 'react';
import { useDataForDisabilityApplication } from 'api/application/use-get-data-for-disability-application';
import { DisabilityFamilyData } from '../components/disability-family-data';
import { DisabilityEducationWorkData } from '../components/disability-education-work-data';

export const DisabilityGeneralInformation = () => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const navigate = useNavigate();
  const form = Form.useFormInstance();
  const { isInitialLoading, data } = useDataForDisabilityApplication(
    URL_CITIZEN_INFO,
    {},
    {
      enabled: applicantType === APPLICATION_FOR_HIMSELF,
      onError: (err) => {
        errorMessage(err);
        navigate(PATHS.SERVICES);
      },
    }
  );

  useEffect(() => {
    if (data.id && applicantType === APPLICATION_FOR_HIMSELF) {
      form.setFieldsValue({
        id: data.id,
        profileId: data.profileId,
        applicationForm: {
          ...data,
          birthdate: dayjs(data.birthdate),
          gender: data.sex,
          ...(data.documents.length === 1
            ? {
                documentType: data.documents[0].documentType,
                documentNumber: data.documents[0].documentNumber,
              }
            : {}),
        },
        registrationAddress: data.registrationAddress,
      });
    }
  }, [applicantType, data, form]);

  return (
    <Spin spinning={isInitialLoading}>
      <VerticalSpace>
        <DisabilityFamilyData />
        <DisabilityEducationWorkData />
      </VerticalSpace>
    </Spin>
  );
};
