import { Col, DatePicker, Form, InputNumber, Row, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_CURRENCIES, URL_GET_VULNERABILITY } from 'api/urrp/constants';
import { FormSectionCard } from 'components/card/form-section-card';
import { Datepicker } from 'components/datepicker';

import dayjs from 'dayjs';
import { createOptionsFromArray } from 'helpers/utils';

export const SupportInfo = () => {
  const { data: currency } = useGetFfromBenefit(URL_GET_CURRENCIES);
  const { data: targetGroup } = useGetFfromBenefit(URL_GET_VULNERABILITY);
  const form = useFormInstance();

  const disabledStartDate = (current) => {
    // Disable dates before today
    return current && current.isBefore(dayjs(), 'day');
  };

  const disabledEndDate = (current) => {
    // Disable dates before today and dates before start date
    const startDate = form.getFieldValue(['programDetails', 'programStartDate']);

    if (!startDate) {
      return current && current.isBefore(dayjs(), 'day');
    }
    return (
      current &&
      (current.isBefore(dayjs(), 'day') ||
        current.isBefore(dayjs(startDate), 'day') ||
        current.isSame(startDate, 'day'))
    );
  };

  return (
    <FormSectionCard title={'Աջակցության տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'targetGroup']} label="Թիրախային խումբ" rules={[{ required: true }]}>
            <Select options={createOptionsFromArray(targetGroup)} labelInValue />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'budget']} label="Բյուջե" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} min={1} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'currency']} label="Արժույթ" rules={[{ required: true }]}>
            <Select options={createOptionsFromArray(currency)} labelInValue />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['programDetails', 'beneficiaryNumber']}
            label="Շահառուների նախատեսված թիվ"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} min={1} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['programDetails', 'programStartDate']} label="Ծրագրի սկիզբ" rules={[{ required: true }]}>
            <Datepicker
              format="DD/MM/YYYY"
              style={{ borderRadius: 10, width: '100%' }}
              disabledDate={disabledStartDate}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['programDetails', 'programEndDate']}
            label="Ծրագրի ավարտ"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const startDate = getFieldValue(['programDetails', 'programStartDate']);
                  const agreementEndDate = dayjs(getFieldValue(['programDetails', 'agreementDeadline']), 'DD.MM.YYYY');

                  if (
                    (value && startDate && value.isBefore(dayjs(startDate), 'day')) ||
                    value.isSame(dayjs(startDate), 'day')
                  ) {
                    return Promise.reject(
                      new Error('Ծրագրի ավարտ դաշտի օրը Ծրագրի սկիզբ դաշտի օրվանից պետք է մեծ լինի')
                    );
                  }
                  if (value && agreementEndDate.isValid() && value.isAfter(agreementEndDate, 'day')) {
                    return Promise.reject(new Error('Ծրագրի ավարտը չի կարող մեծ լինել Համաձայնագրի վերջնաժամկետից'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              style={{ borderRadius: 10, width: '100%' }}
              disabledDate={(current) => disabledEndDate(current)}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
