import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { PATHS } from '../../helpers/constants';
import { useAuth } from '../../context/auth-context';

export const PrivateRoute = () => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to={PATHS.SIGN_IN} state={{ from: location.pathname }} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};
