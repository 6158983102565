import { WarningOutlined } from '@ant-design/icons';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';

export const requiredMessage = (
  <span>
    <WarningOutlined type="info-circle" /> Պարտադիր լրացման դաշտ
  </span>
);

export const patternMessage = <span>Տեղեկատվությունը սխալ է լրացված</span>;

export const FAMILY_STATUS = [
  {
    id: 1,
    label1: 'Ամուսնացած եմ',
    label2: 'Ամուսնացած է',
    IsChild: false,
    value: 1,
  },
  {
    id: 2,
    label1: 'Ամուսնացած չեմ',
    label2: 'Ամուսնացած չէ',
    IsChild: false,
    value: 2,
  },
  {
    id: 3,
    label1: 'Այրի եմ',
    label2: 'Այրի է',
    IsChild: false,
    value: 3,
  },
  {
    id: 4,
    label1: 'Ամուսնալուծված եմ',
    label2: 'Ամուսնալուծված է',
    IsChild: false,
    value: 4,
  },
  {
    id: 5,
    label1: 'Միայնակ հայր/մայր եմ',
    label2: 'Միայնակ հայր/մայր է',
    IsChild: false,
    value: 5,
  },
  {
    id: 6,
    label1: 'Երեխա եմ',
    label2: 'Երեխա է',
    IsChild: true,
    value: 6,
  },
];

export const LIVING_ITEMS = [
  {
    id: 1,
    label1: 'Հոր եւ մոր հետ',
    label2: 'Հոր եւ մոր հետ',
    name: 'choose_living',
  },
  {
    id: 2,
    label1: 'Ոորդեգրված եմ',
    label2: 'Որդեգրված է',
    name: 'choose_living',
  },
  {
    id: 3,
    label1: 'Խնամակալության տակ եմ',
    label2: 'Խնամակալության տակ է',
    name: 'choose_living',
  },
  {
    id: 4,
    label1: 'Հաստատությունում եմ',
    label2: 'Հաստատությունում է',
    name: 'choose_living',
  },
  {
    id: 5,
    label1: 'Միայնակ ծնողի հետ',
    label2: 'Միայնակ ծնողի հետ',
    name: 'choose_living',
  },
  {
    id: 6,
    label1: 'Խնամատար ընտանիքում եմ ապրում',
    label2: 'Խնամատար ընտանիքում է ապրում',
    name: 'choose_living',
  },
  {
    id: 7,
    label1: 'Ընտանիքիս հետ',
    label2: 'Ընտանիքի հետ',
    name: 'choose_living',
  },
  {
    id: 8,
    label1: 'Միայնակ',
    label2: 'Միայնակ',
    name: 'choose_living',
  },
  {
    id: 9,
    label1: 'Այլ',
    label2: 'Այլ',
    name: 'choose_living',
  },
];

export const SELF_CARE_DIFFICULTIES = [
  {
    label: 'Այո',
    value: true,
    options: [
      {
        label: 'լվացվելիս',
        value: 1,
      },
      {
        label: 'լոգանք ընդունելիս',
        value: 2,
      },
      {
        label: 'խմելիս',
        value: 3,
      },
      {
        label: 'հագնվելիս',
        value: 4,
      },
    ],
  },
  {
    label: 'Ոչ',
    value: false,
    options: [],
  },
];

export const HOUSE_HOLD_ITEM = [
  {
    label: 'Այո',
    value: true,
    options: [
      {
        label: 'տնային մաքրության գործեր անելիս',
        value: 1,
      },
      {
        label: 'կերակուր պատրաստելիս',
        value: 2,
      },
      {
        label: 'բակը/հողամասը խնամելիս',
        value: 3,
      },
      {
        label: 'գնումներ կատարելիս',
        value: 4,
      },
    ],
  },
  {
    label: 'Ոչ',
    value: false,
    options: [],
  },
];

export const MOVEMENT_DIFFICULTIES = [
  {
    label: 'Այո',
    value: true,
    options: [
      {
        label: 'քայլելիս',
        value: 1,
      },
      {
        label: 'աստիճաններով բարձրանալիս',
        value: 2,
      },
      {
        label: 'բնակարանում կամ տանը ազատ տեղաշարժվելիս',
        value: 3,
      },
      {
        label: 'բնակարանից կամ տանից դուրս ազատ տեղաշարժվելիս',
        value: 4,
      },
    ],
  },
  {
    label: 'Ոչ',
    value: false,
    options: [],
  },
];

export const BODY_POSITION = [
  {
    label: 'Այո',
    value: true,
    options: [
      {
        label: 'կանգնելիս',
        value: 1,
      },
      {
        label: 'նստելիս',
        value: 2,
      },
      {
        label: 'կքանստելիս',
        value: 3,
      },
      {
        label: 'պառկելիս',
        value: 4,
      },
      {
        label: 'առանց օգնության նստած դիրքով այլ տեղ տեղափոխվելիս',
        value: 5,
      },
    ],
  },
  {
    label: 'Ոչ',
    value: false,
    options: [],
  },
];

export const COMMUNICATION_DIFFICULTIES = [
  {
    label: 'Այո',
    value: true,
    options: [
      {
        label: 'Ընտանիքի անդամների հետ շփվելիս',
        value: 1,
      },
      {
        label: 'ընկերների հետ շփվելիս',
        value: 2,
      },
      {
        label: 'այլ ծանոթների հետ շփվելիս',
        value: 3,
      },
      {
        label: 'անծանոթների հետ շփվելիս',
        value: 4,
      },
    ],
  },
  {
    label: 'Ոչ',
    value: false,
    options: [],
  },
];

export const getTransportList = (applicantType) => [
  {
    label: 'Այո',
    value: true,
    options: [
      {
        label: `խուսափում ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'է'} մարդաշատ վայրերից`,
        value: 1,
      },
      {
        label: `գումար ${applicantType === APPLICATION_FOR_HIMSELF ? 'չունեմ' : 'չունի'}`,
        value: 2,
      },
      {
        label: 'հարմարեցված պայմաններ չկան',
        value: 3,
      },
    ],
  },
  {
    label: 'Ոչ',
    value: false,
    options: [],
  },
];

export const smokeItemList = (applicantType) => [
  {
    label: `երբեք ${applicantType === APPLICATION_FOR_HIMSELF ? 'չեմ' : 'չի'} ծխել`,
    value: 1,
    IsChild: false,
  },
  {
    label: `թողել ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'է'} անձնական ցանկությամբ`,
    value: 2,
    IsChild: false,
  },
  {
    label: `թողել ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'է'} հիվանդության պատճառով`,
    value: 3,
    IsChild: false
  },
  {
    label: `երեխա ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'է'}`,
    value: 4,
    IsChild: true,
  },
];

export const familyComposition = (applicantType) => [
  {
    id: 1,
    label: 'Ամուսին',
    value: 1,
  },
  {
    id: 2,
    label: 'Կին',
    value: 2,
  },
  {
    id: 3,
    label: 'Տատիկ',
    value: 3,
  },
  {
    id: 4,
    label: 'Քույր',
    value: 4,
  },
  {
    id: 9,
    label: applicantType === APPLICATION_FOR_HIMSELF ? 'Չունեմ ընտանիքի անդամներ' : 'Չունի ընտանիքի անդամներ',
    value: 9,
  },
  {
    id: 6,
    label: 'Պապիկ',
    value: 6,
  },
  {
    id: 7,
    label: 'Մայր',
    value: 7,
  },
  {
    id: 8,
    label: 'Հայր',
    value: 8,
  },

  {
    id: 5,
    label: 'Եղբայր',
    value: 5,
  },
  {
    id: 10,
    label: 'Այլ',
    value: 10,
  },
];

export const TRUE_FALSE_OPTIONS = [
  {
    id: 1,
    value: 1,
    name: 'Այո',
    // name: 'check_true',
  },
  {
    id: 2,
    value: 2,
    name: 'Ոչ',
  },
];

export const CAREGIVER_CASE = [
  {
    id: 1,
    name: 'ընտանիքի անդամ',
    value: 1,
  },
  {
    id: 2,
    name: 'հարազատ',
    value: 2,
  },
  {
    id: 3,
    name: 'հաստատություն',
    value: 3,
  },
  {
    id: 4,
    name: 'կազմակերպություն',
    value: 4,
  },
  {
    id: 5,
    name: 'վճարովի ծառայություն',
    value: 5,
  },
];

export const HAVE_PROFESSION = [
  {
    id: '1',
    label1: 'Ունեմ',
    label2: 'Ունի',
    name: 'check_true',
    value: 1,
  },
  {
    id: '2',
    label1: 'Չունեմ',
    label2: 'Չունի',
    name: 'check_true',
    value: 2,
  },
];
