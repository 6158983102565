import { Checkbox } from 'components/checkbox';
import { FormItem } from 'components/form/form-item';
import { Form } from 'antd';

export const DisabilityTrustProvide = () => {
  const form = Form.useFormInstance();

  const handleCheckChange = (e) => {
    if (!e.target.checked) {
      form.setFieldValue('iProvided', null);
    }
  };
  return (
    <FormItem name="iProvided" rules={[{ required: true }]} valuePropName="checked">
      <Checkbox onChange={handleCheckChange}>Ես հավաստում եմ, որ իմ կողմից տրամադրած տեղեկատվությունը համապատասխանում է իրականությանը</Checkbox>
    </FormItem>
  );
};
