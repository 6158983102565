import { useState } from 'react';
import { Col, Divider, Row, Select, Radio, Input, Form } from 'antd';
import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { CAREGIVER_CASE, HAVE_PROFESSION, patternMessage, requiredMessage } from '../constants';
import { URL_GET_ALL_EDUCATIONS, URL_GET_ALL_OCCUPATIONS } from 'api/benefit/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { FIELD_NAMES_BY_LABEL1, FIELD_NAMES_BY_LABEL2 } from 'components/select/constants';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';
import { InputNumber } from 'components/input-number';
import { Title } from 'components/typography';
import { ExclamationCircleOutlined } from '@ant-design/icons';


export const DisabilityEducationWorkData = () => {
  const guardian = Form.useWatch(['selfAssessment', 'guardian', 'have']);
  const [profession, setProfession] = useState(null);
  const [workPositionYear] = useState([1, 2, 4]);
  const { data: educations } = useGetFfromBenefit(URL_GET_ALL_EDUCATIONS);
  const { data: occupations } = useGetFfromBenefit(URL_GET_ALL_OCCUPATIONS);
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const occupation = Form.useWatch(['selfAssessment', 'occupation', 'typeId'], { preserve: true });
  const personData = Form.useWatch(['applicationForm'], { preserve: true });

  const form = Form.useFormInstance();

  return (
    <VerticalSpace>
      <VerticalSpace size="small">
        <Row>
          <Col span={6}>
            <FormItem
              name={['selfAssessment', 'guardian', 'have']}
              label={
                <Title level={3} color="#0B847F">
                  {applicantType === APPLICATION_FOR_HIMSELF ? 'Ունե՞ք խնամող անձ' : 'Ունի՞ խնամող անձ'}
                </Title>
              }
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { value: true, label: 'Այո' },
                  { value: false, label: 'Ոչ' },
                ]}
                labelInValue
              ></Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={10} style={{ marginTop: '20px', marginLeft: '30px' }}>
            {guardian && guardian?.value === true && (
              <FormItem name={['selfAssessment', 'guardian', 'guardianType', 'typeId']} rules={[{ required: true }]}>
                <Radio.Group
                  // onChange={(e) => console.log('Selected:', e.target.value)}
                  style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                  className="radlo_button"
                >
                  {CAREGIVER_CASE.map((caseItem) => (
                    <Row key={caseItem.id}>
                      <VerticalSpace>
                        <Radio value={caseItem.value}>{caseItem.name}</Radio>
                      </VerticalSpace>
                    </Row>
                  ))}
                </Radio.Group>
              </FormItem>
            )}
          </Col>
        </Row>
      </VerticalSpace>
      <Divider />
      <VerticalSpace size="small">
        <Row>
          <Col span={8}>
            <FormItem
              name={['selfAssessment', 'education', 'typeId']}
              label={
                <Title level={3} color="#0B847F">
                  Կրթություն՝ նշել վերջին կրթական վայրը
                </Title>
              }
              rules={[{ required: true }]}
            >
              <Select
                options={educations}
                labelInValue
                fieldNames={applicantType === APPLICATION_FOR_HIMSELF ? FIELD_NAMES_BY_LABEL2 : FIELD_NAMES_BY_LABEL1}
              ></Select>
            </FormItem>
          </Col>
        </Row>
      </VerticalSpace>
      <Divider />
      <VerticalSpace size="small">
        <Row>
          <Col span={6}>
            <FormItem
              name={['selfAssessment', 'profession', 'have']}
              label={
                <Title level={3} color="#0B847F">
                  Մասնագիտություն
                </Title>
              }
              rules={[{ required: true }]}
            >
              <Select
                options={HAVE_PROFESSION}
                labelInValue
                onChange={(option) => setProfession(option.value === '1')}
                fieldNames={applicantType === APPLICATION_FOR_HIMSELF ? FIELD_NAMES_BY_LABEL1 : FIELD_NAMES_BY_LABEL2}
              ></Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            {profession && (
              <FormItem
                name={['selfAssessment', 'profession', 'whatKindOf']}
                rules={[
                  { required: true, message: requiredMessage },
                  { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                ]}
                label={
                  <Title level={3} color="#0B847F">
                    Լրացնել
                  </Title>
                }
              >
                <Input size="large" style={{ borderRadius: 10, height: '46px' }} />
              </FormItem>
            )}
          </Col>
        </Row>
      </VerticalSpace>
      <Divider />
      <VerticalSpace size="small">
        <Row>
          <Col span={6}>
            <FormItem
              name={['selfAssessment', 'occupation', 'typeId']}
              label={
                <Title level={3} color="#0B847F">
                  Զբաղվածություն
                </Title>
              }
              rules={[{ required: true }]}
            >
              <Select
                options={occupations?.filter(item => {
                  if (personData?.age >= 16) {
                    return !item.isChild;
                  }
                  return true
                })}
                labelInValue
                fieldNames={applicantType === APPLICATION_FOR_HIMSELF ? FIELD_NAMES_BY_LABEL1 : FIELD_NAMES_BY_LABEL2}
                onChange={() => {
                  form.setFieldsValue({
                    selfAssessment: {
                      workPosition: '',
                    },
                  });
                  form.setFieldsValue({
                    selfAssessment: {
                      numberOfYearWorked: '',
                    },
                  });
                }}
              ></Select>
            </FormItem>
          </Col>
        </Row>

        {occupation?.value && workPositionYear.includes(occupation?.value) && (
          <Row gutter={16}>
            <Col span={10}>
              <FormItem
                name={['selfAssessment', 'workPosition']}
                rules={[{ required: true }]}
                label={
                  <Title level={3} color="#0B847F">
                    {applicantType === APPLICATION_FOR_HIMSELF ?
                      (occupation?.value === 2 || occupation?.value === 4 ? 'Որպես ի՞նչ եք աշխատել' : 'Որպես ի՞նչ եք աշխատում') :
                      (occupation?.value === 2 || occupation?.value === 4 ? 'Որպես ի՞նչ է աշխատել' : 'Որպես ի՞նչ է աշխատում')}

                  </Title>
                }
              >
                <Input.TextArea rows={5} size="large" autosize={{ minRows: 3, maxRows: 4 }} placeholder="Լրացնել" />
              </FormItem>
            </Col>
            <Col span={10}>
              <FormItem
                name={['selfAssessment', 'numberOfYearWorked']}
                rules={[{ required: true }]}
                label={
                  <Title level={3} color="#0B847F">
                    Տարի
                  </Title>
                }
              >
                <InputNumber
                  size="large"
                  min={0}
                  style={{ borderRadius: 10, height: '46px', width: '246px' }}
                  placeholder="20 ՏԱՐԻ"
                />
              </FormItem>
              <p style={{ marginTop: '15px', color: '#555965' }}>
                <ExclamationCircleOutlined /> Աշխատած տարիների ընդհանուր թիվը
              </p>
            </Col>
          </Row>
        )}
      </VerticalSpace>
      <Divider />
    </VerticalSpace>
  );
};
