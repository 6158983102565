import { Form, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { AgreementTabs } from './tabs/agreement-tabs';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useGetData } from 'api/dictionary/use-get-data';
import { Button } from 'components/button';
import { URL_GET_AGREEMENT_APPLICATION_BY_ID } from 'api/application/constants';
import { DONOR_APPLICATION_STATUSES } from 'pages/rapid-need/constants';
import { RejectModal } from '../components/reject-modal';
import { useState } from 'react';

export const Agreement = () => {
  const { id } = useParams();
  const [form] = useForm();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  const { data, isLoading } = useGetData(
    URL_GET_AGREEMENT_APPLICATION_BY_ID,
    {
      agreementId: id,
    },
    {
      enabled: !!id,
      onSuccess: (data) => {
        const formData = data.data;
        form.setFieldsValue({
          ...formData,
          ...formData.agreementData,
          num: `N ${formData.num}`,
          mainData: {
            ...formData.agreementData.mainData,
            birthdate: dayjs(formData.agreementData.mainData.birthdate),
          },
          agreementData: {
            notifyTypeId: formData?.agreementData?.notifyTypeId === 3 ? [1, 2] : [formData?.agreementData?.notifyTypeId],
          },
        });
      },
    }
  );
  return (
    <Spin spinning={isLoading}>
      <Form form={form} layout="vertical" preserve={true} autoComplete={false} name="agreement-form" disabled>
        <AgreementTabs />
      </Form>
      {data.status !== DONOR_APPLICATION_STATUSES.DENIED || data.status !== DONOR_APPLICATION_STATUSES.SUSPENDED && (
        <Space style={{ justifyContent: 'center', display: 'flex', margin: '40px 0' }}>
          <Button onClick={() => setRejectModalOpen(true)} type="primary">
            Դադարեցնել
          </Button>
        </Space>
      )}
      <RejectModal visible={rejectModalOpen} setVisible={setRejectModalOpen} agreementId={id} />
    </Spin>
  );
};
