import { Button } from 'antd';
import styled from 'styled-components';

const StyledDisabilityButton = styled(Button)`
  color: ${({ type }) => (type === 'default' ? '#0B847F' : '#fff')};
  background-color: ${({ type }) => (type === 'default' ? '#fff' : '#0B847F')};
  border-color: '#0B847F';

  &&:hover {
    background-color: ${({ type }) => (type === 'default' ? '#fff' : '#0B847F')};
  }
`;

export const DisabilityButton = (props) => {
  return <StyledDisabilityButton size="large" shape="round" {...props} />;
};
