import { Card as CardComponent } from 'antd';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';

export const PcCard = styled(CardComponent)`
  && {
    .ant-card {
      text-align: center;
      border: 2px solid ${COLORS.SECONDARY.AQUA_BLUE};
      border-radius: 8;
      width: 261px;
    }

    .ant-card-cover {
      display: flex;
      justify-content: center;
    }
  }
`;
