import { Col, Form, Row } from 'antd';

import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';
import { PhoneNumber } from 'components/input/phone-number';
import { phonePattern } from 'helpers/constants';

export const CompanyInfo = () => {
  return (
    <FormSectionCard title={'Կազմակերպության տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item label="Կազմակերպության անվանում" name={['companyData', 'companyName']}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item label="ՀՎՀՀ" name={['companyData', 'tin']}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item label="Կազմակերպաիրավական տեսակը" name={['companyData', 'companyType']}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item
            label="Էլեկտրոնային փոստի հասցե"
            name={['companyData', 'companyEmail']}
            rules={[{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item
            label="Հեռախոսի համար"
            name={['companyData', 'phone']}
            rules={[
              {
                required: true,
                pattern: phonePattern,
                message: 'Սխալ հեռախոսի ֆորմատ, պետք է լինի +374XXXXXXXX ֆորմատով',
              },
            ]}
          >
            <PhoneNumber />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item hidden name={['mainData', 'companyId']} />
    </FormSectionCard>
  );
};
