import dayjs from 'dayjs';
import { files, utilityAccounts } from '../../../pages/application/constants';
import { dateConvert } from 'helpers/utils';
import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/constants';

export const citizenData = {
  actualAddress: null,
  animals: [],
  registrationAddress: null,
  citizenInfo: null,
};

export const initialValues = {
  step: null,
  id: 0,
  tempApplicationId: null,
  profileId: '',
  profileData: null,
  citizenData: null,
  familyData: null,
  applicationProperties: null,
  applicationType: 1,
};

export const createAnimalsData = (animals) =>
  animals.reduce((acc, current, index) => {
    if (current?.id) {
      return [
        ...acc,
        {
          id: index,
          name: current.name || '',
          count: current.count,
        },
      ];
    }

    return acc;
  }, []);

export const getDataToSave = ({
  form,
  animals = [],
  values,
  step,
  submittedData,
  cityzenData: { citizenInfo, registrationAddress } = {},
}) => {
  /* eslint-disable no-console */
  console.log('values.familyData',values);
  let dataToSubmit;
  if (step === 1) {
    const animalsList = createAnimalsData(animals);
    dataToSubmit = {
      ...submittedData,
      citizenData: {
        citizenInfo: {
          firstName: citizenInfo.firstName,
          lastName: citizenInfo.lastName,
          patronymicName: citizenInfo.patronymicName,
          ssn: citizenInfo.ssn,
          birthdate: citizenInfo.birthdate,
          documentType: values.citizenData.citizenInfo?.documentType || citizenInfo.documents?.[0]?.documentType,
          documentNumber: values.citizenData.citizenInfo?.documentNumber || citizenInfo.documents?.[0]?.documentNumber,
          gender: citizenInfo.gender,
          phoneNumber: values.citizenData.citizenInfo.phoneNumber,
          email: values.citizenData.citizenInfo.email,
        },
        animals: animalsList.length ? animalsList : null,
        isActualAddressMatch: values.citizenData.isActualAddressMatch,
        actualResidenceType: values.citizenData.actualResidenceType ?? null,
        registrationAddress: !registrationAddress
          ? {
              regionId: values.citizenData.registrationAddress.regionId.value,
              region: values.citizenData.registrationAddress.regionId.label,
              communityId: values.citizenData.registrationAddress.communityId.value,
              community: values.citizenData.registrationAddress.communityId.label,
              settlementId: values.citizenData.registrationAddress.settlementId.value,
              settlement: values.citizenData.registrationAddress.settlementId.label,
              street: values.citizenData.registrationAddress.street,
              building: values.citizenData.registrationAddress.building,
              buildingType: values.citizenData.registrationAddress.buildingType?.label,
              buildingTypeId: values.citizenData.registrationAddress.buildingType?.value,
              apartment: values.citizenData.registrationAddress.apartment,
              postIndex: values.citizenData.registrationAddress.postIndex,
            }
          : registrationAddress,
        actualAddress: values.citizenData.actualAddress
          ? {
              regionId: values.citizenData.actualAddress.regionId.value,
              region: values.citizenData.actualAddress.regionId.label,
              communityId: values.citizenData.actualAddress.communityId.value,
              community: values.citizenData.actualAddress.communityId.label,
              settlementId: values.citizenData.actualAddress.settlementId.value,
              settlement: values.citizenData.actualAddress.settlementId.label,
              street: values.citizenData.actualAddress.street,
              building: values.citizenData.actualAddress.building,
              buildingType: values.citizenData.actualAddress.buildingType?.label,
              buildingTypeId: values.citizenData.actualAddress.buildingType?.value,
              apartment: values.citizenData.actualAddress.apartment,
              postIndex: values.citizenData.actualAddress.postIndex,
            }
          : null,
        isStudying: values.citizenData.isStudying,
        educationalInstitution: values.citizenData?.educationalInstitution,
        isMilitaryService: values.citizenData?.isMilitaryService,
        hasAnimals: values.citizenData.hasAnimals,
        attachedDocumentTypeHouse: null,
        isActualAddressDocumentAttached: false,
        isAnimalDocumentAttached: null,
        attachedDocumentTypeAnimal: null,
        residenceFilesChecksum: values.citizenData?.[files]?.residenceFilesChecksum?.map(
          (item) => item.response.fileChecksum
        ),
        studyFilesChecksum: values.citizenData?.[files]?.studyFilesChecksum?.map((item) => item.response.fileChecksum),
        militaryServiceFilesChecksum: values.citizenData?.[files]?.militaryServiceFilesChecksum?.map(
          (item) => item.response.fileChecksum
        ),
        animalFilesChecksum: values.citizenData?.[files]?.animalFilesChecksum?.map(
          (item) => item.response.fileChecksum
        ),
        ...values.citizenData?.[utilityAccounts],
      },
      step: step + 1,
    };
  } else if (step === 2) {
    dataToSubmit = {
      ...submittedData,
      familyData: {
        ...values.familyData,
        marriageFilesChecksum: values?.familyData?.marriageFilesChecksum?.map(
          (item) => item?.response?.fileChecksum),
        familyMembers: values.familyData?.familyMembers?.map(familyPerson => ({
          id: familyPerson?.id,
          email: familyPerson?.email,
          isStudying: familyPerson?.isStudying,
          studyFilesChecksum: familyPerson?.studyFilesChecksum?.map(
            (item) => item?.response?.fileChecksum),
          citizenInfo: {
            ssn: familyPerson?.ssn,
            firstName: familyPerson?.fname,
            lastName: familyPerson?.lname,
            patronymicName: familyPerson?.patronymicName,
            birthdate: familyPerson?.birthdate,
          },
          relativeType: familyPerson?.relativeType,
          isMilitaryService: familyPerson?.isMilitaryService,
          militaryServiceFilesChecksum: familyPerson?.militaryServiceFilesChecksum?.map(
            (item) => item?.response?.fileChecksum),
        })) || []
      },
      step: step + 1,
    };
  } else if (step === 3) {
    dataToSubmit = {
      ...submittedData,
      applicationProperties: {
        agreeDataCorrect: values.agreeDataCorrect[0],
        isNotifyBySMS: values.isNotifyBySMS?.[0],
        bankId: values?.bankId,
        paymentMethodId: values.paymentMethodId,
      },
      step: step + 1,
    };
  } else {
    dataToSubmit = {
      ...submittedData,
      ...values,
      profileData: values.profileData?.legalRepresentative
        ? {
            ...values.profileData,
            legalRepresentative: values.profileData?.legalRepresentative
              ? {
                  ...values.profileData.legalRepresentative,
                  firstName: values.profileData.legalRepresentative.fname,
                  lastName: values.profileData.legalRepresentative.lname,
                  birthdate: dateConvert(values.profileData.legalRepresentative.birthdate, DATE_FORMAT_YYYY_MM_DD),
                }
              : null,
            legalFilesChecksum: values.profileData?.legalFilesChecksum?.map((item) => item.response.fileChecksum),
          }
        : null,
      step: step + 1,
      profileId: form.getFieldValue('profileId'),
      tempApplicationId: form.getFieldValue('tempApplicationId'),
    };
  }

  return dataToSubmit;
};

/** @todo check CREATE citizen info part */
export const setFormCitizenData = (form, data, documents) => {
  if (!data) {
    return;
  }

  form.setFieldsValue({
    animals: data.animals?.reduce((acc, current) => {
      acc[current.id] = current;
      return acc;
    }, []),
    citizenData: {
      ...data,
      [files]: {
        animalFilesChecksum: data.animalFilesChecksum?.map((item, index) => ({
          uid: item,
          name: `Տեղեկանք ${index + 1}`,
          status: 'done',
          response: {
            fileChecksum: item,
          },
        })),
        militaryServiceFilesChecksum: data.militaryServiceFilesChecksum?.map((item, index) => ({
          uid: item,
          name: `Տեղեկանք ${index + 1}`,
          status: 'done',
          response: {
            fileChecksum: item,
          },
        })),
        studyFilesChecksum: data.studyFilesChecksum?.map((item, index) => ({
          uid: item,
          name: `Տեղեկանք ${index + 1}`,
          status: 'done',
          response: {
            fileChecksum: item,
          },
        })),
        residenceFilesChecksum: data.residenceFilesChecksum?.map((item, index) => ({
          uid: item,
          name: `Տեղեկանք ${index + 1}`,
          status: 'done',
          response: {
            fileChecksum: item,
          },
        })),
      },
      [utilityAccounts]: {
        gasAccount: data.gasAccount,
        electricityAccount: data.electricityAccount,
        waterAccount: data.waterAccount,
      },
      citizenInfo: {
        ...data.citizenInfo,
        fname: data.citizenInfo.firstName,
        lname: data.citizenInfo.lastName,
        birthdate: dayjs(data.citizenInfo.birthdate, 'YYYY-MM-DD'),
        documentType: data.citizenInfo.documentType || documents?.[0]?.documentType,
        documentNumber: data.citizenInfo.documentNumber || documents?.[0]?.documentNumber,
      },
      registrationAddress: data.registrationAddress
        ? {
            ...data.registrationAddress,
            regionId: {
              label: data.registrationAddress.region,
              value: data.registrationAddress.regionId,
            },
            communityId: {
              label: data.registrationAddress.community,
              value: data.registrationAddress.communityId,
            },
            settlementId: {
              value: data.registrationAddress.settlementId,
              label: data.registrationAddress.settlement,
            },
          }
        : null,
      actualAddress: data.actualAddress
        ? {
            ...data.actualAddress,
            regionId: {
              value: data.actualAddress.regionId,
              label: data.actualAddress.region,
            },
            communityId: {
              value: data.actualAddress.communityId,
              label: data.actualAddress.community,
            },
            settlementId: {
              value: data.actualAddress.settlementId,
              label: data.actualAddress.settlement,
            },
            buildingType: {
              value: data.actualAddress.buildingTypeId,
              label: data.actualAddress.buildingType,
            },
          }
        : null,
    },
  });
};

export const getDocumentTypeProps = (form, documents, name) => ({
  options:
    documents.map((item) => ({
      value: item.documentType,
      label: item.documentType,
    })) || [],
  onChange: (value) => {
    const documentNumber = documents.find((item) => item.documentType === value).documentNumber;
    form.setFieldValue(name, documentNumber);
  },
});
