import { Col, Form, Row } from 'antd';
import { Input } from '../../components/input';
import { Datepicker } from '../../components/datepicker';
import { phonePattern } from '../../helpers/constants';

export const ApplicantPersonalData = () => {
  return (
    <>
      <Row gutter={16} justify={'center'}>
        <Form.Item name="profileId" hidden></Form.Item>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Անուն" name="firstName">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Ազգանուն" name="lastName">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Հայրանուն" name="patronymicName">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify={'center'}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանք" name="ssn">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Սեռ" name="sex">
            <Input size="middle" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item label="Ծննդյան ամսաթիվ " name="birthdate">
            <Datepicker style={{ width: '100%' }} size="middle" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify={'center'}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item
            label={'Հեռախոսի համար'}
            name="phoneNumber"
            rules={[
              { required: true, pattern: phonePattern, message: 'Ֆորմատը սխալ է {+37400000000}' },
            ]}
          >
            <Input placeholder={'+37400000000'} size="middle" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item label={"Էլեկտրոնային փոստի հասցե"} rules={[{ required: true }]} name="email">
            <Input size="middle" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
