import { MainSider } from '../menu/main-sider';
import { ServicesNavbar } from '../menu/services-navbar';
import { COLORS } from '../../helpers/constants';

export const ServicesSider = () => {
  return (
    <MainSider
      style={{ backgroundColor: COLORS.SECONDARY.OIL }}
      width={330}
      breakpoint="lg"
      collapsedWidth="0"
      collapsible
    >
      <ServicesNavbar />
    </MainSider>
  );
};
