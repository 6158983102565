import { useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Col, Divider, Form, Row, Select, Input } from 'antd';
import { FormItem } from 'components/form/form-item';
import { InputNumber } from 'components/input-number';
import { VerticalSpace } from 'components/space/vertical-space';
import {
  FAMILY_STATUS,
  LIVING_ITEMS,
  familyComposition,
  TRUE_FALSE_OPTIONS,
  patternMessage,
  requiredMessage,
} from '../constants';
import { FIELD_NAMES_DEFAULT, FIELD_NAMES_BY_LABEL1, FIELD_NAMES_BY_LABEL2 } from 'components/select/constants';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';
import { Title } from 'components/typography';

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding-top: 29px;
  label {
    margin-bottom: 20px;
    margin-inline-start: 0 !important;
  }
`;
export const DisabilityFamilyData = () => {
  const [haveChildren, setHaveChildren] = useState(false);
  const [livingItem, setLivingItem] = useState(false);
  const personData = Form.useWatch(['applicationForm'], { preserve: true });
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const selectedFamilyMembers = Form.useWatch(['selfAssessment', 'family', 'familyMembers'], { preserve: true });
  const form = Form.useFormInstance();

  // eslint-disable-next-line no-console
  // const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);

  // const handleFamilyMembersChange = (selectedValues) => {
  //   const mappedValues = selectedValues.map(value => {
  //     const { label, value: id } = familyComposition().find(item => item.value === value) || {};
  //     return { typeId: id, typeName: label };
  //   });
  //   setSelectedFamilyMembers(mappedValues);
  // };

  return (
    <VerticalSpace>
      <VerticalSpace size="small">
        <Row justify="space-between">
          <Col xxl={6} xs={8}>
            <FormItem
              name={['selfAssessment', 'maritalTypeOver18', 'typeId']}
              label={
                <Title level={3} color="#0B847F">
                  Ընտանեկան կարգավիճակը
                </Title>
              }
              rules={[{ required: true }]}
            >
              <Select
                options={FAMILY_STATUS.filter((item) => {
                  if (personData?.age >= 16) return item.IsChild === false;
                  return true;
                }).map((item) => ({
                  label: applicantType === APPLICATION_FOR_HIMSELF ? item.label1 : item.label2,
                  value: item.value,
                }))}
                labelInValue
              ></Select>
            </FormItem>
          </Col>
        </Row>
      </VerticalSpace>
      <Divider />
      <VerticalSpace size="small">
        <Row gutter={16}>
          <Col span={6}>
            <FormItem
              name={['selfAssessment', 'maritalTypeUnder18', 'typeId']}
              label={
                <Title level={3} color="#0B847F">
                  {applicantType === APPLICATION_FOR_HIMSELF ? 'Ապրում եք' : 'Ապրում Է'}
                </Title>
              }
              rules={[{ required: true }]}
            >
              <Select
                options={LIVING_ITEMS}
                labelInValue
                fieldNames={applicantType === APPLICATION_FOR_HIMSELF ? FIELD_NAMES_BY_LABEL1 : FIELD_NAMES_BY_LABEL2}
                onChange={(option) => {
                  setLivingItem(option.value === 9);
                  form.setFieldsValue({
                    selfAssessment: {
                      maritalTypeUnder18: {
                        commentForOther: '',
                      },
                    },
                  });
                }}
              ></Select>
            </FormItem>
          </Col>
          {livingItem && (
            <Col span={6}>
              <FormItem
                name={['selfAssessment', 'maritalTypeUnder18', 'commentForOther']}
                rules={[
                  { required: true, message: requiredMessage },
                  { pattern: /^[ա-ֆԱ-Ֆ0-9։,.-/՝ō ]*$/, message: patternMessage },
                ]}
                label={
                  <Title level={3} color="#0B847F">
                    Նշել
                  </Title>
                }
              >
                <Input placeholder="Լրացնել" size="large" style={{ borderRadius: 10, height: '46px' }} />
              </FormItem>
            </Col>
          )}
        </Row>
      </VerticalSpace>
      <Divider />
      <VerticalSpace size="small">
        <Row gutter={16}>
          <Col>
            <FormItem
              name={['selfAssessment', 'family', 'familyMembers']}
              label={
                <Title level={3} color="#0B847F">
                  Ընտանիքի կազմը
                </Title>
              }
              rules={[{ required: true }]}
            >
              <StyledCheckboxGroup options={familyComposition(applicantType)} />
            </FormItem>

          </Col>
          <Col span={6}>
            {selectedFamilyMembers && selectedFamilyMembers.includes(10) && (
              <FormItem
                name={['selfAssessment', 'family', 'commentForOther']}
                label={
                  <Title level={5} color="#0B847F">
                    Նշել
                  </Title>
                }
                rules={[{ required: true }]}
              >
                <Input placeholder="Լրացնել" size="large" style={{ borderRadius: 10, height: '46px' }} />
              </FormItem>
            )}
          </Col>
        </Row>
      </VerticalSpace>
      <Divider />
      <VerticalSpace size="small">
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name={['selfAssessment', 'haveChildren', 'have']}
              label={
                <Title level={3} color="#0B847F">
                  {`${applicantType === APPLICATION_FOR_HIMSELF ? 'Ունե՞ք' : 'Ունի՞'} անչափահաս երեխա(ներ)`}
                </Title>
              }
              rules={[{ required: true }]}
            >
              <Select
                labelInValue
                fieldNames={FIELD_NAMES_DEFAULT}
                options={TRUE_FALSE_OPTIONS}
                onChange={(option) => setHaveChildren(option.value === 1)}
              />
            </Form.Item>
          </Col>
          {haveChildren && (
            <Col span={6}>
              <Form.Item
                label={
                  <Title level={3} color="#0B847F">
                    Անչափահաս երեխաների թիվը
                  </Title>
                }
                name={['selfAssessment', 'haveChildren', 'childrenCount']}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="Լրացնել"
                  size="large"
                  min={1}
                  max={15}
                  style={{ borderRadius: 10, height: '46px', width: '246px' }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </VerticalSpace>
      <Divider />
    </VerticalSpace>
  );
};
