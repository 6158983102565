export const URL_GET_USER_COMPANIES = '/api/Urrp/GetCitizenCompaniesSwitcher';
export const URL_GET_COMPANY_BY_ID = '/api/Urrp/GetCompanyById';
export const URL_GET_ALL_COMPANIES = '/api/Urrp/GetCompanies';
export const URL_GET_CLOTHE_TYPES = '/api/Dictionary/GetClothes';
export const URL_GET_SHOE_TYPES = '/api/Dictionary/GetShoes';
export const URL_GET_SUPPORT_INSTRUMENT_TYPES = '/api/Dictionary/GetSupportInstruments';
export const URL_GET_SWADDLER_TYPES = '/api/Dictionary/GetSwaddlers';
export const URL_SAVE_CITIZEN_APPLICATION = '/api/Urrp/CitizenSubmitApplication';
export const URL_EDIT_CITIZEN_APPLICATION = '/api/Account/EditUrrpApplication';
export const URL_GET_VULNERABILITY = '/api/Dictionary/GetVulnerability';
export const URL_GET_SHOE_SIZE = '/api/Dictionary/GetShoeSize';
export const URL_GET_AGE_GROUP = '/api/Dictionary/GetAgeGroup';
export const URL_GET_CATEGORIES = '/api/Dictionary/GetCategories';
export const URL_GET_CURRENCIES = '/api/Dictionary/GetCurrency';
export const URL_GET_SUPPORT_CATEGORIES = '/api/Dictionary/GetSupportsForCategories';
export const URL_GET_COMMUNITY_LIST_BY_REGIONS = '/api/Dictionary/GetCitiesByRegions';
export const URL_CHECK_AGREEMENTS = '/api/Urrp/CheckAgreements';
export const URL_CREATE_AGREEMENT = '/api/Agreement/CreateAgreement';
export const URL_SAVE_DONOR_APPLICATION = '/api/Urrp/DonorSubmitApplication';
export const URL_GET_USER_DATA_FROM_BPR = '/api/Account/GetCitizenBprInfo';
export const URL_VALIDATE_FAMILY_MEMBER = 'api/Auth/ValidateSSN';
export const URL_GET_CITIZEN_BUILDING_TYPES = '/api/Dictionary/GetAccomodetions';
export const URL_GET_ELDER_SWADDLER_OPTIONS = '/api/Dictionary/GetCategoriesById?baseId=8';
export const URL_GET_CHILD_SWADDLER_OPTIONS = '/api/Dictionary/GetCategoriesById?baseId=9';
export const URL_GET_SHEET_OPTIONS = '/api/Dictionary/GetCategoriesById?baseId=10';
export const URL_GET_WOMEN_SWADDLER_OPTIONS = '/api/Dictionary/GetCategoriesById?baseId=11';
export const URL_GET_URRP_APPLICATION_BY_ID = '/api/Account/ViewUrrpApplication';
export const URL_GET_DONOR_APPLICATION_BY_ID = '/api/Account/ViewUrrpDonorApplication';
export const URL_EDIT_DONOR_APPLICATION = '/api/Account/EditUrrpDonorApplication';
export const URL_GET_DONOR_CATEGORIES = '/api/Dictionary/GetAvailableCategories';
export const URL_GET_ALL_DONOR_CATEGORIES = '/api/Dictionary/GetCategoriesById?baseId=1';
export const URL_GET_DONOR_AGREEMENT = '/api/Dictionary/GetAgreementDataForApplication';
export const URL_GET_EDUCATIONS = '/api/Dictionary/GetUrrpEducationTypes';
export const URL_CHECK_PERSON_REPRESENTATIVE = '/api/Urrp/CheckPersonRepresentative';
export const URL_GET_COMPANY_DATA = '/api/Urrp/GetCompanyAndDirectorInfo';
export const URL_CREATE_REPRESENTATIVE = '/api/Representative/CreateRepresentative';
export const URL_GET_COMPANY_REPRESENTATIVES = '/api/Representative/GetCompanyRepresentatives';
export const URL_GET_PERSON_REPRESENTATIVES = '/api/Representative/GetPersonRepresentatives';
export const URL_GET_REPRESENTATIVE_BY_ID = '/api/Representative/GetRepresentativeById';
export const URL_EDIT_REPRESENTATIVE = '/api/Representative/EditRepresentative';
export const URL_GET_NEEDS_TYPES = '/api/Dictionary/GetNeedsTypes';
export const URL_GET_NEEDS_LOCATIONS = '/api/Urrp/GetNeedsLocations';
export const URL_GET_NEEDS_BY_COMMUNITY = '/api/Urrp/GetNeedsByCommunity';
export const URL_GET_NEED_STATUSES = '/api/Urrp/GetApplicationNeed';
