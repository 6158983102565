import { Form, Spin } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_SUPPORT_INSTRUMENT_TYPES } from 'api/urrp/constants';
import { Checkbox } from 'components/checkbox';
import { VerticalSpace } from 'components/space/vertical-space';
import { ErrorText } from 'components/typography/error-text';
import { useState } from 'react';
import { NeedMatchCard } from '../need-match-card';
import { useMatches } from 'react-router-dom';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';
import { arePathsEqual } from 'pages/rapid-need/utils';
import { PATHS } from 'helpers/constants';

export const SupportInstrumentList = ({ parentFormItem, itemData }) => {
  const { data, isLoading } = useGetFfromBenefit(URL_GET_SUPPORT_INSTRUMENT_TYPES);
  const [errorMessage, setErrorMessage] = useState(false);
  const checklist = useWatch([...parentFormItem, 'selections'], {
    preserve: true,
  });
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;

  const validateAtLeastOneSelected = (_, val) => {
    if (!val?.length) {
      setErrorMessage('Խնդրում ենք ընտրել բացված արժեքներից առնվազն մեկը');
      return Promise.reject();
    }
    setErrorMessage('');
    return Promise.resolve();
  };

  return (
    <Spin spinning={isLoading}>
      <Form.Item
        name={[...parentFormItem, 'selections']}
        style={{ marginLeft: '20px' }}
        rules={[{ validator: validateAtLeastOneSelected }]}
      >
        <Checkbox.Group>
          <VerticalSpace size="small">
            {data.map((option) => {
              const currentItemData = itemData?.filter((item) => item.supportInstrumentId === option.id);
              return (
                <>
                  <Checkbox key={option.id} value={option.id}>
                    {option.name}
                  </Checkbox>
                  {checklist?.includes(option.id) &&
                    type === APPLICATION_MODE.VIEW &&
                    arePathsEqual(match.pathname, PATHS.RAPID_NEED_VIEW) && (
                      <NeedMatchCard itemData={currentItemData?.[0]} />
                    )}
                </>
              );
            })}
          </VerticalSpace>
        </Checkbox.Group>
      </Form.Item>
      {errorMessage && <ErrorText text={errorMessage} />}
    </Spin>
  );
};
