import { LockOutlined } from '@ant-design/icons';
import { Password } from '../../input/password';
import { FormItem } from '../form-item';
import { SecondaryText } from '../../typography';
import {COLORS} from '../../../helpers/constants';
import { validatePassword } from '../../../helpers/utils';

export const PasswordForm = () => {
    return (
        <FormItem>
            <FormItem
                name="newPassword"
                label="Նոր գաղտնաբառ"
                rules={[
                    { required: true, min: 8, message: 'Պարտադիր դաշտ'},
                    {
                        validator(_, value) {
                            if (validatePassword(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    'Գաղտնաբառը պետք է պարունակի մեծատառ եւ փոքրատառ լատիներեն տառեր, թվային եւ սիմվոլային արժեքներ'
                                )
                            );
                        },
                    },
                ]}
            >
                <Password placeholder="գաղտնաբառ" prefix={<LockOutlined />} />
            </FormItem>
            <FormItem
                name="newPasswordRepeat"
                label="Նոր գաղտնաբառ"
                rules={[
                    { required: true, min: 8, message: 'Պարտադիր դաշտ' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Լրացված արժեքները միմյանց չեն համապատասխանում'));
                        },
                    }),
                ]}
                dependencies={['newPassword']}
                extra={
                    <SecondaryText color={COLORS.PRIMARY.BLUE_DARK}>
                        Գաղտնաբառը պետք է պարունակի մեծատառ եւ փոքրատառ լատիներեն տառեր, թվային եւ սիմվոլային արժեքներ
                    </SecondaryText>
                }
            >
                <Password placeholder="գաղտնաբառ" prefix={<LockOutlined />} />
            </FormItem>
        </FormItem>
    );
};
