import { MainSider } from '../menu/main-sider';
import { UserNavbar } from '../menu/user-navbar';
import { SidebarProfile } from '../profile/sidebar-profile';

export const DefaultSider = () => {
  return (
    <MainSider
      style={{ backgroundColor: 'rgba(219, 233, 253, 1)'}}
      width={330}
      breakpoint="lg"
      collapsedWidth="0"
      collapsible
    >
      <SidebarProfile />
      <UserNavbar />
    </MainSider>
  );
};
