import { FormSectionCard } from '../../../card/form-section-card';
import { VerticalSpace } from '../../../space/vertical-space';
import { Select } from '../../../select';
import { Col, Row } from 'antd';
import { Input } from '../../../input';
import { FormItem } from '../../form-item';

export const PersonalInfo = ({ formItemDoc = {}, viewMode = false, selectProps }) => {
  return (
    <FormSectionCard title="Անձը հաստատող փաստաթղթի տվյալներ">
      <VerticalSpace>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="ԱՀՓ տեսակ" rules={[{ required: true }]} {...formItemDoc.documentType}>
              {viewMode ? <Input /> : <Select {...selectProps} />}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="ԱՀՓ համար" rules={[{ required: true }]} {...formItemDoc.documentNumber}>
              <Input readOnly={!!formItemDoc.documentNumber?.readOnly} />
            </FormItem>
          </Col>
        </Row>
      </VerticalSpace>
    </FormSectionCard>
  );
};
