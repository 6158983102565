import { Col, Form, Row } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';
import { BuildingTypesSelect } from 'components/select/building-types-select';
import { CommunitySelect } from 'components/select/community-select';
import { PostIndexSelect } from 'components/select/post-index-select';
import { RegionsSelect } from 'components/select/regions-select';
import { SettlementSelect } from 'components/select/settlement-select';
import { VerticalSpace } from 'components/space/vertical-space';
import { useEffect } from 'react';

export const DIPersonAddress = ({
  formItemAddress = {},
  viewMode = false,
  isActualAddress = false,
  title = 'Հաշվառման հասցե',
  doAddressesMatch = false,
  data,
  isView = false,
  notRequired = false,
}) => {
  const form = Form.useFormInstance();
  const regionId = Form.useWatch(formItemAddress.regionId.name, {
    preserve: true,
  });
  const communityId = Form.useWatch(formItemAddress.communityId.name, {
    preserve: true,
  });

  const onRegionChange = () => {
    form.resetFields([
      formItemAddress.communityId.name,
      formItemAddress.settlementId.name,
      ...(formItemAddress.postIndex ? [formItemAddress.postIndex.name] : []),
    ]);
  };

  const onCommunityChange = () => {
    form.resetFields([formItemAddress.settlementId.name]);
  };

  useEffect(() => {
    if (!doAddressesMatch && viewMode && !isView) {
      form.setFields([
        {
          name: formItemAddress.regionId.name,
          value: data?.regionId,
        },
        {
          name: formItemAddress.region.name,
          value: data?.region,
        },
        {
          name: formItemAddress.communityId.name,
          value: data?.communityId,
        },
        {
          name: formItemAddress.community.name,
          value: data?.community,
        },
        {
          name: formItemAddress.settlementId.name,
          value: data?.settlementId,
        },
        {
          name: formItemAddress.settlement.name,
          value: data?.settlement,
        },
        {
          name: formItemAddress.street.name,
          value: data?.street,
        },
        {
          name: formItemAddress.buildingType.name,
          value: data?.buildingType,
        },
        {
          name: formItemAddress.buildingTypeId.name,
          value: data?.buildingTypeId,
        },
        {
          name: formItemAddress.building.name,
          value: data?.building,
        },
        {
          name: formItemAddress.apartment.name,
          value: data?.apartment,
        },
      ]);
    }
  }, [data, doAddressesMatch, form, formItemAddress, isActualAddress, isView, viewMode]);

  return (
    <FormSectionCard title={title}>
      <VerticalSpace>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Մարզ"
              name={viewMode ? formItemAddress.region.name : formItemAddress.regionId.name}
              rules={[{ required: !notRequired }]}
            >
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <RegionsSelect labelInValue onChange={onRegionChange} disabled={viewMode} />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Համայնք"
              name={viewMode ? formItemAddress.community.name : formItemAddress.communityId.name}
              rules={[{ required: !notRequired }]}
            >
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <CommunitySelect
                  labelInValue
                  onChange={onCommunityChange}
                  regionId={regionId?.value}
                  disabled={viewMode}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Բնակավայր"
              rules={[{ required: !notRequired }]}
              name={viewMode ? formItemAddress.settlement.name : formItemAddress.settlementId.name}
            >
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <SettlementSelect labelInValue communityId={communityId?.value} disabled={viewMode} />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Փողոց" {...formItemAddress.street} rules={[{ required: !notRequired }]}>
              <Input disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Կառույցի տեսակ"
              rules={[{ required: !notRequired }]}
              name={viewMode ? formItemAddress.buildingType.name : formItemAddress.buildingTypeId.name}
            >
              {viewMode ? <Input disabled={viewMode} /> : <BuildingTypesSelect labelInValue disabled={viewMode} />}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Կառույցի համար" {...formItemAddress.building} rules={[{ required: !notRequired }]}>
              <Input disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Բնակարան" {...formItemAddress.apartment}>
              <Input disabled={viewMode} />
            </Form.Item>
          </Col>
          {isActualAddress && (
            <>
              {formItemAddress.postIndex && (
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Փոստային դասիչ" {...formItemAddress.postIndex}>
                    <PostIndexSelect regionId={doAddressesMatch ? regionId : regionId?.value} />
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Row>
      </VerticalSpace>
    </FormSectionCard>
  );
};
