import { SectionWrapper } from 'components/collapse/section-wrapper';
import { AddFamilyMember } from 'components/form/application/family/add-family-member';
import { HasFamilyMember } from 'components/form/application/family/has-family-member';
import { MariageFormView } from 'components/form/application/mariage-cert/view';
import { Form } from 'components/form/form';
import { VerticalSpace } from 'components/space/vertical-space';

export const FamilyDataView = () => {
  const familyData = Form.useWatch('familyData', { preserve: true });
  return familyData ? (
    <SectionWrapper title="Ընտանիքի անդամների տվյալներ">
      <VerticalSpace>
        <HasFamilyMember />
        <AddFamilyMember viewMode />
      </VerticalSpace>
      <MariageFormView parent={['familyData']} />
    </SectionWrapper>
  ) : (
    <></>
  );
};
