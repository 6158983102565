import { Button, Space, Spin, Table } from 'antd';
import { DEFAULT_PAGE, PATHS } from 'helpers/constants';
import { useGetData } from 'api/dictionary/use-get-data';
import { useCallback, useState } from 'react';
import { useColumns } from './use-columns';
import { URL_GET_AGREEMENT_APPLICATIONS } from 'api/application/constants';
import { APPLICATION_TYPES } from 'pages/applications/constants';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/auth-context';

export const AgreementApplications = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [result, setResult] = useState({ data: [], totalRecords: 0 });
  const {
    user: { isLegal, companyId, profileId },
  } = useAuth();

  const columns = useColumns(APPLICATION_TYPES.AGREEMENT_APPLICATIONS, page.pageNumber, page.pageSize);
  const { isLoading } = useGetData(
    URL_GET_AGREEMENT_APPLICATIONS,
    {
      pageNumber: page.pageNumber,
      pageSize: page.pageSize,
      ...(isLegal ? { companyId: companyId || profileId } : {}),
    },
    {
      onSuccess: (data) => {
        setResult({
          data: data.data,
          totalRecords: data.data?.totalRecords,
        });
      },
      enabled: true,
    }
  );

  const onHandleChange = useCallback((pagination) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
    }));
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'end' }}>
        <Button
          onClick={() => navigate(PATHS.CREATE_DONOR_AGREEMENT)}
          style={{ paddingLeft: '21px' }}
          type="primary"
          block
          size="large"
        >
          Դիմել համաձայնագրի համար
        </Button>
      </Space>
      <Space size="middle" direction="vertical" style={{ width: '100%' }}>
        <Table
          columns={columns}
          dataSource={result?.data?.data}
          rowKey="applicationNumber"
          style={{ overflow: 'auto' }}
          onChange={onHandleChange}
          pagination={{
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: result?.totalRecords,
            showTotal: (total, range) => `${range[0]}-${range[1]} ${result.totalRecords}-ից`,
          }}
        />
      </Space>
    </Spin>
  );
};
