import { Col, Row } from 'antd';
import { Button } from '../../components/button';
import { COLORS } from '../../helpers/constants';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/auth-context';

const spanStyle = (showArrow) => ({
  display: 'flex',
  width: 20,
  height: 20,
  margin: '-10px auto',
  ...(showArrow ? { transform: 'rotate(45deg)', background: COLORS.SECONDARY.ALICE_BLUE } : {}),
});

export const ServicesDepartment = ({ setIsLegal }) => {
  const [show, setShow] = useState(true);
  const { user } = useAuth();

  const style = {
    background: user ? COLORS.SECONDARY.AQUA_BLUE : COLORS.SECONDARY.OIL,
    height: 61,
    fontSize: 14,
    position: 'relative',
  };

  useEffect(() => {
    setIsLegal(user?.isLegal || false);
  }, [setIsLegal, user?.isLegal]);

  return (
    <Row>
      {!!(!user || !user?.isLegal) && (
        <Col span={user ? 24 : 12}>
          <Button
            block
            size="large"
            onClick={() => {
              setShow(true);
              setIsLegal(false);
            }}
            style={style}
            type="primary"
          >
            Ֆիզիկական անձ
          </Button>
          {show && <span style={spanStyle(!user)} />}
        </Col>
      )}
      {!!(!user || user?.isLegal) && (
        <Col span={user ? 24 : 12}>
          <Button
            block
            size="large"
            style={style}
            onClick={() => {
              setShow(false);
              setIsLegal(true);
            }}
            type="primary"
          >
            Իրավաբանական անձ
          </Button>
          {!show && <span style={spanStyle(!user)} />}
        </Col>
      )}
    </Row>
  );
};
