import { Input as InputComponent } from 'antd';
import styled from 'styled-components';

const { TextArea: TextAreaComponent } = InputComponent;

const TextAreaStyled = styled(TextAreaComponent)`
  background: linear-gradient(91.78deg, rgba(255, 255, 255, 0.64) 6.81%, rgba(255, 255, 255, 0.16) 100%);
  border: 1px solid #C0C1C2;
  border-radius: 1px;

  &.ant-input-disabled {
    color: #091024;
    background: rgba(240, 240, 240, 0.5);
  }
`;

export const TextArea = (props) => {
  return <TextAreaStyled style={{resize: props.resize}}  {...props} />;
};
