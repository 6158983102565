import { Col, Form, Input, Row } from 'antd';
import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { RejectionReasons } from './rejection-reason-list';
import { VerticalSpace } from 'components/space/vertical-space';

export const NeedMatchCard = ({ itemData }) => {
  const companyList = itemData?.satisfiedCompany?.split(';');

  return (
    <FormSectionFullCard style={{ marginBottom: '20px' }}>
      <Row gutter={[12, 12]}>
        <Col span={18}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item label="Կարգավիճակ">
                <Input disabled value={itemData?.status?.label} />
              </Form.Item>
            </Col>
            {itemData?.reasons?.length ? (
              <Col span={12}>
                <RejectionReasons reasons={itemData.reasons} />
              </Col>
            ) : (
              <></>
            )}
            {itemData?.satisfiedCompany ? (
              <Col span={12}>
                <Form.Item label="Ինչ միջոցներով">
                  <VerticalSpace>
                    {companyList.map((name) => {
                      return <Input disabled value={name} key={name} />;
                    })}
                  </VerticalSpace>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col span={12}>
              <Form.Item label="Կարգավիճակի ամսաթիվ">
                <Input disabled value={itemData?.statusDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Կարիքը գնահատող">
                <Input disabled value={itemData?.socWorkerName} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormSectionFullCard>
  );
};
