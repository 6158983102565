import { useQuery } from '@tanstack/react-query';
import { client } from 'api/client';

const fetchCitiesByRegions = async (regionIds) => {
  const params = new URLSearchParams();
  regionIds.forEach((id) => params.append('regionIds', id));

  const response = await client.get('/api/Dictionary/GetCitiesByRegions', {
    params,
  });

  return response;
};
export const useGetCommunitiesList = (url, params, options = { enabled: true }) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => fetchCitiesByRegions(params.regionIds),
    retry: false,
    ...options,
  });
  const { data, isSuccess } = result;
  return {
    ...result,
    data: isSuccess ? data.data : [],
  };
};
