import { Col, Row } from 'antd';
import { Input } from '../input';
import { FormItem } from './form-item';
import { PhoneNumberFormItem } from 'components/form-item/phone-number-form-item';

export const PersonContactForm = ({ formItemContactProp = {} }) => {
  // const spanCount = Math.ceil(24 / Object.keys(formItemContactProp).length);

  return (
    <>
      <Row gutter={10} align={'middle'}>
        {formItemContactProp.phoneNumber && (
          <Col xs={24} sm={24} md={8}>
            <PhoneNumberFormItem
              inputProps={{ disabled: !!formItemContactProp.phoneNumber?.readOnly }}
              {...formItemContactProp.phoneNumber}
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={9}>
          <FormItem label="Էլեկտրոնային փոստի հասցե" {...formItemContactProp.email}>
            <Input placeholder="example@gmail.com" disabled={!!formItemContactProp.email?.readOnly} />
          </FormItem>
        </Col>
        {formItemContactProp.gender && (
          <Col xs={24} sm={24} md={8}>
            <FormItem label="Սեռ" {...formItemContactProp.gender}>
              <Input disabled={!!formItemContactProp.gender?.readOnly} />
            </FormItem>
          </Col>
        )}
      </Row>
    </>
  );
};
