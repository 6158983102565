import { Col, Form, Row, Spin } from 'antd';

import { armenianLettersPattern, DATE_FORMAT_YYYY_MM_DD } from 'helpers/constants';
import { VerticalSpace } from 'components/space/vertical-space';
import { URL_GET_GET_QKAG_DEATH_INFO } from 'api/benefit/constants';
import { Title } from 'components/typography';
import { FormItem } from 'components/form/form-item';
import { ArmenianInput } from 'components/input/armenian-input';
import { Input } from 'components/input';
import { Datepicker } from 'components/datepicker';
import { useGetValidateUser } from 'api/benefit/use-get-validate-user';
import { dateConvert, errorMessage } from 'helpers/utils';
import { useState } from 'react';
import { DisabilityButton } from 'components/button';
import { SearchOutlined } from '@ant-design/icons';
import { ErrorText } from 'components/typography/error-text';
import { SuccessText } from 'components/typography/success-text';

const armenianPattern = [
  {
    pattern: armenianLettersPattern,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
];

export const DisabilityDeathPersonDataSearchForm = () => {
  const [search, setSearch] = useState(false);
  const form = Form.useFormInstance();
  const isApproved = Form.useWatch(['applicationForm', 'applicantData', 'isApproved'], { preserve: true });

  const onReset = () => {
    form.resetFields([
      ['applicationForm', 'applicantData', 'firstName'],
      ['applicationForm', 'applicantData', 'lastName'],
      ['applicationForm', 'applicantData', 'patronymicName'],
      ['applicationForm', 'applicantData', 'birthDate'],
      ['applicationForm', 'applicantData', 'ssn'],
      ['applicationForm', 'applicantData', 'isApproved'],
    ]);
  };

  const onSearch = () => {
    form
      .validateFields([
        ['applicationForm', 'applicantData', 'firstName'],
        ['applicationForm', 'applicantData', 'lastName'],
        ['applicationForm', 'applicantData', 'patronymicName'],
        ['applicationForm', 'applicantData', 'birthDate'],
        ['applicationForm', 'applicantData', 'ssn'],
      ])
      .then((values) => {
        const ssn = values.applicationForm.applicantData.ssn;
        const firstName = values.applicationForm.applicantData.firstName;
        const lastName = values.applicationForm.applicantData.lastName;
        const patronymicName = values.applicationForm.applicantData.patronymicName;
        const birthDate = dateConvert(values.applicationForm.applicantData.birthDate, DATE_FORMAT_YYYY_MM_DD);

        if (ssn && firstName && lastName && birthDate) {
          setSearch({ ssn, firstName, lastName, patronymicName, birthDate });
        }
      });
  };

  const { isInitialLoading } = useGetValidateUser(URL_GET_GET_QKAG_DEATH_INFO, search, {
    enabled: !!search.ssn,
    onSuccess: (data) => {
      form.setFieldValue(['applicationForm', 'applicantData', 'isApproved'], data.identified);
      setSearch(false);
    },
    select: (data) => data.data,
    onError: (error) => {
      errorMessage(error);
      setSearch();
    },
  });

  return (
    <Spin spinning={isInitialLoading}>
      <VerticalSpace>
        <Title level={2} color="#0B847F">
          Մահացած անձի տվյալներ
        </Title>
        <VerticalSpace size="small">
          <Row justify="space-between">
            <Col span={7}>
              <FormItem
                name={['applicationForm', 'applicantData', 'firstName']}
                label="Անուն"
                rules={[{ required: true }, ...armenianPattern]}
              >
                <ArmenianInput />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem
                name={['applicationForm', 'applicantData', 'lastName']}
                label="Ազգանուն"
                rules={[{ required: true }, ...armenianPattern]}
              >
                <ArmenianInput />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem
                name={['applicationForm', 'applicantData', 'patronymicName']}
                label="Հայրանուն"
                rules={[{ required: true }, ...armenianPattern]}
              >
                <ArmenianInput />
              </FormItem>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={7}>
              <FormItem
                name={['applicationForm', 'applicantData', 'ssn']}
                label="ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանք"
                rules={[
                  { required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value === getFieldValue(['applicationForm', 'legalRepresentative', 'ssn'])) {
                        return Promise.reject(
                          new Error('Մահացած անձը և Օրինական ներկայուցուցչը չեն կարող լինել նույն մարդը')
                        );
                      }
                      if (value === getFieldValue(['applicationForm', 'ssn'])) {
                        return Promise.reject(new Error('Դիմումատուն և Մահացած անձը չեն կարող լինել նույն մարդը'));
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem
                name={['applicationForm', 'applicantData', 'birthDate']}
                label="Ծննդյան ամսաթիվ "
                rules={[{ required: true }]}
              >
                <Datepicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </FormItem>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={7}>
              <VerticalSpace>
                {/* <PersonMainData formItemProp={formItemProp} /> */}

                {isApproved === null || isApproved === undefined ? (
                  <DisabilityButton block size="large" icon={<SearchOutlined />} type="default" onClick={onSearch}>
                    Որոնել
                  </DisabilityButton>
                ) : (
                  <DisabilityButton block size="large" icon={<SearchOutlined />} type="default" onClick={onReset}>
                    Չեղարկել
                  </DisabilityButton>
                )}

                {isApproved === false && <ErrorText text="Մահացած անձը չի նույնականացվել" />}
                {isApproved === true && <SuccessText text="Մահացած անձը հաջողությամբ նույնականացվել է" />}
                <FormItem
                  name={['applicationForm', 'applicantData', 'isApproved']}
                  label={false}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('Մահացած անձը չի նույնականացվել'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input type="hidden" />
                </FormItem>
              </VerticalSpace>
            </Col>
          </Row>
        </VerticalSpace>
      </VerticalSpace>
    </Spin>
  );
};
