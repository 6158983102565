import { Col, Form, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input, TextArea } from 'components/input';

export const ApplicationDetails = () => {
  const data = useWatch('applicationData', { preserve: true });

  return (
    <FormSectionCard title={'Հայտի տվյալներ'}>
      <Row gutter={[12, 12]} justify="start">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Աջակցության հայտ">
            <Input value={data?.num} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Ներկայացման ամսաթիվ">
            <Input value={data?.numDate} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Կարգավիճակ">
            <Input value={data?.status} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Կարգավիճակի ամսաթիվ">
            <Input value={data?.statusChangeDate} />
          </Form.Item>
        </Col>
        {data?.notes && (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label={'Նշումներ'}>
              <TextArea value={data?.notes} disabled />
            </Form.Item>
          </Col>
        )}
        {data?.rejectionReason && (
          <Col span={24}>
            <Form.Item label="Մերժման պատճառը">
              <Input value={data?.rejectionReason} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </FormSectionCard>
  );
};
