import { Card, Col } from 'antd';
import { Button } from 'components/button';
import { LottieIcon } from 'components/lottie';
import { Text, Title } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { Link } from 'react-router-dom';

export const HomePageCard = ({ data }) => {
  return (
    <Col lg={16} xl={12} xxl={8} style={{ width: '100%' }}>
      <Card
        bodyStyle={{ minHeight: 380 }}
        actions={[
          <Button block size="large" key="view" style={{ width: 150, float: 'left', margin: '0 20px' }} type="primary">
            <Link to={data.url} style={{ color: COLORS.PRIMARY.WHITE }}>
              Տեսնել ավելին
            </Link>
          </Button>,
        ]}
      >
        <LottieIcon src={data.icon} />
        <Title style={{ fontSize: 21 }}>{data.title}</Title>
        <Text style={{ fontSize: 17 }}>{data.text}</Text>
      </Card>
    </Col>
  );
};
