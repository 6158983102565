import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const MainSider = styled(Sider)`
  background-color: #0b847f;
  padding: 114px 12px;

  .ant-layout-sider-zero-width-trigger {
    top: 60px;
  }

  @media (max-width: 786px) {
    position: absolute !important;
    z-index: 99;
    min-height: 100vh;
    padding: 0;

    .ant-layout-sider-zero-width-trigger {
      top: 30px;
    }
  }
`;
