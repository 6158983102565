import { useQueries } from '@tanstack/react-query';
import { URL_RELATIVE_TYPES } from 'api/benefit/constants';
import { client } from 'api/client';
import { URL_GET_CITIZEN_BUILDING_TYPES, URL_GET_EDUCATIONS, URL_GET_VULNERABILITY } from './constants';

export const DICTIONARY_URLS = {
  SECOND_STEP: {
    URL_GET_CITIZEN_BUILDING_TYPES,
    URL_GET_EDUCATIONS,
    URL_RELATIVE_TYPES,
    URL_GET_VULNERABILITY,
  },
};

export const useGetAllDictionaries = (url) => {
  const queryObjects = Object.entries(url).map(([key, url]) => ({
    queryKey: [key],
    queryFn: () => client.get(url).then((res) => res.data),
  }));

  const results = useQueries({ queries: queryObjects });

  const isLoading = results.some((query) => query.isLoading);
  const data = results.map((i) => i.data);
  return { data, isLoading };
};
