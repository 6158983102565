import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, message } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { URL_CHECK_PERSON_REPRESENTATIVE } from 'api/urrp/constants';
import { usePostMutate } from 'api/urrp/use-post-mutate';
import { Button } from 'components/button';
import { FormSectionCard } from 'components/card/form-section-card';
import { useAuth } from 'context/auth-context';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';
import { useState } from 'react';
import { useMatches } from 'react-router-dom';

export const PersonSearchForm = () => {
  const form = useFormInstance();
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;
  const {
    user: { tin, ssn },
  } = useAuth();

  const { mutate, isLoading } = usePostMutate(URL_CHECK_PERSON_REPRESENTATIVE, {
    onSuccess: (data) => {
      setIsValidated(true);
      form.setFieldsValue({
        ...data.data,
      });
    },
    onError: () => {
      form.resetFields(['ssn']);
      message.error('Տվյալ անձը չի հանդիսանում այս կազմակերպության աշխատակիցը');
    },
  });
  const handleSearch = () => {
    form
      .validateFields(['ssn'])
      .then((values) => {
        mutate({
          directorSsn: ssn,
          workerSsn: values.ssn,
          taxNumber: tin,
        });
      })
      .catch(() => message.error('Լրացրեք բոլոր անհրաժեշտ դաշտերը'));
  };

  const [isValidated, setIsValidated] = useState(false);
  return (
    <FormSectionCard title={'Անձնական տվյալներ'}>
      <Row align={'middle'} gutter={[12, 12]} justify={'middle'}>
        <Col md={14} xs={24}>
          <Form.Item
            label="ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանք"
            rules={[
              { required: true },
              {
                min: 10,
                message: 'Անհրաժեշտ է մուտքագրել 10 նիշ',
              },
            ]}
            name={'ssn'}
          >
            <Input maxLength={10} disabled={isValidated || type !== APPLICATION_MODE.FILL} />
          </Form.Item>
        </Col>
        <Col md={10} xs={24}>
          <Button
            type="default"
            shape="default"
            size="large"
            style={{ borderRadius: '10px', height: '45px', marginTop: '5px' }}
            disabled={isValidated || type !== APPLICATION_MODE.FILL}
            onClick={handleSearch}
            loading={isLoading}
          >
            Որոնել <SearchOutlined />
          </Button>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col md={8} xs={24}>
          <Form.Item label="Անուն" name="firstName">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item label="Ազգանուն" name="lastName">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item label="Հայրանուն" name="patronymicName">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
