import { Form, Spin } from 'antd';
import { PATHS } from 'helpers/constants';
import { useEffect, useState } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';

import { Supportsteps } from './constants';
import { ActionButtons } from './components/action-buttons';
import { VerticalSpace } from 'components/space/vertical-space';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_CHECK_AGREEMENTS, URL_GET_DONOR_APPLICATION_BY_ID } from 'api/urrp/constants';
import { useAuth } from 'context/auth-context';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';

import { RapidNeedSteps } from 'pages/rapid-need/components/stepper';
import { modifyViewValues } from './utils';

export const OfferingSupport = () => {
  const {
    user: { profileId, companyId, isLegal, representativeProfileId },
  } = useAuth();
  const [infoModalOpen, setInfoModalOpen] = useState(true);

  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const id = match?.params?.id;
  const type = match?.handle?.type;
  const { data: agreementData, isLoading } = useGetFfromBenefit(
    URL_CHECK_AGREEMENTS,
    {
      profileId: !isLegal ? profileId : null,
      companyId: isLegal ? companyId || profileId : null,
    },
    {
      enabled: type !== APPLICATION_MODE.VIEW,
    }
  );
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const items = Supportsteps.map((item) => ({ key: item.title, title: item.title }));

  const { isLoading: isApplicationLoading } = useGetFfromBenefit(
    URL_GET_DONOR_APPLICATION_BY_ID,
    {
      applicationId: id,
    },
    {
      enabled: !!id,
      onSuccess: (data) => {
        form.setFieldsValue({ ...modifyViewValues(data) });
      },
    }
  );

  const navigate = useNavigate();
  const next = () => {
    setCurrent(current + 1);
    document.getElementById('support-page').scrollIntoView();
  };

  const prev = () => {
    setCurrent(current - 1);
    document.getElementById('support-page').scrollIntoView();
  };
  useEffect(() => {
    document.getElementById('support-page').scrollIntoView();
    if (!isLoading) {
      if (!infoModalOpen && !agreementData?.hasApplication && type === APPLICATION_MODE.FILL) {
        Modal.error({
          title: 'Ծանուցում',
          closable: true,
          onCancel: () => navigate(PATHS.HOME),
          content:
            'Դուք ՀՀ ԱՍՀՆ-ի հետ չունեք համագործակցության գործող համաձայնագիր կամ հուշագիր։ Խնդրում ենք ներկայացնել համագործակցության հայտ:',
          okButtonProps: { style: { display: 'none' } },
          footer: !agreementData?.hasUnAcceptedApplication ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                type="primary"
                shape="round"
                style={{ background: '#456FAE' }}
                onClick={() => {
                  navigate(PATHS.CREATE_DONOR_AGREEMENT);
                  Modal.destroyAll();
                }}
              >
                Ներկայացնել դիմում
              </Button>
            </div>
          ) : (
            <></>
          ),
        });
      }
    }
  }, [agreementData, infoModalOpen, isLegal, navigate, type, isLoading]);

  return (
    <>
      <Modal
        open={infoModalOpen && type === APPLICATION_MODE.FILL}
        onOk={() => setInfoModalOpen(false)}
        closable={false}
        okText="Տրամադրել աջակցություն"
        onCancel={() => navigate(PATHS.HOME)}
        width={800}
      >
        Եթե ցանկանում եք աջակցել կյանքի դժվարին իրավիճակում հայտնված անձանց/ընտանիքներին, ապա լրացրեք աջակցության հայտը։
        Հայտը կարող են լրացնել թե՛ իրավաբանական, թե՛ ֆիզիկական անձինք։ <br /> <br /> Ուշադրություն. <br /> Եթե
        իրավաբանական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ համոզված եղեք, որ ՀՀ ԱՍՀՆ-ի հետ ունեք
        համագործակցության համաձայնագիր կամ հուշագիր։ Եթե չունեք այդպիսին, ապա կարող եք լրացնել համագործակցության հայտը,
        որից հետո կկարողանաք ներկայացնել աջակցության հայտը։ Եթե ֆիզիկական անձ եք, ապա աջակցության հայտ ներկանացնելուց
        առաջ լրացրեք ՀՀ ԱՍՀՆ հետ համագործակցության հայտը։
      </Modal>
      <Spin spinning={isLoading || type !== APPLICATION_MODE.FILL ? isApplicationLoading : false}>
        <RapidNeedSteps current={current} items={items} />
        <Form
          name="support-form"
          form={form}
          layout="vertical"
          autoComplete="off"
          preserve={true}
          disabled={type === APPLICATION_MODE.VIEW}
        >
          <VerticalSpace>
            <div id="support-page">{Supportsteps[current].content}</div>
            <ActionButtons
              next={next}
              prev={prev}
              step={current}
              isView={type === APPLICATION_MODE.VIEW}
              isEdit={type === APPLICATION_MODE.EDIT}
              applicationId={id}
              representativeProfileId={representativeProfileId}
            />
          </VerticalSpace>
        </Form>
      </Spin>
    </>
  );
};
