import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

import { VerticalSpace } from '../../components/space/vertical-space';
import { ApplicationInfoForm } from '../../components/form/application/application-info-full-form';
import { PrintButton } from '../../components/button/print-button';
import { PATHS } from '../../helpers/constants';
// import { Info } from "../../components/typography/info";

export const ApplicationViewFormData = () => {
  const params = useParams();

  return (
    <VerticalSpace>
      <ApplicationInfoForm nonrelaible={true} />
      <Row justify="end" gutter={10}>
        <Col xs={18} sm={18} md={12} lg={7}>
          <PrintButton
            block
            text="Տպել դիմումը"
            onClick={() =>
              window.open(`${PATHS.NON_RELIABLE_AGREEMENT_PRINT.replace(':id', params.id)}`, '_blank', 'noopener,noreferrer')
            }
            disabled={false}
          />
          {/* <Info text="Այս հրահանգով պետք է գեներացնել և տպել տվյալ դիմումի Համաձայնության թերթիկը, որը պետք է ստորագրվի ընտանիքի բոլոր չափահաս անդամների կողմից" /> */}
        </Col>
      </Row>
    </VerticalSpace>
  );
};
