import { Col, Form, Row } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormSectionCard } from 'components/card/form-section-card';
import { Datepicker } from 'components/datepicker';
import { Input } from 'components/input';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export const DIPersonDataForm = ({ disabled, personData, isView }) => {
  const form = useFormInstance();
  useEffect(() => {
    if (disabled && !isView) {
      form.setFields([
        {
          name: ['personMainData', 'firstName'],
          value: personData.firstName,
        },
        {
          name: ['personMainData', 'lastName'],
          value: personData.lastName,
        },
        {
          name: ['personMainData', 'patronymicName'],
          value: personData.patronymicName,
        },
        {
          name: ['personMainData', 'birthdate'],
          value: dayjs(personData.birthdate),
        },
        {
          name: ['personMainData', 'ssn'],
          value: personData.ssn,
        },
        {
          name: ['personMainData', 'documentNumber'],
          value: personData.documents?.[0]?.documentNumber,
        },
        {
          name: ['personMainData', 'documentValidUntil'],
          value: dayjs(personData.documents?.[0]?.documentValidationDate),
        },
      ]);
    }
  }, [disabled, form, isView, personData]);

  return (
    <FormSectionCard title={'Անձնական տվյալներ'}>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Անուն'} rules={[{ required: true }]} name={['personMainData', 'firstName']}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Ազգանուն'} rules={[{ required: true }]} name={['personMainData', 'lastName']}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Հայրանուն'} rules={[{ required: true }]} name={['personMainData', 'patronymicName']}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'ՀԾՀ/ՀԾՀ չունենալու վերաբերյալ տեղեկանքի համար'}
            rules={[{ required: true }]}
            name={['personMainData', 'ssn']}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={'Ծննդյան ամսաթիվ'} rules={[{ required: true }]} name={['personMainData', 'birthdate']}>
            <Datepicker format="DD/MM/YYYY" style={{ borderRadius: 10, width: '100%' }} disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={'ԱՀՓ համար'} rules={[{ required: true }]} name={['personMainData', 'documentNumber']}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Վավերականության ժամկետ'}
            rules={[{ required: true }]}
            name={['personMainData', 'documentValidUntil']}
          >
            <Datepicker format="DD/MM/YYYY" style={{ borderRadius: 10, width: '100%' }} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
