import { Col, Form, Row } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';
import { Select } from 'components/select';
import { FIELD_NAMES_DEFAULT } from 'components/select/constants';

export const ApplicationInfo = () => {
  const rejectionReasons = Form.useWatch('rejectionReasons', { preserve: true });
  return (
    <FormSectionCard title={'Դիմումի տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Դիմումի համարը" name={'num'}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Ներկայացման ամսաթիվը" name={'submittedDate'}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Կարգավիճակը" name={'status'}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Կարգավիճակի ամսաթիվը" name={'statusDate'}>
            <Input />
          </Form.Item>
        </Col>
        {rejectionReasons && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Մերժման պատճառ" name="rejectionReasons">
              <Select fieldNames={FIELD_NAMES_DEFAULT} mode="multiple"></Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </FormSectionCard>
  );
};
