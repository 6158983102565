import { Col, Form, Row, Space, message } from 'antd';
import { Button } from 'components/button';
import { COLORS, PATHS } from 'helpers/constants';
import { modifyFormListValues, modifySaveValues } from '../utils';
import { useSaveApplication } from 'api/application/use-save-application';
import { URL_EDIT_CITIZEN_APPLICATION, URL_SAVE_CITIZEN_APPLICATION } from 'api/urrp/constants';
import { errorMessage } from 'helpers/utils';
import { Modal } from 'components/modal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/auth-context';

export const ActionButtons = ({ next, prev, step, isView, isEdit, applicationId }) => {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const {
    user: { profileId },
  } = useAuth();

  const { mutate: saveApplication, isLoading } = useSaveApplication(
    isEdit ? URL_EDIT_CITIZEN_APPLICATION : URL_SAVE_CITIZEN_APPLICATION,
    {
      onSuccess: (response) => {
        const modal = Modal.success({
          title: 'Շնորհակալություն',
          content: (
            <p>
              {isEdit ? (
                <span>Շնորհակալություն Փոփոխությունները պահպանված են:</span>
              ) : (
                <p>
                  <span>
                    Ձեր հայտն ընդունված է: Առաջիկա հինգ աշխատանքային օրվա ընթացքում Ձեզ հետ կապ
                    կհաստատի սոցիալական աշխատողը: Հայտի համարն է{` `}
                  </span>
                  <span
                    style={{ textDecoration: 'underline', color: COLORS.PRIMARY.BLUE, cursor: 'pointer' }}
                    onClick={() => {
                      navigate(PATHS.APPLICATIONS);
                      modal.destroy();
                    }}
                  >
                    {response.data.applicationNumber} :
                  </span>
                </p>
              )}
            </p>
          ),
          footer: (
            <Row align="center" justify="center">
              <Col span={8}>
                <Space
                  size="middle"
                  align="center"
                  style={{ width: '100%', justifyContent: 'center', marginTop: '16px' }}
                >
                  <Button
                    size="medium"
                    type="primary"
                    onClick={() => {
                      navigate(PATHS.APPLICATIONS);
                      modal.destroy();
                    }}
                  >
                    Լավ
                  </Button>
                </Space>
              </Col>
            </Row>
          ),
          closable: true,
          onCancel: () => navigate(PATHS.APPLICATIONS),
        });
      },
      onError: errorMessage,
    }
  );

  const onNext = () => {
    if (isView) {
      next();
    }
    form
      .validateFields()
      .then((vals) => {
        if (step !== 2) {
          next();
        } else {
          const formData = form.getFieldsValue(true);
          const plainSelections = Object.values(vals?.needs || {}).filter((item) => !!item);
          if (plainSelections.length > 5 || plainSelections.length < 1) {
            message.error('Խնդրում ենք ցանկից ընտրել մինչև 5 կարիք, որոնք առավել առաջնային են');
            return;
          }
          if (isEdit) {
            saveApplication({
              ...formData,
              applicationId,
              needs: {
                bed: null,
                bedClothes: null,
                householdSupplement: null,
                medicine: null,
                psychicalSupport: null,
                schoolSupplies: null,
                hygieneItems: null,
                ...formData.needs,
                care: {
                  inplace: formData.needs?.care?.inplace || null,
                  careCenter: formData.needs?.care?.careCenter || null,
                },
                food: {
                  rawFood: formData.needs?.food?.rawFood || null,
                  readyFood: formData.needs?.food?.readyFood || null,
                  babyFood: {
                    underSixMonths: formData.needs?.food?.babyFood?.underSixMonths || null,
                    fromSixMonthToOneYear: formData.needs?.food?.babyFood?.fromSixMonthToOneYear || null,
                    overOneYear: formData.needs?.food?.babyFood?.overOneYear || null,
                  },
                },
                clothes: formData.needs?.clothes ? modifyFormListValues(formData.needs.clothes) : null,
                shoes: formData.needs?.shoes ? modifyFormListValues(formData.needs.shoes) : null,
                swaddlers: formData.needs?.swaddlers ? modifyFormListValues(formData.needs.swaddlers) : null,
                supportInstruments: formData.needs?.supportInstruments?.selections || [],
                other: formData.needs?.other?.other || '',
              },
            });
            return;
          } else {
            let dataToSave = modifySaveValues(formData, profileId);
            saveApplication(dataToSave);
          }
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        message.error('Կան սխալ լրացված դաշտեր');
      });
  };

  return (
    <Space style={{ justifyContent: 'center', display: 'flex' }}>
      {step > 0 && !isEdit && (
        <Button disabled={false} onClick={() => prev()}>
          Նախորդ
        </Button>
      )}
      {step < 2 && (
        <Button type="primary" onClick={onNext} disabled={false}>
          Հաջորդ
        </Button>
      )}
      {step === 2 && !isView && (
        <Button type="primary" htmlType="submit" onClick={onNext} disabled={false} loading={isLoading}>
          Հաստատել
        </Button>
      )}
    </Space>
  );
};
