import { Col, Form, Radio, Row } from 'antd';
import { URL_GET_NOTIFICATION_TYPES } from 'api/benefit/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { FormItem } from 'components/form/form-item';
import { requiredMessage } from '../constants';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';

export const DisabilityNotifyBy = () => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const { data: dataNotificationType } = useGetFfromBenefit(URL_GET_NOTIFICATION_TYPES);
  const options = dataNotificationType.map((item) => ({
    value: item.id,
    label: item.name,
    disabled: !item.isActive,
  }));

  return (
    <Row>
      <Col span={12}>
        <VerticalSpace>
          <Title level={2} color="#0B847F">
            {`Ծանուցումը նախընտրում ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'Է'} ստանալ՝`}
          </Title>
          <FormItem
            name={['applicationForm', 'preferGetNotified']}
            validateFirst
            dependencies={[
              ['applicationForm', 'actualAddress', 'postIndex'],
              ['applicationForm', 'email'],
            ]}
            rules={[
              { required: true, message: requiredMessage },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value === 3 &&
                    getFieldValue(['applicationForm', 'fillingApplication']) === 1 &&
                    !getFieldValue(['applicationForm', 'email'])
                  ) {
                    return Promise.reject(new Error('Անհրաժեշտ է լրացնել Էլեկտրոնային փոստի հասցեն'));
                  }
                  if (value === 2 && !getFieldValue(['applicationForm', 'actualAddress', 'postIndex'])) {
                    return Promise.reject(new Error('Անհրաժեշտ է լրացնել փոստային դասիչը'));
                  }
                  if (
                    value === 3 &&
                    getFieldValue(['applicationForm', 'fillingApplication']) === 2 &&
                    !getFieldValue(['applicationForm', 'email']) &&
                    !getFieldValue(['applicationForm', 'legalRepresentative', 'email'])
                  ) {
                    return Promise.reject(new Error('Անհրաժեշտ է լրացնել Էլեկտրոնային փոստի հասցեն'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Radio.Group
              className="radlo_button"
              options={options}
              style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
            />
          </FormItem>
        </VerticalSpace>
      </Col>
      <Col span={12}>
        <VerticalSpace>
          <Title level={2} color="#0B847F">
            {`Որոշումը նախընտրում ${applicantType === APPLICATION_FOR_HIMSELF ? 'եմ' : 'Է'} ստանալ՝`}
          </Title>
          <FormItem
            name={['applicationForm', 'preferReceiveApplications']}
            validateFirst
            dependencies={[
              ['applicationForm', 'actualAddress', 'postIndex'],
              ['applicationForm', 'email'],
            ]}
            rules={[
              { required: true, message: requiredMessage },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value === 3 &&
                    getFieldValue(['applicationForm', 'fillingApplication']) === 1 &&
                    !getFieldValue(['applicationForm', 'email'])
                  ) {
                    return Promise.reject(new Error('Անհրաժեշտ է լրացնել Էլեկտրոնային փոստի հասցեն'));
                  }
                  if (value === 2 && !getFieldValue(['applicationForm', 'actualAddress', 'postIndex'])) {
                    return Promise.reject(new Error('Անհրաժեշտ է լրացնել փոստային դասիչը'));
                  }
                  if (
                    value === 3 &&
                    getFieldValue(['applicationForm', 'fillingApplication']) === 2 &&
                    !getFieldValue(['applicationForm', 'email']) &&
                    !getFieldValue(['applicationForm', 'legalRepresentative', 'email'])
                  ) {
                    return Promise.reject(new Error('Անհրաժեշտ է լրացնել Էլեկտրոնային փոստի հասցեն'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Radio.Group
              className="radlo_button"
              options={options}
              style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
            />
          </FormItem>
        </VerticalSpace>
      </Col>
    </Row>
  );
};
