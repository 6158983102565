import { ShoesForm } from 'pages/rapid-need/components/form-lists/shoes-form';
import { FirstStep } from './steps/first-step';
import { ClothesForm } from 'pages/rapid-need/components/form-lists/clothes-form';
import { FoodCheckbox } from 'pages/rapid-need/components/food-checkbox';
import { SupportInstrumentList } from 'pages/rapid-need/components/form-lists/support-instrument-list';
import { SwaddlerForm } from 'pages/rapid-need/components/form-lists/swaddler-form';
import { CareList } from 'pages/rapid-need/components/form-lists/care-list';
import { SecondStep } from './steps/second-step';

export const support = 'support';

export const Supportsteps = [
  {
    title: 'Ծրագրի տվյալներ',
    content: <FirstStep />,
  },
  {
    title: 'Տրամադրվող աջակցություն',
    content: <SecondStep />,
  },
];

export const careOptions = [
  {
    label: 'Տնային պայմաններում',
    fieldName: 'inplace',
    id: 1,
  },
  {
    label: 'Խնամքի կենտրոնում (հաստատությունում)',
    fieldName: 'careCenter',
    id: 2,
  },
];

export const supportOptions = [
  {
    id: 1,
    label: 'Կացարան',
    fieldName: 'accommodation',
  },
  { id: 2, label: 'Սնունդ', fieldName: 'food', subItem: <FoodCheckbox /> },
  { id: 3, label: 'Հագուստ', fieldName: 'clothes', subItem: <ClothesForm /> },
  { id: 4, label: 'Կոշիկ', fieldName: 'shoes', subItem: <ShoesForm /> },
  {
    id: 5,
    label: 'Աջակցող միջոցներ (պրոթեզաօրթոպեդիկ պարագաներ)',
    fieldName: 'supportInstruments',
    subItem: <SupportInstrumentList />,
  },
  { id: 6, label: 'Հիգիենայի պարագաներ', fieldName: 'hygieneItems' },
  { id: 7, label: 'Տակդիր/միջադիր', fieldName: 'swaddlers', subItem: <SwaddlerForm /> },
  { id: 8, label: 'Մահճակալ (ներքնակ)', fieldName: 'bed' },
  { id: 9, label: 'Անկողնային պարագաներ', fieldName: 'bedClothes' },
  { id: 10, label: 'Կենցաղային պարագաներ', fieldName: 'householdSupplement' },
  { id: 11, label: 'Դեղորայք', fieldName: 'medicine' },
  { id: 12, label: 'Դպրոցական պարագաներ', fieldName: 'schoolSupplies' },
  { id: 13, label: 'Հոգեբանական աջակցություն', fieldName: 'psychicalSupport' },
  { id: 14, label: 'Խնամք', fieldName: 'care', subItem: <CareList /> },
  { id: 15, label: 'Այլ', fieldName: 'other', hasComment: true },
];

export const foodOptions = [
  {
    id: 1,
    label: 'Չոր (չեփված) սննդամթերք',
    fieldName: 'rawFood',
  },
  {
    id: 2,
    label: 'Պատրաստի եփած սնունդ',
    fieldName: 'readyFood',
  },
  {
    id: 3,
    label: 'Մանկական սնունդ',
    fieldName: 'babyFood',
  },
];

export const childFoodOptions = [
  {
    id: 1,
    label: 'Մինչև 6 ամսական երեխայի համար',
    fieldName: 'underSixMonths',
  },
  {
    id: 2,
    label: '6 ամսականից 1 տարեկան երեխայի համար',
    fieldName: 'fromSixMonthToOneYear',
  },
  {
    id: 3,
    label: '1 տարեկանից',
    fieldName: 'overOneYear',
  },
];
