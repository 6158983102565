export const APPLICATION_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create',
};

export const formItemRegAddress = {
  regionId: {
    name: ['mainData', 'registrationAddress', 'regionId'],
  },
  region: {
    name: ['mainData', 'registrationAddress', 'region'],
  },
  communityId: {
    name: ['mainData', 'registrationAddress', 'communityId'],
  },
  community: {
    name: ['mainData', 'registrationAddress', 'community'],
  },
  settlementId: {
    name: ['mainData', 'registrationAddress', 'settlementId'],
  },
  settlement: {
    name: ['mainData', 'registrationAddress', 'settlement'],
  },
  street: {
    name: ['mainData', 'registrationAddress', 'street'],
  },
  building: {
    name: ['mainData', 'registrationAddress', 'building'],
  },
  buildingType: {
    name: ['mainData', 'registrationAddress', 'buildingType'],
  },
  buildingTypeId: {
    name: ['mainData', 'registrationAddress', 'buildingTypeId'],
  },
  apartment: {
    name: ['mainData', 'registrationAddress', 'apartment'],
  },
  postIndex: {
    name: ['mainData', 'registrationAddress', 'postIndex'],
  },
};

export const formItemActualAddress = {
  regionId: {
    name: ['mainData', 'actualAddress', 'regionId'],
  },
  region: {
    name: ['mainData', 'actualAddress', 'region'],
  },
  communityId: {
    name: ['mainData', 'actualAddress', 'communityId'],
  },
  community: {
    name: ['mainData', 'actualAddress', 'community'],
  },
  settlementId: {
    name: ['mainData', 'actualAddress', 'settlementId'],
  },
  settlement: {
    name: ['mainData', 'actualAddress', 'settlement'],
  },
  street: {
    name: ['mainData', 'actualAddress', 'street'],
  },
  building: {
    name: ['mainData', 'actualAddress', 'building'],
  },
  buildingType: {
    name: ['mainData', 'actualAddress', 'buildingType'],
  },
  buildingTypeId: {
    name: ['mainData', 'actualAddress', 'buildingTypeId'],
  },
  apartment: {
    name: ['mainData', 'actualAddress', 'apartment'],
  },
  postIndex: {
    name: ['mainData', 'actualAddress', 'postIndex'],
  },
};
