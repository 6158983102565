import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { Col, Row } from 'antd';
import { InputNumber } from 'components/input-number';

export const DisabiityQuestionBiometric = () => {
  return (
    <VerticalSpace>
      <Title level={2} color="#0B847F">
        Նկարագրություն
      </Title>
      <Row>
        <Col span={3}>
          <FormItem label="Հասակը" name={['questionnaire', 'biometrics', 'height']} rules={[{ required: true }]}>
            <InputNumber min={0} />
          </FormItem>
        </Col>
        <Col span={3}>
          <FormItem label="Քաշը" name={['questionnaire', 'biometrics', 'weight']} rules={[{ required: true }]}>
            <InputNumber min={0} />
          </FormItem>
        </Col>
      </Row>
    </VerticalSpace>
  );
};
