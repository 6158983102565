// import { useNavigate } from 'react-router-dom';
// import { PATHS } from 'helpers/constants';
import { Card as CardComponent, Avatar, Popover as PopoverComponent, Row, Col } from 'antd';
import styled from 'styled-components';
import { DisabilitySubtypes } from './disability-subtypes';
import { SecondaryText, Text } from 'components/typography';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_EVALUATION_WITH_SUBTYPES } from 'api/benefit/constants';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import { ReactComponent as DisabilityTypes } from '../../icons/disability-types.svg';
import { VerticalSpace } from 'components/space/vertical-space';

const DisabilityTypeCard = styled(CardComponent)`
  &&& {
    .ant-card-grid {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .ant-card-meta-detail {
        display: flex;
        align-items: center;
      }
    }

    .ant-popover {
      .ant-popover-arrow {
        top: 50% !important;
        left: 110% !important;
        &::before {
          background-color: rgba(226, 225, 225, 1);
          transform: rotate(90deg);
        }
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
`;

const { Meta } = CardComponent;

const gridStyle = {
  minWidth: '100%',
  height: 200,
  cursor: 'pointer',
};
const StyledTextHeader = styled(Text)`
  &&& {
    margin-bottom: 16px;  
    display: block;
    text-align: start;  
  }
`;

const icons = ['Primary_assessment.svg', 'Reassessment.svg', 'Double_assessment.svg', 'Cause_of_death.svg'];

export const DisabilityType = () => {
  const [, setHeaderText] = useOutletContext();

  useEffect(() => {
    setHeaderText?.({ text: 'ՖՈՒՆԿՑԻՈՆԱԼՈՒԹՅԱՆ ԳՆԱՀԱՏՈՒՄ', icon: <DisabilityTypes style={{ width: '32px' }} /> });
  }, [setHeaderText]);

  const { data: disabilityTypes } = useGetFfromBenefit(URL_GET_EVALUATION_WITH_SUBTYPES);
  const breakpoint = useBreakpoint();

  return (
    <VerticalSpace>
      <StyledTextHeader>Ընտրեք անհրաժեշտ դիմումի տեսակը</StyledTextHeader>
      <DisabilityTypeCard>
        <Row gutter={0}>
          {disabilityTypes.map((item, index) => (
            <Col span={12} key={item.id} >
              <PopoverComponent
                overlayInnerStyle={{
                  backgroundColor: 'rgba(226, 225, 225, 1)',
                  minHeight: 180,
                  width: breakpoint.xxl ? '110%' : '90%',
                }}
                placement="left"
                arrow={false}
                content={<DisabilitySubtypes items={item.items} />}
                getPopupContainer={(trigger) => trigger.parentElement}
                align={{ points: ['tl', 'tl'], offset: [10, 10] }}
                key={item.id}
              >
                <CardComponent.Grid style={gridStyle} key={item.id}>
                  <Meta
                    avatar={
                      // eslint-disable-next-line no-undef
                      <Avatar size={42} src={require(`./images/${icons[index]}`)} />
                    }
                    description={
                      <SecondaryText color="rgba(19, 0, 46, 0.64)" strong>
                        {item.name}
                      </SecondaryText>
                    }
                  />

                  {/* <Tooltip title={item.description} placement="bottom">
                            <span></span>  
                        </Tooltip> */}
                </CardComponent.Grid>
              </PopoverComponent>
            </Col>
          ))}
        </Row>
      </DisabilityTypeCard>
    </VerticalSpace>

  );
};
