import { VerticalSpace } from 'components/space/vertical-space';
import { Form, Radio } from 'antd';
import { Text } from 'components/typography';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { COLORS } from 'helpers/constants';
import { formItemActualAddress, formItemRegAddress } from 'pages/donor-agreement/constnats';
import { ApplicationInfo } from 'pages/agreements/components/application-info';
import { ApplicantInfo } from 'pages/agreements/components/applicant-info';
import { PersonAddress } from 'pages/agreements/components/person-address';
import { SupportDetails } from 'pages/agreements/components/support-details';
import { CompanyInfo } from 'pages/agreements/components/company-info';
import { Checkbox } from 'components/checkbox';

export const FirstTab = () => {
  const form = useFormInstance();
  const legalTypeId = form.getFieldValue('legalTypeId');

  return (
    <>
      <VerticalSpace>
        <ApplicationInfo />
        {legalTypeId === 1 ? <ApplicantInfo /> : <CompanyInfo />}
        <PersonAddress
          formItemAddress={formItemRegAddress}
          title={legalTypeId === 1 ? 'Հաշվառման հասցե' : 'Իրավաբանական հասցե'}
          viewMode
        />
        <FormSectionFullCard>
          <Form.Item
            label={
              <Text color={COLORS.PRIMARY.BLUE}>
                {legalTypeId === 1
                  ? 'Հաշվառման հասցեն համընկնում է փաստացի բնակության հասցեի հետ '
                  : 'Իրավաբանական հասցեն համընկնում է գործունեության հասցեի հետ'}
              </Text>
            }
            name={['mainData', 'isRegAddressActual']}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Radio.Group>
          </Form.Item>
        </FormSectionFullCard>
        <PersonAddress
          formItemAddress={formItemActualAddress}
          title={legalTypeId === 1 ? 'Փաստացի բնակության հասցե' : 'Կազմակերպության գործունեության հասցե'}
          isActualAddress={true}
          viewMode
        />
        <SupportDetails />
        <FormSectionFullCard>
          <Form.Item
            name={['agreementData', 'notifyTypeId']}
            label={<Text color={COLORS.PRIMARY.BLUE}>Հետադարձ կապը՝</Text>}
          >
            <Checkbox.Group>
              <Checkbox value={1} disabled>Հեռախոսահամարով</Checkbox>
              <Checkbox value={2}>Էլ. փոստով</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </FormSectionFullCard>
      </VerticalSpace>
    </>
  );
};
