import { Form, Spin } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { URL_GET_CLOTHE_TYPES } from 'api/urrp/constants';
import { AddClothe } from './manage-clothes-form-list';
import { useState } from 'react';
import { ErrorText } from 'components/typography/error-text';
import { Checkbox } from 'components/checkbox';

export const ClothesForm = ({ parentFormItem, itemData }) => {
  const { data, isLoading } = useGetFfromBenefit(URL_GET_CLOTHE_TYPES);
  const checkList = useWatch([...parentFormItem]);
  const [errorMessage, setErrorMessage] = useState(false);

  const validateAtLeastOneSelected = () => {
    const isAnyChecked = Object.values(checkList).some((val) => val?.id && val?.selections);
    if (!isAnyChecked) {
      setErrorMessage('Խնդրում ենք ընտրել բացված արժեքներից առնվազն մեկը');
      return Promise.reject();
    }
    setErrorMessage('');
    return Promise.resolve();
  };

  return (
    <Spin spinning={isLoading}>
      {data?.map((option) => {
        return (
          <Form.Item
            key={option.id}
            name={[...parentFormItem, option.id]}
            style={{ margin: '0 0 0 20px' }}
            rules={[{ validator: validateAtLeastOneSelected }]}
          >
            <Form.Item name={[...parentFormItem, option.id, 'id']} valuePropName="checked" style={{ margin: 0 }}>
              <Checkbox value={option.id}>{option.name}</Checkbox>
            </Form.Item>
            <Form.Item hidden />
            {checkList?.[option.id]?.id && (
              <AddClothe
                parentProp={[...parentFormItem, option.id, 'selections']}
                itemId={option.id}
                itemData={itemData}
              />
            )}
          </Form.Item>
        );
      })}
      {errorMessage && <ErrorText text={errorMessage} />}
    </Spin>
  );
};
