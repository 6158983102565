import { Row } from 'antd';
import { COLORS } from '../../helpers/constants';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { HomePageCard } from 'components/card/home-page-card';
import { COMPANY_HOME_PAGE_ITEMS, USER_HOME_PAGE_ITEMS } from './constants';
import { useAuth } from 'context/auth-context';

export const Home = () => {
  const {
    user: { isLegal },
  } = useAuth();
  return (
    <ContentWrapper style={{ padding: 20, background: COLORS.SECONDARY.ALICE_BLUE }}>
      <Row gutter={[80, 42]} align="center">
        {(isLegal ? COMPANY_HOME_PAGE_ITEMS : USER_HOME_PAGE_ITEMS).map((item, key) => (
          <HomePageCard data={item} key={key} />
        ))}
      </Row>
    </ContentWrapper>
  );
};
