import { Checkbox } from 'components/checkbox';
import { familyData, resideMemberOptions } from '../constants';

import { Form } from 'antd';

export const ResideMemberCheckbox = ({ isView }) => {
  const selections = Form.useWatch([familyData, 'hasResideMember'], {
    preserve: true,
  });

  return (
    <Form.Item
      label="Արդյոք այս պահին Ձեզ հետ կա՞ անձ, ով Ձեր ընտանիքի անդամը չէ"
      name={[familyData, 'hasResideMember']}
      rules={[{ required: true }]}
    >
      <Checkbox.Group style={{ display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'baseline' }}>
        {resideMemberOptions.map((option) => {
          return (
            <Checkbox
              value={option.value}
              key={option.value}
              disabled={
                isView
                  ? true
                  : option.value === 3
                  ? selections?.includes(1) || selections?.includes(2)
                    ? true
                    : false
                  : selections?.includes(3)
              }
            >
              {option.label}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </Form.Item>
  );
};
