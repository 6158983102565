import {Col, Row, Spin, message} from 'antd';
import {SearchOutlined} from '@ant-design/icons';

import {COLORS} from '../../helpers/constants';
import {Button} from '../button';
import {WarningSection} from '../card/warning-section';
import {VerticalSpace} from '../space/vertical-space';
import {Text} from '../typography';
import {PersonMainData} from './person-main-data';
import {Form} from './form';
import {useGetValidateUser} from '../../api/benefit/use-get-validate-user';
import {ErrorText} from '../typography/error-text';
import {dateConvert, errorMessage} from '../../helpers/utils';
import {SuccessText} from '../typography/success-text';
import {PersonContactForm} from './person-contact-form';

export const PersonDataSearchForm = ({formItemProp, url, onSuccess, onSelect, parent = [], locationRes}) => {
    const form = Form.useFormInstance();

    const isApproved = Form.useWatch([...parent, ...(formItemProp?.isApproved?.name || [])], {preserve: true});
    const ssn = Form.useWatch([...parent, ...formItemProp.ssn.name]);
    const firstName = Form.useWatch([...parent, ...formItemProp.fname.name]);
    const lastName = Form.useWatch([...parent, ...formItemProp.lname.name]);
    const patronymicName = Form.useWatch([...parent, ...formItemProp.patronymicName.name]);
    const birthdate = dateConvert(Form.useWatch([...parent, ...formItemProp.birthdate.name]));

    const {isInitialLoading, refetch} = useGetValidateUser(url, {ssn, firstName, lastName, patronymicName, birthdate}, {
        enabled: false || !!locationRes,
        onSuccess: (data) => {
            onSuccess(data);
            formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], !!data);
        },
        select: (data) => onSelect?.(data) || data.data,
        onError: (error) => {
            errorMessage(error);
        },
    });

    const onSearch = async () => {
        try {
            await form.validateFields([
                [...parent, ...formItemProp.ssn.name], 
                [...parent, ...formItemProp.fname.name], 
                [...parent, ...formItemProp.lname.name], 
                [...parent, ...formItemProp.patronymicName.name],
                [...parent, ...formItemProp.birthdate.name]
            ]);
            refetch()
          } catch (errorInfo) {
            message.error('Կան սխալ լրացված դաշտեր');
          }
    };    

    return (
        <Spin spinning={isInitialLoading}>
            <VerticalSpace>
                <PersonMainData formItemProp={formItemProp}/>
                {(locationRes === null || locationRes === undefined) && (
                    <Row justify="center">
                        <Col>
                            <Button size="large" icon={<SearchOutlined/>} type="default" onClick={onSearch}>
                                Որոնել
                            </Button>
                        </Col>
                    </Row>
                )}
                {isApproved === false && <ErrorText text="Նույնականացումը չի հաջողվել"/>}
                {isApproved === true && <SuccessText text="Դիմումատուն հաջողությամբ նույնականացվեց"/>}
                {formItemProp.contact && <PersonContactForm formItemContactProp={formItemProp.contact}/>}
                {(locationRes === null || locationRes === undefined) && (
                    <WarningSection>
                        <Text color={COLORS.SECONDARY.GRAY_DARK}>
                            Նույնականացման տվյալները անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան
                        </Text>
                    </WarningSection>
                )}
            </VerticalSpace>
        </Spin>
    );
};
