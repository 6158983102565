import { Col, DatePicker, Form, Row, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import Input from 'antd/es/input/Input';
import { FormSectionCard } from 'components/card/form-section-card';
import dayjs from 'dayjs';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';
import { useMatches } from 'react-router-dom';

const { Option } = Select;

export const AuthorityData = () => {
  const form = useFormInstance();
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;
  const disabledStartDate = (current) => {
    // Disable dates before today
    return current && current.isBefore(dayjs(), 'day');
  };

  const disabledEndDate = (current) => {
    // Disable dates before today and dates before start date
    const startDate = form.getFieldValue('validFrom');
    if (!startDate) {
      return current && current.isBefore(dayjs(), 'day');
    }
    return (
      current &&
      (current.isBefore(dayjs(), 'day') ||
        current.isBefore(dayjs(startDate), 'day') ||
        current.isSame(startDate, 'day'))
    );
  };

  return (
    <FormSectionCard title={'Լիազորություն'}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name="validFrom" label={'Լիազորության սկիզբ'} rules={[{ required: true }]} initialValue={dayjs()}>
            <DatePicker style={{ width: '100%' }} disabledDate={disabledStartDate} format="DD.MM.YYYY" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item
            name="validTo"
            label={'Լիազորության ավարտ'}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const startDate = getFieldValue('validFrom');
                  if ((value && startDate && value?.isBefore(startDate, 'day')) || value?.isSame(startDate, 'day')) {
                    return Promise.reject(
                      new Error('Լիազորության ավարտ դաշտի օրը Լիազորության սկիզբ դաշտի օրվանից պետք է մեծ լինի')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(current) => disabledEndDate(current)}
              format="DD.MM.YYYY"
              disabled={type === APPLICATION_MODE.VIEW}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item name="isActive" label="Կարգավիճակ" rules={[{ required: true }]} initialValue={true}>
            <Select disabled={type === APPLICATION_MODE.VIEW}>
              <Option value={true}>Ակտիվ</Option>
              <Option value={false}>Պասիվ</Option>
            </Select>
          </Form.Item>
        </Col>
        {type !== APPLICATION_MODE.FILL && (
          <>
            <Col xs={24} sm={24} md={24} lg={8}>
              <Form.Item name="dateCreated" label="Ստեղծվել է" initialValue={true}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <Form.Item name="createdBy" label="Ում կողմից" initialValue={true}>
                <Input />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </FormSectionCard>
  );
};
