import { Layout } from 'antd';
import styled from 'styled-components';

import { ManagePage } from './manage-page';
import { screenSize } from '../../helpers/constants';
import { UserCompanySelect } from 'components/select/user-company-select';

const { Content } = Layout;

const Wrapper = styled(Content)`
  padding: 32px;

  @media only screen and (max-width: ${screenSize.xs}) {
    padding: 0;
  }
`;

export const DefaultContent = ({ children }) => (
  <Wrapper>
    <ManagePage />
    <UserCompanySelect />
    {children}
  </Wrapper>
);
