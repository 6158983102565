import { useMutation } from '@tanstack/react-query';
import { client } from './client';

export const useGetMutate = (url, options) => {
  const mutation = useMutation({
    mutationFn: async (params) => {
      const res = await client.get(url, { params });
      return res.data;
    },
    ...options,
  });
  return mutation;
};
