import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, message } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { URL_GET_COMPANY_DATA } from 'api/urrp/constants';
import { useGetMutate } from 'api/use-get-mutate';
import { Button } from 'components/button';
import { FormSectionCard } from 'components/card/form-section-card';
import { APPLICATION_MODE } from 'pages/rapid-need/constants';
import { useState } from 'react';
import { useMatches } from 'react-router-dom';

export const CompanySearchForm = () => {
  const form = useFormInstance();
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;
  const { mutate, isLoading } = useGetMutate(URL_GET_COMPANY_DATA, {
    onSuccess: (data) => {
      setIsValidated(true);
      form.setFieldsValue({
        ...data.data,
        ssn: data.data?.director.ssn,
        ceo: `${data.data.director.firstName} ${data.data.director.lastName}`,
      });
    },
    onError: () => {
      form.resetFields(['taxNumber']);
      message.error('Կազմակերպությունը չի նույնականացվել');
    },
  });

  const handleSearch = () => {
    form
      .validateFields(['taxNumber'])
      .then((values) => {
        mutate({
          taxNumber: values.taxNumber,
        });
      })
      .catch(() => message.error('Լրացրեք բոլոր անհրաժեշտ դաշտերը'));
  };

  const [isValidated, setIsValidated] = useState(false);
  return (
    <FormSectionCard title={'Կազմակերպության տվյալներ'}>
      <Row align={'middle'} gutter={[12, 12]} justify={'middle'}>
        <Col md={14} xs={24}>
          <Form.Item
            label="ՀՎՀՀ"
            rules={[
              { required: true },
              {
                min: 8,
                message: 'Անհրաժեշտ է մուտքագրել 8 նիշ',
              },
            ]}
            name={'taxNumber'}
          >
            <Input maxLength={8} disabled={isValidated || type !== APPLICATION_MODE.FILL} />
          </Form.Item>
        </Col>
        <Form.Item hidden name={'ssn'} />
        <Col md={10} xs={24}>
          <Button
            type="default"
            shape="default"
            size="large"
            style={{ borderRadius: '10px', height: '45px', marginTop: '5px' }}
            disabled={isValidated || type !== APPLICATION_MODE.FILL}
            onClick={handleSearch}
            loading={isLoading}
          >
            Որոնել <SearchOutlined />
          </Button>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item label="Անվանում " name="companyName">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item label="Տեսակ" name="companyType">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item label="Տնօրեն" name="ceo">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
