import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { Actions } from 'components/button/actions';
import { APPLICATION_STATUSES, PATHS } from 'helpers/constants';
import { AUTHORITY_TYPES } from 'pages/applications/constants';

export const useColumns = (type, currentPage = 1, pageSize = 10) => {
  const navigate = useNavigate();

  return [
    {
      title: 'N',
      dataIndex: 'number',
      align: 'center',
      flex: 1,
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: type === AUTHORITY_TYPES.MY_AUTHORITIES ? 'Անուն Ազգանուն' : 'Անվանում',
      dataIndex: type === AUTHORITY_TYPES.MY_AUTHORITIES ? 'fullName' : 'companyName',
      key: type === AUTHORITY_TYPES.MY_AUTHORITIES ? 'fullName' : 'companyName',
      align: 'center',
      flex: 1,
    },
    {
      title: type === AUTHORITY_TYPES.MY_AUTHORITIES ? 'ՀԾՀ/ՀԾՀ չունենալու մասին տեղեկանք' : 'ՀՎՀՀ',
      dataIndex: type === AUTHORITY_TYPES.MY_AUTHORITIES ? 'ssn' : 'taxNumber',
      key: type === AUTHORITY_TYPES.MY_AUTHORITIES ? 'ssn' : 'taxNumber',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Լիազորության սկիզբ',
      dataIndex: 'validFrom',
      key: 'validFrom',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.startdDate, 'DD/MM/YYYY').diff(dayjs(b.startdDate, 'DD/MM/YYYY')),
    },
    {
      title: 'Լիազորության ավարտ',
      dataIndex: 'validTo',
      key: 'validTo',
      align: 'center',
      flex: 1,
      sorter: (a, b) => dayjs(a.endDate, 'DD/MM/YYYY').diff(dayjs(b.endDate, 'DD/MM/YYYY')),
    },
    {
      title: 'Ստեղծման ամսաթիվ',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      flex: 1,
      filters: APPLICATION_STATUSES,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      render: (item) => (
        <Actions
          items={item}
          onEdit={() => navigate(`${PATHS.EDIT_AUTHORITY.replace(':id', item.representativeId)}`)}
          onView={() => navigate(`${PATHS.VIEW_AUTHORITY.replace(':id', item.representativeId)}`)}
        />
      ),
    },
  ];
};
