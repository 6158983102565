import dayjs from 'dayjs';
import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/constants';

export const modifySaveValues = (
  formData,
  isLegal,
  profileId,
  companyId,
  isRepresentative,
  representativeProfileId
) => {
  if (formData.notifyTypeId.includes(1) && formData.notifyTypeId.includes(2)) {
    formData.notifyTypeId = 3;
  } else {
    formData.notifyTypeId = formData.notifyTypeId[0];
  }
  return {
    legalTypeId: isLegal ? 2 : 1,
    profileId: !isRepresentative && !isLegal ? profileId : null,
    companyId: isLegal && isRepresentative ? profileId : companyId || null,
    representativeProfile: isRepresentative ? representativeProfileId : null,
    createdByRepresentative: isRepresentative || false,
    notifyTypeId: formData.notifyTypeId,
    categoryName: formData.category.label,
    categoryId: formData.category.value,
    supportName: formData.support.label,
    supportId: formData.support.value,
    tin: null,
    companyData: {
      companyType: formData?.companyData?.companyType || null,
      companyName: formData?.companyData?.companyName || null,
      companyEmail: formData?.companyData?.companyEmail || null,
      phone: formData?.companyData?.phone || null,
      tin: formData?.companyData?.tin || null,
    },
    mainData: {
      ssn: null,
      email: null,
      lastName: null,
      firstName: null,
      phoneNumber: null,
      ...formData.mainData,
      documentNumber: null,
      documentValidUntil: null,
      birthdate: dayjs(formData.mainData.birthdate).format(DATE_FORMAT_YYYY_MM_DD),
      actualAddress: formData.mainData.isRegAddressActual
        ? formData.mainData.registrationAddress
        : {
            regionId: formData.mainData.actualAddress.regionId.value,
            region: formData.mainData.actualAddress.regionId.label,
            communityId: formData.mainData.actualAddress.communityId.value,
            community: formData.mainData.actualAddress.communityId.label,
            settlementId: formData.mainData.actualAddress.settlementId.value,
            settlement: formData.mainData.actualAddress.settlementId.label,
            buildingType: formData.mainData.actualAddress.buildingTypeId.label,
            buildingTypeId: formData.mainData.actualAddress.buildingTypeId.value,
            street: formData.mainData.actualAddress.street,
            building: formData.mainData.actualAddress.building,
            postIndex: formData.mainData.actualAddress?.postIndex || null,
            apartment: formData.mainData.actualAddress?.apartment || null,
          },
    },
  };
};
