import { Col, Form, Row } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';

export const AgreemnetData = () => {
  return (
    <FormSectionCard title={'Համաձայնագրի տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Համաձայնագրի համար" name={['condition', 'agreementNumber']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Կնքման ամսաթիվ" name={['condition', 'sealingDate']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Վավերականության սկիզբ" name={['condition', 'validFrom']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Վավերականության ավարտ" name={['condition', 'validTo']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Համաձայնագրի ստեղծման ամսաթիվը" name={['condition', 'createdDate']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Համաձայնագիր ստեղծող մասնագետ" name={['condition', 'approverName']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
