import { Form, Modal } from 'antd';
import dayjs from 'dayjs';
import {
  disabilityApplicantForAdultsOptions,
  disabilityApplicantForChildrenOptions,
} from './components/disability-legal-representetive';
import { useNavigate, useParams } from 'react-router-dom';
import {
  URL_GET_ALL_HEALTHS,
  URL_GET_ALL_OFFICES,
  URL_GET_EVALUATION_WITH_SUBTYPES,
  URL_GET_NOTIFICATION_TYPES,
} from 'api/benefit/constants';
import {
  BODY_POSITION,
  CAREGIVER_CASE,
  COMMUNICATION_DIFFICULTIES,
  familyComposition,
  HOUSE_HOLD_ITEM,
  MOVEMENT_DIFFICULTIES,
  SELF_CARE_DIFFICULTIES,
  getTransportList,
  smokeItemList,
} from './constants';
import { useSaveApplication } from 'api/application/use-save-application';
import { URL_SAVE_DEATH_DISABILITY_APPLICATION, URL_SAVE_DISABILITY_APPLICATION, URL_CREATE_APPEAL_APPLICATION } from 'api/application/constants';
import { GENDER_LIST_USER, PATHS } from 'helpers/constants';
import { errorMessage } from 'helpers/utils';
import { disabilityEvaluationList, disabilityEvaluationListSub } from './components/disability-valuation-place-select';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';

export const useOnSave = (form) => {
  const params = useParams();
  const navigate = useNavigate();
  const assesmentSubtype = Form.useWatch(form, ['assessmentAt', 'subLocation']);
  const { data: hospitals } = useGetFfromBenefit(URL_GET_ALL_HEALTHS, {}, { enabled: assesmentSubtype === 1 });
  const community = Form.useWatch(['applicationForm', 'actualAddress', 'communityId'], { form, preserve: true });

  const location = Form.useWatch(['assessmentAt', 'location'], { form, preserve: true });
  const { data: offices } = useGetFfromBenefit(
    URL_GET_ALL_OFFICES,
    { communityId: community?.value },
    {
      enabled: location === 2 && !!community?.value,
    }
  );
  const { data: disabilityTypes } = useGetFfromBenefit(URL_GET_EVALUATION_WITH_SUBTYPES);
  const { data: dataNotificationType } = useGetFfromBenefit(URL_GET_NOTIFICATION_TYPES);
  let url;

  if (params.typeId === '3') {
    url = URL_CREATE_APPEAL_APPLICATION;
  } else if (params.subtypeId === '10') {
    url = URL_SAVE_DEATH_DISABILITY_APPLICATION;
  } else {
    url = URL_SAVE_DISABILITY_APPLICATION;
  }
  const { mutate: applicationSubmit } = useSaveApplication(url,
    {
      onSuccess: (res) => {
        Modal.success({
          title: 'Շնորհակալություն',
          content: res?.data?.applicationCreateMessage,
          footer: false,
          closable: true,
          onCancel: () => navigate(PATHS.APPLICATIONS),
        });
      },
      onError: errorMessage,
    }
  );

  const subTypeName = disabilityTypes
    .find((item) => item.id === +params.typeId)
    ?.items.find((subItem) => subItem.id === +params.subtypeId)?.name;
  const subTypeInfo = {
    typeId: +params.subtypeId,
    typeName: subTypeName,
  };

  return () => {
    const values = form.getFieldsValue(true);
    let dataToSubmit = {};

    if (params.subtypeId === '10') {
      dataToSubmit = {
        iProvided: true,
        IApproved: true,
        applicationForm: {
          electronicCaseNumber: null,
          firstName: values.applicationForm.firstName,
          lastName: values.applicationForm.lastName,
          patronymicName: values.applicationForm.patronymicName,
          documentType: values.applicationForm.documentType?.label,
          documentNumber: values.applicationForm.documentNumber,
          ssn: values.applicationForm.ssn,
          gender: values.applicationForm.gender === GENDER_LIST_USER.M ? 'M' : 'F',
          phoneNumber: values.applicationForm.phoneNumber,
          email: values.applicationForm.email,
          actualAddress: {
            regionId: values.applicationForm.actualAddress.regionId.value,
            region: values.applicationForm.actualAddress.regionId.label,
            communityId: values.applicationForm.actualAddress.communityId.value,
            community: values.applicationForm.actualAddress.communityId.label,
            settlementId: values.applicationForm.actualAddress.settlementId.value,
            settlement: values.applicationForm.actualAddress.settlementId.label,
            street: values.applicationForm.actualAddress.street,
            building: values.applicationForm.actualAddress.building,
            buildingTypeId: values.applicationForm.actualAddress.buildingType.value,
            buildingType: values.applicationForm.actualAddress.buildingType.label,
            apartment: values.applicationForm.actualAddress.apartment,
            postIndex: values.applicationForm.actualAddress.postIndex,
          },
          registrationAddress: values?.applicationForm?.registrationAddress?.region
            ? {
                region: values.applicationForm.registrationAddress.region,
                community: values.applicationForm.registrationAddress.community,
                settlement: values.applicationForm.registrationAddress.settlement,
                street: values.applicationForm.registrationAddress.street,
                building: values.applicationForm.registrationAddress.building,
                buildingType: values.applicationForm.registrationAddress.buildingType,
                apartment: values.applicationForm.registrationAddress.apartment,
              }
            : null,
          ...(values.applicationForm.legalRepresentative?.ssn
            ? {
                legalRepresentative: {
                  firstName: values.applicationForm.legalRepresentative?.firstName,
                  lastName: values.applicationForm.legalRepresentative?.lastName,
                  patronymicName: values.applicationForm.legalRepresentative?.patronymicName,
                  documentType: values.applicationForm.legalRepresentative?.documentType?.label,
                  documentNumber: values.applicationForm.legalRepresentative?.documentNumber,
                  phoneNumber: values.applicationForm.legalRepresentative?.phoneNumber,
                  email: values.applicationForm.legalRepresentative?.email,
                  ssn: values.applicationForm.legalRepresentative?.ssn,
                  birthDate: dayjs(values.applicationForm.legalRepresentative?.birthDate).format('YYYY-MM-DD'),
                  gender: values.applicationForm.legalRepresentative?.gender === GENDER_LIST_USER.M ? 'M' : 'F',
                },
              }
            : {}),
          fillingApplication: {
            typeId: values.applicationForm.fillingApplication,
            typeName:
              values.applicationForm.fillingApplication === 1
                ? 'Ինձ համար'
                : 'Որպես դիմումատուի օրինական ներկայացուցիչ',
          },
          ...(values.applicationForm.likeLegalRepresentative?.value
            ? {
                likeLegalRepresentative: {
                  typeId: values.applicationForm.likeLegalRepresentative?.value,
                  typeName: values.applicationForm.likeLegalRepresentative?.label,
                },
              }
            : {}),

          applicantData: {
            firstName: values.applicationForm.applicantData.firstName,
            lastName: values.applicationForm.applicantData.lastName,
            patronymicName: values.applicationForm.applicantData.patronymicName,
            ssn: values.applicationForm.applicantData.ssn,
            birthDate: dayjs(values.applicationForm.applicantData?.birthDate).format('YYYY-MM-DD'),
            gender: null,
            registrationAddress: values?.applicationForm?.registrationAddress?.region
              ? {
                  region: values.applicationForm.registrationAddress.region,
                  community: values.applicationForm.registrationAddress.community,
                  settlement: values.applicationForm.registrationAddress.settlement,
                  street: values.applicationForm.registrationAddress.street,
                  building: values.applicationForm.registrationAddress.building,
                  buildingType: values.applicationForm.registrationAddress.buildingType,
                  apartment: values.applicationForm.registrationAddress.apartment,
                }
              : null,
          },
          ...(values.applicationForm.legalRepresentativeInCase
            ? {
                legalRepresentativeInCase: {
                  typeId: values.applicationForm.legalRepresentativeInCase,
                  typeName: (values.applicationForm.likeLegalRepresentative?.value === 1
                    ? disabilityApplicantForChildrenOptions
                    : disabilityApplicantForAdultsOptions
                  ).find((item) => item.value === values.applicationForm.legalRepresentativeInCase)?.label,
                },
              }
            : {}),
          preferGetNotified: {
            typeId: values.applicationForm.preferGetNotified,
            typeName: dataNotificationType.find((item) => item.id === values.applicationForm.preferGetNotified)?.name,
          },
          birthDate: dayjs(values.applicationForm.birthDate).format('YYYY-MM-DD'),
          evaluationType: +params.typeId,
          primaryAssessmentDegree: null,
          doubleAssessmentDegree: null,
          reassessmentForPurposeOfAppeal: null,
          determineDeathRelationShip: null,
          preferReceiveApplications: {
            typeId: values.applicationForm.preferReceiveApplications,
            typeName: dataNotificationType.find((item) => item.id === values.applicationForm.preferReceiveApplications)
              ?.name,
          },
          ...(values.decisionId
            ? {
              decision: {
                decisionId: values?.decisionId,
              },
            }
            : {}),
        },
      };
    } else {
      dataToSubmit = {
        // inputDate: '2024-02-15T17:10:02.919Z',
        iProvided: values.iProvided,
        iApproved: values.IApproved,
        isMedicalDocumentAttached: values.isMedicalDocumentAttached,
        applicationForm: {
          electronicCaseNumber: null,
          // ...values.applicationForm,
          firstName: values.applicationForm.firstName,
          lastName: values.applicationForm.lastName,
          patronymicName: values.applicationForm.patronymicName,
          documentType: values.applicationForm.documentType?.label,
          documentNumber: values.applicationForm.documentNumber,
          ssn: values.applicationForm.ssn,
          gender: values.applicationForm.gender === GENDER_LIST_USER.M ? 'M' : 'F',
          phoneNumber: values.applicationForm.phoneNumber,
          email: values.applicationForm.email,
          actualAddress: {
            regionId: values.applicationForm.actualAddress.regionId.value,
            region: values.applicationForm.actualAddress.regionId.label,
            communityId: values.applicationForm.actualAddress.communityId.value,
            community: values.applicationForm.actualAddress.communityId.label,
            settlementId: values.applicationForm.actualAddress.settlementId.value,
            settlement: values.applicationForm.actualAddress.settlementId.label,
            street: values.applicationForm.actualAddress.street,
            building: values.applicationForm.actualAddress.building,
            buildingTypeId: values.applicationForm.actualAddress?.buildingType?.value,
            buildingType: values.applicationForm.actualAddress?.buildingType?.label,
            apartment: values.applicationForm.actualAddress.apartment,
            postIndex: values.applicationForm.actualAddress.postIndex,
          },
          registrationAddress: values?.applicationForm?.registrationAddress?.region
            ? {
                region: values.applicationForm.registrationAddress.region,
                community: values.applicationForm.registrationAddress.community,
                settlement: values.applicationForm.registrationAddress.settlement,
                street: values.applicationForm.registrationAddress.street,
                building: values.applicationForm.registrationAddress.building,
                buildingType: values.applicationForm.registrationAddress.buildingType,
                apartment: values.applicationForm.registrationAddress.apartment,
              }
            : null,
          ...(values.applicationForm.legalRepresentative?.ssn
            ? {
                legalRepresentative: {
                  firstName: values.applicationForm.legalRepresentative?.firstName,
                  lastName: values.applicationForm.legalRepresentative?.lastName,
                  patronymicName: values.applicationForm.legalRepresentative?.patronymicName,
                  documentType: values.applicationForm.legalRepresentative?.documentType?.label,
                  documentNumber: values.applicationForm.legalRepresentative?.documentNumber,
                  phoneNumber: values.applicationForm.legalRepresentative?.phoneNumber,
                  email: values.applicationForm.legalRepresentative?.email,
                  ssn: values.applicationForm.legalRepresentative?.ssn,
                  birthDate: dayjs(values.applicationForm.legalRepresentative?.birthDate).format('YYYY-MM-DD'),
                  gender: values.applicationForm.legalRepresentative?.gender === GENDER_LIST_USER.M ? 'M' : 'F',
                },
              }
            : {}),
          fillingApplication: {
            typeId: values.applicationForm.fillingApplication,
            typeName:
              values.applicationForm.fillingApplication === 1
                ? 'Ինձ համար'
                : 'Որպես դիմումատուի օրինական ներկայացուցիչ',
          },
          ...(values.applicationForm.likeLegalRepresentative?.value
            ? {
                likeLegalRepresentative: {
                  typeId: values.applicationForm.likeLegalRepresentative?.value,
                  typeName: values.applicationForm.likeLegalRepresentative?.label,
                },
              }
            : {}),
          ...(values.applicationForm.legalRepresentativeInCase
            ? {
                legalRepresentativeInCase: {
                  typeId: values.applicationForm.legalRepresentativeInCase,
                  typeName: (values.applicationForm.likeLegalRepresentative?.value === 1
                    ? disabilityApplicantForChildrenOptions
                    : disabilityApplicantForAdultsOptions
                  ).find((item) => item.value === values.applicationForm.legalRepresentativeInCase)?.label,
                },
              }
            : {}),
          preferGetNotified: {
            typeId: values.applicationForm.preferGetNotified,
            typeName: dataNotificationType.find((item) => item.id === values.applicationForm.preferGetNotified)?.name,
          },
          birthDate: dayjs(values.applicationForm.birthDate).format('YYYY-MM-DD'),
          evaluationType: +params.typeId,
          primaryAssessmentDegree: +params.typeId === 1 ? subTypeInfo : null,
          doubleAssessmentDegree: +params.typeId === 2 ? subTypeInfo : null,
          reassessmentForPurposeOfAppeal: +params.typeId === 3 ? subTypeInfo : null,
          determineDeathRelationShip: +params.typeId === 4 ? subTypeInfo : null,
          allowToExtendPeriodTempDisability: null,
          determineWorkProfAbilityDegree: null,
          changeIndividualServicePlan: null,
          conductAssessmentAtLocation: values.assessmentAt?.location
            ? {
                locationValue: values.assessmentAt.location,
                locationName: disabilityEvaluationList.find((item) => item.value === values.assessmentAt.location)
                  ?.label,
                stayingLocation: values.assessmentAt?.subLocation
                  ? {
                      stayingLocationId: values.assessmentAt?.subLocation,
                      stayingLocationName: disabilityEvaluationListSub.find(
                        (item) => item.id === values.assessmentAt?.subLocation
                      )?.name,
                      medicalInstitutions:
                        values.assessmentAt?.subLocation === 1 && values.assessmentAt?.hospitals
                          ? {
                              medicalInstitutionId: values.assessmentAt?.hospitals,
                              medicalInstitutionName: hospitals?.find(
                                (item) => item.value === values.assessmentAt?.hospitals
                              )?.label,
                            }
                          : null,
                      actualResidence:
                        values.assessmentAt?.subLocation === 2
                          ? {
                              actualResidenceAddress: {
                                regionId: values.applicationForm.actualAddress.regionId.value,
                                region: values.applicationForm.actualAddress.regionId.label,
                                communityId: values.applicationForm.actualAddress.communityId.value,
                                community: values.applicationForm.actualAddress.communityId.label,
                                settlementId: values.applicationForm.actualAddress.settlementId.value,
                                settlement: values.applicationForm.actualAddress.settlementId.label,
                                street: values.applicationForm.actualAddress.street,
                                building: values.applicationForm.actualAddress.building,
                                buildingTypeId: values.applicationForm.actualAddress?.buildingType?.value,
                                buildingType: values.applicationForm.actualAddress?.buildingType?.label,
                                apartment: values.applicationForm.actualAddress.apartment,
                                postIndex: values.applicationForm.actualAddress.postIndex,
                              },
                            }
                          : null,
                    }
                  : null,
                ussCenter:
                  values.assessmentAt?.location === 2 && values.assessmentAt?.offices
                    ? {
                        ussCenterId: values.assessmentAt?.offices,
                        ussCenterName: offices.find((item) => item.id === values.assessmentAt?.offices)?.name,
                      }
                    : null,
              }
            : null,
          preferReceiveApplications: {
            typeId: values.applicationForm.preferReceiveApplications,
            typeName: dataNotificationType.find((item) => item.id === values.applicationForm.preferReceiveApplications)
              ?.name,
          },
          ...(values.decisionId
            ? {
              decision: {
                decisionId: values?.decisionId,
              },
            }
            : {}),
        },
        selfAssessment: {
          maritalTypeOver18: values.selfAssessment?.maritalTypeOver18
            ? {
                typeId: values.selfAssessment.maritalTypeOver18.typeId?.value,
                typeName: values.selfAssessment.maritalTypeOver18.typeId?.label,
              }
            : {},
          maritalTypeUnder18: values.selfAssessment?.maritalTypeUnder18
            ? {
                typeId: values.selfAssessment.maritalTypeUnder18.typeId?.value,
                typeName: values.selfAssessment.maritalTypeUnder18.typeId?.label,
                commentForOther: values.selfAssessment.maritalTypeUnder18?.commentForOther,
              }
            : {},
          family: values.selfAssessment?.family
            ? {
                familyMembers: values.selfAssessment.family.familyMembers?.map((memberItem) => ({
                  typeId: memberItem,
                  typeName:
                    memberItem === 10
                      ? values.selfAssessment?.family?.commentForOther
                      : familyComposition(values.applicationForm.fillingApplication).find(
                          (item) => item.value === memberItem
                        )?.label,
                })),
              }
            : {},
          guardian: values.selfAssessment?.guardian
            ? {
                have: values.selfAssessment.guardian?.have?.value,
                guardianType: {
                  typeId: values.selfAssessment.guardian.guardianType?.typeId,
                  typeName:
                    CAREGIVER_CASE.find((item) => item.value === values.selfAssessment.guardian.guardianType?.typeId)
                      ?.name || '',
                },
              }
            : {},
          haveChildren: values.selfAssessment?.haveChildren
            ? {
                have: values.selfAssessment.haveChildren.value,
              }
            : {},
          needInfo: 'string', //????????????
          education: {
            typeId: values.selfAssessment?.education?.typeId?.value,
            typeName: values.selfAssessment?.education?.typeId?.label || '',
          },
          profession: values.selfAssessment?.profession
            ? {
                have: values.selfAssessment.profession.have?.value === 1,
                whatKindOf: values.selfAssessment.profession?.whatKindOf,
              }
            : {},
          occupation: {
            typeId: values.selfAssessment?.occupation.typeId?.value,
            typeName: values.selfAssessment?.occupation.typeId?.label || '',
          },
          numberOfYearWorked: values.selfAssessment?.numberOfYearWorked || 0,
          workPosition: values.selfAssessment?.workPosition,
        },
        questionnaire: {
          selfServiceDifficulties: values.questionnaire?.selfServiceDifficulties
            ? {
                have: values.questionnaire.selfServiceDifficulties?.have,
                difficultiesType: values.questionnaire.selfServiceDifficulties?.difficultiesType?.map((mainItem) => ({
                  typeId: mainItem,
                  typeName: SELF_CARE_DIFFICULTIES.find(
                    (parent) => parent.value === values.questionnaire.selfServiceDifficulties?.have
                  ).options.find((item) => item.value === mainItem)?.label,
                })),
              }
            : {},
          houseHoldDifficulties: values.questionnaire?.houseHoldDifficulties
            ? {
                have: values.questionnaire.houseHoldDifficulties?.have,
                difficultiesType: values.questionnaire.houseHoldDifficulties?.difficultiesType?.map((mainItem) => ({
                  typeId: mainItem,
                  typeName: HOUSE_HOLD_ITEM.find(
                    (parent) => parent.value === values.questionnaire.houseHoldDifficulties?.have
                  ).options.find((item) => item.value === mainItem)?.label,
                })),
              }
            : {},
          movementDifficulties: values.questionnaire?.movementDifficulties
            ? {
                have: values.questionnaire.movementDifficulties?.have,
                difficultiesType: values.questionnaire.movementDifficulties?.difficultiesType?.map((mainItem) => ({
                  typeId: mainItem,
                  typeName: MOVEMENT_DIFFICULTIES.find(
                    (parent) => parent.value === values.questionnaire.movementDifficulties?.have
                  ).options.find((item) => item.value === mainItem)?.label,
                })),
              }
            : {},
          changePositionDifficulties: values.questionnaire?.changePositionDifficulties
            ? {
                have: values.questionnaire.changePositionDifficulties?.have,
                difficultiesType: values.questionnaire.changePositionDifficulties?.difficultiesType?.map(
                  (mainItem) => ({
                    typeId: mainItem,
                    typeName: BODY_POSITION.find(
                      (parent) => parent.value === values.questionnaire.changePositionDifficulties?.have
                    ).options.find((item) => item.value === mainItem)?.label,
                  })
                ),
              }
            : {},
          useHandsDifficulties: values.questionnaire?.useHandsDifficulties,
          speechDifficulties: values.questionnaire?.speechDifficulties,
          hearingDifficulties: values.questionnaire?.hearingDifficulties,
          visionDifficulties: values.questionnaire?.visionDifficulties,
          communicationDifficulties: values.questionnaire?.communicationDifficulties
            ? {
                have: values.questionnaire.communicationDifficulties?.have,
                difficultiesType: values.questionnaire.communicationDifficulties?.difficultiesType?.map((mainItem) => ({
                  typeId: mainItem,
                  typeName: COMMUNICATION_DIFFICULTIES.find(
                    (parent) => parent.value === values.questionnaire.communicationDifficulties?.have
                  ).options.find((item) => item.value === mainItem)?.label,
                })),
              }
            : {},
          transportDifficulties: values.questionnaire?.transportDifficulties
            ? {
                have: values.questionnaire.transportDifficulties?.have,
                difficultiesType: values.questionnaire.transportDifficulties?.difficultiesType?.map((mainItem) => ({
                  typeId: mainItem,
                  typeName: getTransportList(values.applicationForm?.fillingApplication)
                    .find((parent) => parent.value === values.questionnaire.transportDifficulties?.have)
                    .options.find((item) => item.value === mainItem)?.label,
                })),
              }
            : {},
          // entertainmentAttendingDifficulties: {
          //   have: values.questionnaire.entertainmentAttendingDifficulties.have,
          //   difficultiesType: [
          //     {
          //       typeId: values.questionnaire.entertainmentAttendingDifficulties.difficultiesType,
          //       typeName: SELF_CARE_DIFFICULTIES.find(
          //         (parent) => parent.value === values.questionnaire.entertainmentAttendingDifficulties.have
          //       ).options.find(
          //         (item) => item.value === values.questionnaire.entertainmentAttendingDifficulties.difficultiesType
          //       ),
          //     },
          //   ],
          // },
          biometrics: {
            height: values.questionnaire?.biometrics?.height.toString() || '',
            weight: values.questionnaire?.biometrics?.weight.toString() || '',
          },
          areYouSmoker:
            values.questionnaire?.areYouSmoker === true || values.questionnaire?.areYouSmoker === false
              ? {
                  ...values.questionnaire.areYouSmoker,
                  ...(values.questionnaire.areYouSmoker?.ifNotSmoker
                    ? {
                        ifNotSmoker: {
                          typeId: values.questionnaire.areYouSmoker?.ifNotSmoker,
                          typeName: smokeItemList(values.applicationForm.fillingApplication).find(
                            (item) => item.value === values.questionnaire.areYouSmoker?.ifNotSmoker
                          )?.label,
                        },
                      }
                    : {}),
                }
              : {},
        },
        // deviceRequirement: values.deviceRequirement,
        deviceRequirement: {
          useListeningDevice: values?.deviceRequirement?.useListeningDevice,
          useWheelchairs: values?.deviceRequirement?.useWheelchairs,
          needToAdoptBuilding:
            values?.deviceRequirement?.needToAdoptBuilding?.need === true ||
            values?.deviceRequirement?.needToAdoptBuilding?.need === false
              ? values?.deviceRequirement?.needToAdoptBuilding
              : null,
          needPersonalAssistance: values?.deviceRequirement?.needPersonalAssistance,
          getOutOfHouseSupport: null,
          attitudeOfPeople: values?.deviceRequirement?.attitudeOfPeople,
          doYouStudy: values?.deviceRequirement?.doYouStudy,
          goInstituteEveryDay: values?.deviceRequirement?.goInstituteEveryDay,
          needToAdaptEduMaterial: values?.deviceRequirement?.needToAdaptEduMaterial,
          needHelpInAttendingInstitute:
            values?.deviceRequirement?.needHelpInAttendingInstitute?.need === true ||
            values?.deviceRequirement?.needHelpInAttendingInstitute?.need === false
              ? values?.deviceRequirement?.needHelpInAttendingInstitute
              : null,
          doYouWork: values?.deviceRequirement?.doYouWork,
          needToSetupWorkEnv:
            values?.deviceRequirement?.needToSetupWorkEnv?.need === true ||
            values?.deviceRequirement?.needToSetupWorkEnv?.need === false
              ? values?.deviceRequirement?.needToSetupWorkEnv
              : null,
          haveWorkingHinders:
            values?.deviceRequirement?.haveWorkingHinders?.have === true ||
            values?.deviceRequirement?.haveWorkingHinders?.have === false
              ? values?.deviceRequirement?.haveWorkingHinders
              : null,
          needVocationalTraining:
            values?.deviceRequirement?.needVocationalTraining === true ||
            values?.deviceRequirement?.needVocationalTraining === false
              ? values?.deviceRequirement?.needVocationalTraining
              : null,
          financiallyIndependent: values?.deviceRequirement?.financiallyIndependent === true ? true : false,
        }
      };
    }
    applicationSubmit(dataToSubmit);
  };
};
