import { Divider, Form, Radio, Select } from 'antd';
import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { DisabilityLegalPersonData } from './disability-legal-person-data';

export const disabilityApplicantForOptions = [
  { label: 'Երեխաների դեպքում', value: 1 },
  { label: 'Մեծահասակների դեպքում', value: 2 },
];

export const disabilityApplicantForChildrenOptions = [
  { id: 1, label: 'ծնող', value: 1 },
  { id: 2, label: 'որդեգրող', value: 2 },
  { id: 3, label: 'խնամակալ', value: 3 },
  { id: 4, label: 'խնամատար', value: 4 },
  { id: 5, label: 'հոգաբարձու', value: 5 },
];

export const disabilityApplicantForAdultsOptions = [
  { id: 1, label: 'խնամակալ', value: 1 },
  { id: 2, label: 'խնամատար', value: 2 },
  { id: 3, label: 'հոգաբարձու', value: 3 },
  { id: 4, label: 'փաստաբան', value: 4 },
];

export const DisabilityLegalRepresentetive = () => {
  const applicantFor = Form.useWatch(['applicationForm', 'likeLegalRepresentativeName']);
  const form = Form.useFormInstance();
  return (
    <VerticalSpace>
      <FormItem name={['applicationForm', 'likeLegalRepresentativeName']} rules={[{ required: true }]}>
        <Select options={disabilityApplicantForOptions} placeholder="Ընտրել" labelInValue
          onChange={() => {
            form.setFieldValue(['applicationForm', 'legalRepresentativeInCase'], undefined);
          }}

        ></Select>
      </FormItem>
      {applicantFor && (
        <FormItem name={['applicationForm', 'legalRepresentativeInCase']} rules={[{ required: true }]}>
          <Radio.Group
            options={applicantFor?.value === 1 ? disabilityApplicantForChildrenOptions : disabilityApplicantForAdultsOptions}
            style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          />
        </FormItem>
      )}
      <Divider />
      <DisabilityLegalPersonData />
    </VerticalSpace>
  );
};
