import { Form } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { VerticalSpace } from 'components/space/vertical-space';

import { Checkbox } from 'components/checkbox';
import React from 'react';
import { TextArea } from 'components/input';
import { support, supportOptions } from '../constants';

export const SecondStep = () => {
  const checklist = Form.useWatch([support], {
    preserve: true,
  });

  return (
    <FormSectionCard title={'Աջակցության տվյալներ'}>
      <VerticalSpace>
        <Form.Item name={support}>
          {supportOptions.map((option) => {
            return (
              <React.Fragment key={option.id}>
                <Form.Item name={[support, option.fieldName]} valuePropName="checked" style={{ margin: 0 }}>
                  <Checkbox value={option.id}>{option.label}</Checkbox>
                </Form.Item>
                {checklist?.[option.fieldName] &&
                  option?.subItem &&
                  React.cloneElement(option.subItem, { parentFormItem: [support, option.fieldName] })}
                {checklist?.[option.fieldName] && option?.hasComment && (
                  <Form.Item name={[support, option.fieldName, 'other']} rules={[{ required: true }]}>
                    <TextArea />
                  </Form.Item>
                )}
              </React.Fragment>
            );
          })}
        </Form.Item>
      </VerticalSpace>
    </FormSectionCard>
  );
};
