import { Tabs } from 'antd';
import { InvolvedApplications } from './components/involved-applications';
import { LegalApplications } from './components/legal-applications';
import { MyApplication } from './components/my-application';
import { APPLICATION_TYPES, SERVICE_TYPES } from './constants';
import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { URL_GET_PERSONAL_SERVICE_APPLICATIONS } from 'api/application/constants';
import { useAuth } from 'context/auth-context';

export const Applications = () => {
  const {
    user: { isLegal, companyId, profileId },
  } = useAuth();
  const [activeTab, setActiveTab] = useState();
  const [activeService, setActiveService] = useState();
  const params = useParams();

  const onChange = (val) => {
    setActiveTab(val);
  };

  useEffect(() => {
    params.type && setActiveTab(+params.type);
  }, [params.type]);

  const serviceItems = [
    {
      key: APPLICATION_TYPES.MY_APPLICATION,
      label: `Իմ դիմումները`,
      children: +params.type === APPLICATION_TYPES.MY_APPLICATION ? <Outlet /> : <MyApplication />,
    },
    {
      key: APPLICATION_TYPES.OTHER_APPLICATION,
      label: `Այլ դիմումներում ներգրավվածություն`,
      children: +params.type === APPLICATION_TYPES.OTHER_APPLICATION ? <Outlet /> : <InvolvedApplications />,
    },
    {
      key: APPLICATION_TYPES.LEGAL_APPLICATION,
      label: `Օրինական ներկայացուցչի դիմումներ`,
      children: +params.type === APPLICATION_TYPES.LEGAL_APPLICATION ? <Outlet /> : <LegalApplications />,
    },
  ];

  const offeredItems = [
    {
      key: APPLICATION_TYPES.DONOR_APPLICATION,
      label: `Իմ դիմումները`,
      children: <MyApplication url={URL_GET_PERSONAL_SERVICE_APPLICATIONS} />,
    },
  ];

  const serviceTypes = [
    {
      key: SERVICE_TYPES.WITHDRAWAL_SERVICES,
      label: 'Ծառայության ստացման դիմումներ',
    },
    {
      key: SERVICE_TYPES.OFFERED_SERVICES,
      label: 'Ծառայության տրամադրման դիմումներ',
    },
  ];

  const onServiceChange = (val) => {
    setActiveService(val);
    if (val === 1) {
      setActiveTab(1);
    } else {
      setActiveTab(4);
    }
  };

  return (
    <>
      {isLegal ? (
        <MyApplication
          url={`${URL_GET_PERSONAL_SERVICE_APPLICATIONS}?companyId=${companyId || profileId}`}
        />
      ) : (
        <>
          <Tabs
            defaultActiveKey={1}
            activeKey={activeService}
            items={serviceTypes}
            onChange={onServiceChange}
            destroyInactiveTabPane={true}
          />
          <Tabs
            defaultActiveKey={1}
            activeKey={activeTab}
            items={activeService === SERVICE_TYPES.OFFERED_SERVICES ? offeredItems : serviceItems}
            onChange={onChange}
            destroyInactiveTabPane={true}
          />
        </>
      )}
    </>
  );
};
