import { Col, Input, Row } from 'antd';
import { FormItem } from 'components/form/form-item';
import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';

export const DisabilityPersonRegistrationAddress = () => {
  return (
    <VerticalSpace>
      <Title level={2} color="#0B847F">
        Հաշվառման հասցե
      </Title>
      <VerticalSpace size="small">
        <Row justify="space-between">
          <Col span={7}>
            <FormItem name={['applicationForm', 'registrationAddress', 'region']} label="Մարզ">
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem name={['applicationForm', 'registrationAddress', 'community']} label="Համայնք">
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem name={['applicationForm', 'registrationAddress', 'settlement']} label="Բնակավայր">
              <Input disabled />
            </FormItem>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={7}>
            <FormItem name={['applicationForm', 'registrationAddress', 'street']} label="Փողոց">
              <Input disabled />
            </FormItem>
          </Col>

          <Col span={7}>
            <FormItem name={['applicationForm', 'registrationAddress', 'buildingType']} label="Կառույցի տեսակ">
              <Input disabled />
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem name={['applicationForm', 'registrationAddress', 'building']} label="Կառույցի համար">
              <Input disabled />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <FormItem name={['applicationForm', 'registrationAddress', 'apartment']} label="Բնակարան">
              <Input disabled />
            </FormItem>
          </Col>
        </Row>
      </VerticalSpace>
    </VerticalSpace>
  );
};
