import dayjs from 'dayjs';
import {
  processFoodObject,
  transformClothesData,
  transformShoesData,
  transformSwaddlersData,
} from 'pages/rapid-need/utils';

export const modifyViewValues = (data) => {
  const formData = data.data;
  return {
    applicationData: {
      status: formData.status,
      statusChangeDate: formData.statusChangeDate,
      num: formData.num,
      numDate: formData.numDate,
      notes: formData?.notes,
      rejectionReason: formData?.rejectionReason,
    },
    programDetails: {
      ...formData.programDetails,
      agreementDate: formData.programDetails.agreementDate,
      agreementDeadline: formData.programDetails.agreementDeadline,
      programStartDate: dayjs(formData.programDetails.programStartDate),
      programEndDate: dayjs(formData.programDetails.programEndDate),
    },
    support: {
      ...formData.support,
      food: processFoodObject(formData.support.food),
      care: formData.support?.care?.inplace || formData.support?.care?.careCenter ? formData.support?.care : null,

      clothes: formData.support?.clothes?.length ? transformClothesData(formData.support?.clothes) : null,
      shoes: formData.support?.shoes?.length ? transformShoesData(formData.support?.shoes) : null,
      swaddlers: formData.support?.swaddlers?.length ? transformSwaddlersData(formData.support?.swaddlers) : null,
      supportInstruments: formData?.support?.supportInstruments?.length
        ? {
            selections: formData?.support?.supportInstruments,
          }
        : null,
      other: formData.support?.other
        ? {
            other: formData.support.other,
          }
        : null,
    },
    contactDetails: {
      ...formData?.contactDetails,
    },
  };
};
