import { Input as InputComponent } from 'antd';
import { changeHeight, placeholderSize } from '../../helpers/styles';
import styled, { css } from 'styled-components';

export const Input = styled((props) => (
  <InputComponent
    // onPaste={(e) => {
    //   e.preventDefault();
    //   return false;
    // }}
    // onCopy={(e) => {
    //   e.preventDefault();
    //   return false;
    // }}
    {...props}
  />
))`
  ${(props) =>
    !props.size
      ? css`
          /* ${changeHeight} */
        `
      : ''}

  ::placeholder {
    ${placeholderSize}
  }

  &:disabled {
    color: #091024;
  }
`;

export const FormInput = (props) => {
  return <Input {...props} />;
};

export { TextArea } from './text-area';
