import { useOutletContext, useParams } from 'react-router-dom';
import { URL_EDIT_INCOMPLETE_APPLICATION } from '../../api/application/constants';
import { usePostNoMutate } from '../../api/application/use-post-no-mutate';
import { useApplication } from '../../context/applicaton-context';
import { Form } from '../../components/form/form';
import dayjs from 'dayjs';
import { useAuth } from '../../context/auth-context';
import { setFormCitizenData } from '../../components/form/application/utils';
import { files } from 'pages/application/constants';
import { Modal } from 'antd';

export const useGetApplicationEditData = () => {
  const { user } = useAuth();
  const params = useParams();
  const context = useOutletContext();
  const form = Form.useFormInstance();
  const { step, addCityzenData } = useApplication();

  const response = usePostNoMutate(
    URL_EDIT_INCOMPLETE_APPLICATION,
    { step: step + 1, tempApplicationId: +params.id, profileId: user.profileId || null },
    {
      enabled: !!context.isEdit,
      cacheTime: 0,
      select: (data) => {
        return data;
      },
      onSuccess: ({ data }) => {
        if (step === 0) {
          if (data.isProfileDataChanged === true) {
            Modal.info({
              title: 'Ուշադրություն',
              content: 'Օրինական ներկայացուցչի տվյալներում տեղի է ունեցել փոփոխություն',
              footer: false,
              closable: true,
            });
          }

          form.setFieldsValue({
            profileId: data.profileId,
            profileData: data.profileData
              ? {
                  ...data.profileData,
                  legalRepresentative: {
                    ...data.profileData.legalRepresentative,
                    birthdate: dayjs(data.profileData.legalRepresentative.birthdate, 'YYYY-MM-DD'),
                    fname: data.profileData.legalRepresentative.firstName,
                    lname: data.profileData.legalRepresentative.lastName,
                  },
                  legalFilesChecksum: data.profileData?.legalFilesChecksum?.map((item, index) => ({
                    uid: item,
                    name: `Տեղեկանք ${index + 1}`,
                    status: 'done',
                    response: {
                      fileChecksum: item,
                    },
                  })),
                }
              : null,
            fillingApplication: data.profileData ? 2 : 1,
            tempApplicationId: data.tempApplicationId,
          });
        } else if (step === 1) {
          if (data.isCitizenDataChanged === true) {
            Modal.info({
              title: 'Ուշադրություն',
              content: 'Դիմումատուի անձնական տվյալներում տեղի է ունեցել փոփոխություն',
              footer: false,
              closable: true,
            });
          }
          setFormCitizenData(form, data.citizenData, data.documents);
          if (data.citizenData) {
            addCityzenData({
              ...data.citizenData,
              citizenInfo: {
                ...data.citizenData.citizenInfo,
                documents: data.documents,
              },
              [files]: {
                animalFilesChecksum: data.citizenData.animalFilesChecksum?.map((item, index) => ({
                  uid: item,
                  name: `Տեղեկանք ${index + 1}`,
                  status: 'done',
                  response: {
                    fileChecksum: item,
                  },
                })),
                militaryServiceFilesChecksum: data.citizenData.militaryServiceFilesChecksum?.map((item, index) => ({
                  uid: item,
                  name: `Տեղեկանք ${index + 1}`,
                  status: 'done',
                  response: {
                    fileChecksum: item,
                  },
                })),
                studyFilesChecksum: data.citizenData.studyFilesChecksum?.map((item, index) => ({
                  uid: item,
                  name: `Տեղեկանք ${index + 1}`,
                  status: 'done',
                  response: {
                    fileChecksum: item,
                  },
                })),
                residenceFilesChecksum: data.citizenData.residenceFilesChecksum?.map((item, index) => ({
                  uid: item,
                  name: `Տեղեկանք ${index + 1}`,
                  status: 'done',
                  response: {
                    fileChecksum: item,
                  },
                })),
              },
            });
          }
        } else if (step === 2) {
          if (data.isFamilyDataChanged === true) {
            const familyRemoved = {
              __html: `Ձեր դիմումից հեռացվել են հետևյալ մարդկանց տվյալները <br /> ${data.familyMembersRemoved?.replaceAll(
                '/n',
                '<br />'
              )}`,
            };

            Modal.info({
              title: 'Ուշադրություն',
              content: <div dangerouslySetInnerHTML={familyRemoved} />,
              footer: false,
              closable: true,
            });
          }
          form.setFieldsValue({
            familyData: data.familyData
              ? {
                  ...data.familyData,
                  marriageFilesChecksum: data.familyData.marriageFilesChecksum?.map((item, index) => ({
                    uid: item,
                    name: `Տեղեկանք ${index + 1}`,
                    status: 'done',
                    response: {
                      fileChecksum: item,
                    },
                  })),
                  familyMembers: [
                    ...data.familyData.familyMembers.map((item) => ({
                      ...item,
                      ...item.citizenInfo,
                      birthdate: dayjs(item.citizenInfo.birthdate),
                      fname: item.citizenInfo.firstName,
                      lname: item.citizenInfo.lastName,
                      [files]: {
                        militaryServiceFilesChecksum: item.militaryServiceFilesChecksum?.map((itemMil, index) => ({
                          uid: itemMil,
                          name: `Տեղեկանք ${index + 1}`,
                          status: 'done',
                          response: {
                            fileChecksum: itemMil,
                          },
                        })),
                        studyFilesChecksum: item.studyFilesChecksum?.map((itemSt, index) => ({
                          uid: itemSt,
                          name: `Տեղեկանք ${index + 1}`,
                          status: 'done',
                          response: {
                            fileChecksum: itemSt,
                          },
                        })),
                      },
                    })),
                  ],
                }
              : {},
          });
        } else if (step === 3) {
          form.setFieldsValue({
            isNotifyBySMS: [data.applicationProperties?.isNotifyBySMS],
            bankId: data.applicationProperties?.bankId,
            paymentMethodId: data.applicationProperties?.paymentMethodId,
          });
        }
      },
    }
  );

  return response;
};
