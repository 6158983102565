import { UserLogin } from '../../../components/form/user/user-login';
import { SignIn } from '../../../components/form/sign-in';
import { formItemPropSignIn } from '../../application/constants';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { SecondaryText } from '../../../components/typography';
import { RegistrationCard } from '../../../components/card/registration-card';
import { useLocation } from 'react-router-dom';

export const SignInApp = () => {
  const location = useLocation();

  const [activeTabKey1, setActiveTabKey1] = useState(
    location?.state?.loginOrReg === undefined ? 1 : location?.state?.loginOrReg
  );

  const items = [
    {
      key: 1,
      tab: <SecondaryText>Մուտք</SecondaryText>,
    },
    {
      key: 2,
      tab: <SecondaryText>Գրանցվել</SecondaryText>,
    },
  ];

  const contentList = {
    1: <UserLogin />,
    2: (
      <SignIn
        formItemProp={formItemPropSignIn}
        locationResponse={location?.state?.headInfo}
        loginAccess={location?.state?.loginData}
      />
    ),
  };

  useEffect(() => {
    if (!location?.state?.headInfo) {
      localStorage.setItem('dataParam', location?.state === null ? 0 : location?.state?.param);
    }
  }, [location]);

  return (
    <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
      <Col lg={activeTabKey1 === 1 ? 7 : 12}>
        <RegistrationCard
          tabList={items}
          onTabChange={(key) => {
            setActiveTabKey1(key);
          }}
        >
          {contentList[activeTabKey1]}
        </RegistrationCard>
      </Col>
    </Row>
  );
};
