import styled from 'styled-components';
import { Steps } from 'antd';

export const RapidNeedSteps = styled(Steps)`
  && {
    width: 80%;
    margin: 0 auto;
  }
  .ant-steps-item-title {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .ant-steps-item-container {
    padding: 40px 0;
    /* pointer-events: none; */ //set clickable steps
    @media (max-width: 768px) {
      padding: 10px 0;
    }
  }

  @media (max-width: 1025px) {
    && {
      width: 100%;
      font-size: 16px;
    }
    .ant-steps-item-tail {
      display: none !important;
    }
  }
`;
