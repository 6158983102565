import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Card, Row, Col, Form, Divider, Button } from 'antd';
import { RegionsSelect } from 'components/select/regions-select';
import { NeedsTypesSelect } from 'components/select/needs-types-select';
import { FormItem } from 'components/form/form-item';
import { CardList } from './card-list';
import { usePostNoMutate } from 'api/urrp/use-post-no-mutate';
import { URL_GET_NEEDS_LOCATIONS } from 'api/urrp/constants';
import { useBreakpoint } from 'hooks/use-breakpoint';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import armeniaGeoJson from './armenia-geojson.json';
import { VerticalSpace } from 'components/space/vertical-space';

const defaultIcon = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIconRetina,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const FitToBounds = () => {
  const breakpoint = useBreakpoint();
  const map = useMap();
  useEffect(() => {
    const bounds = breakpoint.lg
      ? [
        [38.8416, 44.6828],
        [41.3007, 46.5052],
      ]
      : [
        [38.8416, 43.5828],
        [41.3007, 46.5052],
      ];
    map.fitBounds(bounds);
  }, [breakpoint.lg, map]);

  return null;
};

export const Maps = () => {
  const breakpoint = useBreakpoint();

  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [selectedCommunityId, setSelectedCommunityId] = useState(null);

  const needsTypesId = Form.useWatch('needsTypesId', { form, preserve: true });
  const regionsId = Form.useWatch('regionsId', { form, preserve: true });

  useEffect(() => {
    setSelectedCommunityId(null);
  }, [needsTypesId, regionsId]);

  usePostNoMutate(
    URL_GET_NEEDS_LOCATIONS,
    {
      regions: regionsId?.map((item) => item.value) || [],
      needs: needsTypesId?.map((item) => item.value) || [],
    },
    {
      enabled: true,
      onSuccess: (data) => {
        const fetchedLocations = data.map((item) => ({
          name: item.community,
          coordinates: [item.coordinates.latitude, item.coordinates.longitude],
          communityId: item.communityId,
        }));
        setLocations(fetchedLocations);
      },
    }
  );

  const handleReset = () => {
    form.resetFields();
    setSelectedCommunityId(null);
  };

  return (
    <VerticalSpace>
      <Row gutter={16} style={breakpoint.lg ? { minHeight: '70vh' } : {}}>
        <Col xs={24} lg={8}>
          <Card>
            <Form form={form} layout="vertical">
              <FormItem label="Օգնության տեսակը" name="needsTypesId">
                <NeedsTypesSelect placeholder="Ընտրել" labelInValue mode="multiple" showSearch={false} />
              </FormItem>
              <Divider style={{ margin: '15px 0' }} />
              <FormItem label="Մարզը" name="regionsId">
                <RegionsSelect placeholder="Ընտրել" labelInValue mode="multiple" showSearch={false} />
              </FormItem>
              <Divider style={{ margin: '15px 0 9px' }} />
              <Button type="text" onClick={handleReset} style={{ padding: 0, color: '#000' }}>
                Մաքրել ֆիլտրերը
              </Button>
            </Form>
            {breakpoint.lg && <CardList communityId={selectedCommunityId} />}
          </Card>
        </Col>
        <Col xs={24} lg={16}>
          <MapContainer
            minZoom={breakpoint.lg ? 8.5 : 6}
            maxZoom={18}
            zoom={breakpoint.lg ? 1 : 6}
            panInsideBounds={armeniaGeoJson}
            style={{ height: '100%', width: '100%', minHeight: '300px', zIndex: '0' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
            />
            <FitToBounds />
            <GeoJSON data={armeniaGeoJson} style={{ weight: 0, fillColor: 'blue', opacity: 0, fillOpacity: 0.1 }} />
            {locations.map((location, index) => (
              <Marker
                key={index}
                position={location.coordinates}
                icon={defaultIcon}
                eventHandlers={{
                  click: () => {
                    setSelectedCommunityId(location.communityId);
                  },
                }}
              >
                <Popup>{location.name}</Popup>
              </Marker>
            ))}
          </MapContainer>
        </Col>
      </Row>
      {!breakpoint.lg && <CardList communityId={selectedCommunityId} />}
    </VerticalSpace>
  );
};
