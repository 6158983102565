import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { FileDoneOutlined } from '@ant-design/icons';
import { DisabilitySubHeader } from './disability-sub-header';
import backgroundImage from '../images/background.png';
import styled, { css } from 'styled-components';

export const BackgroundLayout = styled(Layout)`
  ${(props) =>
    props.inner
      ? css`
          background: transparent;
        `
      : css`
          min-height: 100vh;
          height: 100%;
          background-image: url(${backgroundImage});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #ffffff;
        `}
`;

export const DisabilityLayout = () => {
  return (
    <Layout style={{ background: 'transparent' }}>
      <DisabilitySubHeader
        icon={<FileDoneOutlined style={{ fontSize: '30px', color: '#fff' }} />}
        text="ՖՈՒՆԿՑԻՈՆԱԼՈՒԹՅԱՆ ԳՆԱՀԱՏՈՒՄ"
      />
      <BackgroundLayout>
        <Outlet />
      </BackgroundLayout>
    </Layout>
  );
};
