import { Col, Form, Row } from 'antd';

import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';
import { PhoneNumber } from 'components/input/phone-number';

import { phonePattern } from 'helpers/constants';
import { useEffect } from 'react';

export const DIPersonContactForm = ({ disabled, email, phoneNumber, isView }) => {
  const form = useFormInstance();

  useEffect(() => {
    if (disabled && !isView && email) {
      form.setFields([
        {
          name: ['personMainData', 'email'],
          value: email,
        },
      ]);
    }
  }, [disabled, email, form, isView, phoneNumber]);

  return (
    <FormSectionCard title={'Կոնտակտային տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Հեռախոսահամար'}
            rules={[
              {
                required: true,
                pattern: phonePattern,
                message: 'Սխալ հեռախոսի ֆորմատ, պետք է լինի +374XXXXXXXX ֆորմատով',
              },
            ]}
            name={['personMainData', 'phoneNumber']}
            initialValue={phoneNumber}
          >
            <PhoneNumber />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={'Էլ․ փոստի հասցե'} rules={[{ required: true }]} name={['personMainData', 'email']}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
