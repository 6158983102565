import { Radio, Space } from 'antd';
import { FormSectionCard } from '../../../card/form-section-card';
import { FormItem } from '../../form-item';
import { SecondaryText } from '../../../typography';
import { COLORS, DOCUMENT_TYPES } from '../../../../helpers/constants';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';
import { useApplication } from 'context/applicaton-context';
import { Form } from 'components/form/form';

export const MariageCertForm = ({ parent = [] }) => {
  const { submittedData, cityzenData } = useApplication();
  const hasMariageCertBefore2014 = Form.useWatch([...parent, 'hasMarriageCertificateBefore2014']);

  return (
    <FormSectionCard title="Ընտանիքում կան 2014թ. առաջ ամուսնացած անձինք">
      <FormItem rules={[{ required: true }]} name={[...parent, 'hasMarriageCertificateBefore2014']}>
        <Radio.Group>
          <Space>
            <Radio value={true}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Այո</SecondaryText>
            </Radio>
            <Radio value={false}>
              <SecondaryText color={COLORS.PRIMARY.BLUE}>Ոչ</SecondaryText>
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      {hasMariageCertBefore2014 === true && (
        <UploadApplicationDocument
          tempApplicationId={submittedData.tempApplicationId}
          checksumName={[...parent, 'marriageFilesChecksum']}
          attachedDocumentType={DOCUMENT_TYPES.MARIAGE_BEFORE_2014}
          viewMode={!cityzenData?.citizenInfo}
          userData={cityzenData?.citizenInfo}
          infoText="Խնդրում ենք կցել ամուսնության վկայականը։"
        />
      )}
    </FormSectionCard>
  );
};
