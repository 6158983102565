import { Col, Row } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { FormItem } from 'components/form/form-item';
import { BanksSelect } from 'components/select/banks-selec';
import { PaymentMethodsSelect } from 'components/select/payment-methods-selec';
import { VerticalSpace } from 'components/space/vertical-space';
import { PAY_METHOD } from 'helpers/constants';
import { useState } from 'react';

export const BankData = () => {
  const [isBankRequired, setBankRequired] = useState(true);

  return (
    <VerticalSpace>
      <FormSectionCard title="Բանկի ընտրություն">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Վճարման մեթոդ" name="paymentMethodId" rules={[{ required: true }]}>
              <PaymentMethodsSelect
                onChange={(value) => {
                  setBankRequired(value === PAY_METHOD.BANK);
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Նախընտրելի բանկը" name="bankId" rules={[{ required: isBankRequired }]}>
              <BanksSelect />
            </FormItem>
          </Col>
        </Row>
      </FormSectionCard>
    </VerticalSpace>
  );
};
