
import { Tabs } from 'antd';
import { FirstTab } from './first-tab';
import { useState } from 'react';
import { SecondTab } from './second-tab';

export const AgreementTabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const onChange = (val) => {
    setActiveTab(val);
  };
  const items = [
    {
      key: 1,
      label: 'Ընդհանուր տվյալներ',
      children: <FirstTab />,
    },
    {
      key: 2,
      label: 'Համաձայնագրի տվյալներ',
      children: <SecondTab />,
    },
  ];
  return <Tabs defaultActiveKey={1} activeKey={activeTab} items={items} onChange={onChange} size="large" />;
};
