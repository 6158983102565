import { Divider, Form } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { DisabiityQuestionWithYesNo } from '../components/disability-question-with-yes-no';
import { DisabiityQuestionWithDetails } from '../components/disability-question-with-details';
import { LEGAL_REPRESENTITIVE } from 'pages/application/constants';

export const DisabilityDeviceRequirement = () => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });

  return (
    <VerticalSpace>
      <DisabiityQuestionWithYesNo
        title={`Օգտագործո՞ւմ ${
          applicantType === LEGAL_REPRESENTITIVE ? 'է' : 'եք'
        } լսելու համար նախատեսված օժանդակ սարք`}
        name={['deviceRequirement', 'useListeningDevice']}
      />
      <Divider />
      <DisabiityQuestionWithYesNo
        title={`Օգտագործո՞ւմ ${
          applicantType === LEGAL_REPRESENTITIVE ? 'է' : 'եք'
        } սայլակ, հենակներ կամ տեղաշարժմանն աջակցող այլ պարագաներ`}
        name={['deviceRequirement', 'useWheelchairs']}
      />
      <Divider />
      <DisabiityQuestionWithDetails
        name={['deviceRequirement', 'needToAdoptBuilding', 'need']}
        infoName={['deviceRequirement', 'needToAdoptBuilding', 'whatKindOf']}
        title={`${
          applicantType === LEGAL_REPRESENTITIVE ? 'Ունի՞' : 'Ունե՞ք'
        } բնակարանը, շենքը հարմարեցնելու անհրաժեշտություն`}
        infoPlaceholder="ինչպիսի"
      />
      <Divider />
      <DisabiityQuestionWithYesNo
        title={`${
          applicantType === LEGAL_REPRESENTITIVE ? 'Ունի՞' : 'Ունե՞ք'
        } ուղեկցողի աջակցության կամ անձնական խնամք տրամադրողի անհրաժեշտություն`}
        name={['deviceRequirement', 'needPersonalAssistance']}
      />
      <Divider />
      <DisabiityQuestionWithYesNo
        title={`Մարդկանց (ընտանիքի անդամների, ընկերների, ղեկավար անձանց, անձնական օգնականի և այլի) վերաբերմունքն ազդո՞ւմ է ${
          applicantType === LEGAL_REPRESENTITIVE ? 'նրա' : 'Ձեր'
        }  վարքագծի կամ գործողությունների վրա`}
        name={['deviceRequirement', 'attitudeOfPeople']}
      />
    </VerticalSpace>
  );
};
