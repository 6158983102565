import { Col, Form, Row } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';
import { PhoneNumber } from 'components/input/phone-number';
import { phonePattern } from 'helpers/constants';
import { useEffect } from 'react';

export const CompanyContactForm = ({ phoneNumber, email }) => {
  const form = useFormInstance();

  useEffect(() => {
    form.setFields([
      {
        name: ['contactDetails', 'contactPersonEmail'],
        value: email,
      },
    ]);
  }, [email, form, phoneNumber]);
  return (
    <FormSectionCard title={'Կոնտակտային տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Ծրագրի ղեկավար'}
            rules={[
              {
                required: true,
              },
            ]}
            name={['contactDetails', 'programHead']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Կոնտակտային անձի անուն, ազգանուն'}
            rules={[
              {
                required: true,
              },
            ]}
            name={['contactDetails', 'contactPersonFullName']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Կոնտակտային անձի էլ. փոստ'}
            rules={[{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }]}
            name={['contactDetails', 'contactPersonEmail']}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Կոնտակտային անձի հեռախոսահար'}
            rules={[
              {
                required: true,
                pattern: phonePattern,
                message: 'Սխալ հեռախոսի ֆորմատ, պետք է լինի +374XXXXXXXX ֆորմատով',
              },
            ]}
            name={['contactDetails', 'contactPersonPhone']}
            initialValue={phoneNumber}
          >
            <PhoneNumber />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  );
};
