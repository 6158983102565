import dayjs from 'dayjs';
import { AGREEMENT_APPLICATION_STATUSES, PATHS } from 'helpers/constants';
import { useNavigate } from 'react-router-dom';

import { useMemo } from 'react';
import { Actions } from 'components/button/actions';
import { APPLICATION_TYPES } from 'pages/applications/constants';

export const useColumns = (type, currentPage = 1, pageSize = 10) => {
  const navigate = useNavigate();

  const columnsByType = useMemo(() => {
    const commonColumns = [
      {
        title: 'N',
        dataIndex: 'number',
        align: 'center',
        flex: 1,
        render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
      },
      {
        title: 'Կարգավիճակ',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        flex: 1,
        filters: AGREEMENT_APPLICATION_STATUSES,
        onFilter: (value, record) => record.status === value,
      },
      {
        title: '',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        render: (item) => (
          <Actions
            items={item}
            onView={() =>
              navigate(
                PATHS.AGREEMENT_APPLICATION_VIEW.replace(':id', item.id)
              )
            }
          />
        ),
      },
    ];

    switch (type) {
      case APPLICATION_TYPES.AGREEMENT_APPLICATIONS:
        return [
          commonColumns[0],
          {
            title: 'Համաձայնագրի համարը',
            dataIndex: 'num',
            key: 'num',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Կնքման ամսաթիվը',
            dataIndex: 'sealingDate',
            key: 'sealingDate',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Վերջնաժամկետը',
            dataIndex: 'validUntil',
            key: 'validUntil',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Ոլորտը',
            dataIndex: 'sphereName',
            key: 'sphereName',
            align: 'center',
            flex: 1,
          },
          commonColumns[1],
          commonColumns[2],
        ];
      case APPLICATION_TYPES.REQUEST_FOR_SUPPORT:
        return [
          commonColumns[0],
          {
            title: 'Անուն Ազգանուն / Կազմակերպության անվանում',
            dataIndex: 'fullName',
            key: 'fullName',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Ծրագրի անվանումը',
            dataIndex: 'programName',
            key: 'programName',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Մարզը',
            dataIndex: 'regions',
            key: 'regions',
            align: 'center',
            flex: 1,
            render: (items) => (
              <>
                {items.map((region, index) => (
                  <span key={index}>
                    {region}
                    {index < items.length - 1 && ', '}
                  </span>
                ))}
              </>
            ),
          },
          {
            title: 'Ներկայացման ամսաթիվ',
            dataIndex: 'submitedDate',
            key: 'submitedDate',
            align: 'center',
            flex: 1,
            sorter: (a, b) => dayjs(a.submitedDate, 'DD/MM/YYYY').diff(dayjs(b.submitedDate, 'DD/MM/YYYY')),
          },
          commonColumns[1],
          commonColumns[2],
        ];
      case APPLICATION_TYPES.AGREEMENTS:
        return [
          commonColumns[0],
          {
            title: 'Իրավական տեսակ',
            dataIndex: 'legalType',
            key: 'legalType',
            align: 'center',
            flex: 1,
          },
          {
            title: 'ՀՎՀՀ/ՀԾՀ',
            dataIndex: 'ssnOrTin',
            key: 'ssnOrTin',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Համաձայնագրի համար',
            dataIndex: 'agreementNumber',
            key: 'agreementNumber',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Սկիզբ',
            dataIndex: 'dateFrom',
            key: 'dateFrom',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Ավարտ',
            dataIndex: 'dateTo',
            key: 'dateTo',
            align: 'center',
            flex: 1,
          },
          commonColumns[1],
          commonColumns[2],
        ];
      default:
        return [
          commonColumns[0],
          {
            title: 'Հայտի համար',
            dataIndex: 'num',
            key: 'num',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Ներկայացման ամսաթիվ',
            dataIndex: 'submittedDate',
            key: 'submittedDate',
            align: 'center',
            flex: 1,
            sorter: (a, b) => dayjs(a.submittedDate, 'DD/MM/YYYY').diff(dayjs(b.submittedDate, 'DD/MM/YYYY')),
          },
          {
            title: 'ՀԾՀ',
            dataIndex: 'ssn',
            key: 'ssn',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Անուն',
            dataIndex: 'firstName',
            key: 'firstName',
            align: 'center',
            flex: 1,
          },
          {
            title: 'Ազգանուն',
            dataIndex: 'lastName',
            key: 'lastName',
            align: 'center',
            flex: 1,
          },
          commonColumns[1],
          commonColumns[2],
        ];
    }
  }, [currentPage, navigate, pageSize, type]);

  return columnsByType;
};
