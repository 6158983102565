import { useState, useCallback } from 'react';
import { Spin, Table, Space } from 'antd';

import { useDataForApplication } from 'api/application/use-get-data-for-application';
import { AUTHORITY_TYPES } from 'pages/applications/constants';
import { DEFAULT_PAGE } from 'helpers/constants';
import { useColumns } from '../use-columns';
import { URL_GET_COMPANY_REPRESENTATIVES, URL_GET_PERSON_REPRESENTATIVES } from 'api/urrp/constants';
import { useAuth } from 'context/auth-context';

export const AuthoritiesList = ({ type }) => {
  const [page, setPage] = useState(DEFAULT_PAGE);

  const [result, setResult] = useState({ data: [], totalRecords: 0 });
  const {
    user: { companyId, isRepresentative, representativeProfileId },
  } = useAuth();
  const { isLoading } = useDataForApplication(
    type === AUTHORITY_TYPES.MY_AUTHORITIES ? URL_GET_PERSON_REPRESENTATIVES : URL_GET_COMPANY_REPRESENTATIVES,
    {
      pageNumber: page.pageNumber,
      pageSize: page.pageSize,
      companyId: isRepresentative ? representativeProfileId : companyId,
    },
    {
      onSuccess: (data) => {
        setResult(() => ({
          data: data?.data,
          totalRecords: data?.totalRecords,
        }));
      },
      enabled: true,
    }
  );
  const columns = useColumns(type, page.pageNumber, page.pageSize);

  const onHandleChange = useCallback((pagination) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
    }));
  }, []);

  return (
    <>
      <Spin spinning={isLoading}>
        <Space size="middle" direction="vertical" style={{ width: '100%' }}>
          <Table
            style={{ overflow: 'auto' }}
            columns={columns}
            dataSource={result.data}
            rowKey="profileId"
            onChange={onHandleChange}
            pagination={{
              total: result.totalRecords,
              showTotal: (total, range) => `${range[0]}-${range[1]} ${result.totalRecords} ից`,
            }}
          />
        </Space>
      </Spin>
    </>
  );
};
