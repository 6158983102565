import { Form } from 'antd';
import { TextArea } from 'components/input';

export const RejectionReasons = ({ reasons }) => {
  return (
    <Form.Item label="Պատճառ">
      {reasons.map((reason) => {
        return <TextArea key={reason} value={reason} style={{ marginBottom: '5px' }} />;
      })}
    </Form.Item>
  );
};
