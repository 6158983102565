import { Spin } from 'antd';
import { ApplicationViewFormWrapper } from '../../components/form/application/application-view-form-wrapper';

import { ApplicationViewFormData } from './form-data';

export const ApplicationNonReiable = () => {
  return (
    <ApplicationViewFormWrapper nonrelaible={true}>
      <Spin spinning={false}>
        <ApplicationViewFormData />
      </Spin>
    </ApplicationViewFormWrapper>
  );
};
