import { useAuth } from 'context/auth-context';
import { PATHS } from 'helpers/constants';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

export const PersonRoute = () => {
  const {
    user: { isLegal },
  } = useAuth();
  const context = useOutletContext();
  if (isLegal) {
    return <Navigate to={PATHS.SERVICES} />;
  }

  return <Outlet context={context} />;
};
