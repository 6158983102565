import {
  EyeOutlined,
  PrinterOutlined,
  FileDoneOutlined,
  CopyOutlined,
  EditOutlined,
  DeleteRowOutlined,
} from '@ant-design/icons';
import { PATHS } from '../../helpers/constants';

export const ACTIONS_TYPES = [
  {
    label: 'Դիտել',
    key: 'navigate',
    url: `${PATHS.APPLICATIONS_VIEW}`,
    icon: <EyeOutlined />,
    type: 'navigate',
  },
  {
    label: 'Որոշում',
    key: 'decision',
    icon: <FileDoneOutlined />,
    type: 'navigate',
  },
  {
    label: 'Տպել',
    key: 'print',
    icon: <PrinterOutlined />,
    type: 'navigate',
  },
  {
    label: 'Խմբագրել',
    key: 'edit',
    url: `${PATHS.APPLICATIONS_UPDATE}`,
    icon: <EditOutlined />,
    type: 'navigate',
  },
  {
    label: 'Կլոնավորել',
    key: 'clone',
    icon: <CopyOutlined />,
    type: 'onclick',
  },
  {
    label: 'Ջնջել',
    key: 'delete',
    icon: <DeleteRowOutlined />,
    type: 'onclick',
  },
];

export const ON_CLICK = 'onclick';
export const NAVIGATE = 'navigate';

export const APPLICATION_TYPES = {
  MY_APPLICATION: 1,
  OTHER_APPLICATION: 2,
  LEGAL_APPLICATION: 3,
  DONOR_APPLICATION: 4,
};

export const AUTHORITY_TYPES = {
  MY_AUTHORITIES: 1,
  OTHER_COMPANIES: 2,
};

export const SERVICE_TYPES = {
  WITHDRAWAL_SERVICES: 1,
  OFFERED_SERVICES: 2,
};
