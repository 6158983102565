import { VerticalSpace } from 'components/space/vertical-space';
import { Title } from 'components/typography';
import { DisabilityPersonAddress } from './disability-person-address';

export const formItemActualAddress = {
  regionId: {
    name: ['applicationForm', 'actualAddress', 'regionId'],
    rules: [{ required: true }],
  },
  region: {
    name: ['applicationForm', 'actualAddress', 'region'],
  },
  communityId: {
    name: ['applicationForm', 'actualAddress', 'communityId'],
    rules: [{ required: true }],
    dependencies: ['applicationForm', 'actualAddress', 'regionId'],
  },
  community: {
    name: ['applicationForm', 'actualAddress', 'community'],
  },
  settlementId: {
    name: ['applicationForm', 'actualAddress', 'settlementId'],
    rules: [{ required: true }],
    dependencies: ['applicationForm', 'actualAddress', 'communityId'],
  },
  settlement: {
    name: ['applicationForm', 'actualAddress', 'settlement'],
  },
  street: {
    name: ['applicationForm', 'actualAddress', 'street'],
    rules: [{ required: true }],
  },
  building: {
    name: ['applicationForm', 'actualAddress', 'building'],
    rules: [{ required: true }],
  },
  buildingType: {
    name: ['applicationForm', 'actualAddress', 'buildingType'],
  },
  buildingTypeId: {
    name: ['applicationForm', 'actualAddress', 'buildingTypeId'],
  },
  apartment: {
    name: ['applicationForm', 'actualAddress', 'apartment'],
  },
  postIndex: {
    name: ['applicationForm', 'actualAddress', 'postIndex'],
  },
  isActualAddressMatch: {
    name: ['applicationForm', 'actualAddress', 'isActualAddressMatch'],
  },
};

export const DisabilityPersonActualAddress = () => {
  return (
    <VerticalSpace>
      <Title level={2} color="#0B847F">
        Փաստացի բնակության (գտնվելու) վայր
      </Title>
      <DisabilityPersonAddress formItemAddress={formItemActualAddress} isActualAddress />
    </VerticalSpace>
  );
};
