import { Col, Row, Space } from 'antd';
import { Button } from '.';

export const SubmitButton = ({ canceltext = 'Նախորդ', submitText = 'Հաստատել', onCancel, onApprove, disabled }) => {
  const approveProps = onApprove ? { onClick: onApprove } : { htmlType: 'submit' };
  return (
    <Row align="center" justify="center">
      <Col span={10}>
        <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center' }}>
          <Button type="default" shape="round" size="large" onClick={onCancel}>
            {canceltext}
          </Button>
          <Button type="primary" shape="round" size="large" {...approveProps} disabled={disabled}>
            {submitText}
          </Button>
        </Space>
      </Col>
    </Row>
  );
};
