import { useQuery } from '@tanstack/react-query';

import { client } from '../client';

export const useGetFiles = (url, options = { enabled: true }, params = {}, headers = {}) => {
  const result = useQuery([url, params], () => client.get(url, { params, headers, responseType: 'arraybuffer' }), {
    ...options,
    select: (data) => data.data,
  });
  const { data, isSuccess } = result;
  return {
    ...result,
    data: isSuccess ? data : [],
  };
};
