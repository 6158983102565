import { Col, Form, Row, Spin } from 'antd';
import { URL_GET_USER_COMPANIES } from 'api/benefit/constants';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';
import { FormSectionCard } from 'components/card/form-section-card';
import { Input } from 'components/input';
import { VerticalSpace } from 'components/space/vertical-space';

export const Companies = () => {
  const [form] = Form.useForm();
  const { isLoading } = useGetFfromBenefit(
    URL_GET_USER_COMPANIES,
    {},
    {
      onSuccess: ({ data }) => {
        form.setFieldValue('companyList', data);
      },
    }
  );
  return (
    <Spin spinning={isLoading}>
      <Form form={form} name="company-list" layout="vertical" disabled>
        <Form.List name={['companyList']}>
          {(fields) => (
            <VerticalSpace>
              {fields.map(({ key, name }) => (
                <FormSectionCard title={'Կազմակերպության տվյալներ'} key={key}>
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <Form.Item name={[name, 'companyName']} label="Կազմակերպության անվանում">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[name, 'companyShortName']} label="Կազմակերպության կարճ անվանում">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[name, 'taxNumber']} label="ՀՎՀՀ">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={[name, 'address']} label="Իրավաբանական հասցե">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={[name, 'baseAddress']} label="Գործունեության հասցե">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[name, 'email']} label="Էլ․փոստ">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name={[name, 'phone']} label="Հեռախոս">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </FormSectionCard>
              ))}
            </VerticalSpace>
          )}
        </Form.List>
      </Form>
    </Spin>
  );
};
