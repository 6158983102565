import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { AddSwaddler } from './manage-swaddler-form-list';
import { useState } from 'react';
import { ErrorText } from 'components/typography/error-text';
import { swaddlerList } from 'pages/rapid-need/constants';
import { Checkbox } from 'components/checkbox';

export const SwaddlerForm = ({ parentFormItem, itemData }) => {
  const checkList = useWatch([...parentFormItem]);
  const [errorMessage, setErrorMessage] = useState(false);

  const validateAtLeastOneSelected = () => {
    const isAnyChecked = Object.values(checkList).some((val) => val?.id && val?.selections);
    if (!isAnyChecked) {
      setErrorMessage('Խնդրում ենք ընտրել բացված արժեքներից առնվազն մեկը');
      return Promise.reject();
    }
    setErrorMessage('');
    return Promise.resolve();
  };
  return (
    <>
      {swaddlerList?.map((option) => {
        return (
          <Form.Item
            key={option.id}
            name={[...parentFormItem, option.id]}
            style={{ margin: '0 0 0 20px' }}
            rules={[{ validator: validateAtLeastOneSelected }]}
          >
            <Form.Item name={[...parentFormItem, option.id, 'id']} valuePropName="checked" style={{ margin: 0 }}>
              <Checkbox value={option.id}>{option.label}</Checkbox>
            </Form.Item>
            <Form.Item hidden />

            {checkList?.[option.id]?.id && (
              <AddSwaddler
                parentProp={[...parentFormItem, option.id, 'selections']}
                itemId={option.id}
                option={option}
                itemData={itemData}
              />
            )}
          </Form.Item>
        );
      })}
      {errorMessage && <ErrorText text={errorMessage} />}
    </>
  );
};
