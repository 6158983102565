import { Col, Form, Input, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormSectionCard } from 'components/card/form-section-card';

import { AGREEMENT_APPLICATION_STATUSES } from '../constants';

export const AgreemnetStatus = () => {
  const applicationStatus = useWatch('status');

  return (
    <FormSectionCard title={'Կարգավիճակի տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Կարգավիճակ" name={'status'}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Կարգավիճակի ստացման ամսաթիվ" name={['condition', 'createdDate']}>
            <Input />
          </Form.Item>
        </Col>
        {!!(applicationStatus === AGREEMENT_APPLICATION_STATUSES.PAUSED) && (
          <>
            <Col span={24}>
              <Form.List name={['condition', 'rejectionReason']}>
                {(fields) => (
                  <div>
                    {fields.map(({ key, name }) => (
                      <Form.Item key={key} name={[name]} label={`Դադարեցման պատճառ ${key + 1}`}>
                        <Input.TextArea autoSize={{ minRows: 3 }} />
                      </Form.Item>
                    ))}
                  </div>
                )}
              </Form.List>
            </Col>
          </>
        )}
        {applicationStatus === AGREEMENT_APPLICATION_STATUSES.DENIED && (
          <Col span={24}>
            <Form.List name={['condition', 'rejectionReason']}>
              {(fields) => (
                <div>
                  {fields.map(({ key, name }) => (
                    <Form.Item key={key} name={[name]} label={`Մերժման պատճառ ${key + 1}`}>
                      <Input.TextArea autoSize={{ minRows: 3 }} />
                    </Form.Item>
                  ))}
                </div>
              )}
            </Form.List>
          </Col>
        )}
      </Row>
    </FormSectionCard>
  );
};
