import { Col, Row } from 'antd';
import { FormSectionCard } from '../../card/form-section-card';
import { FormItem } from '../form-item';
import { Input, TextArea } from '../../input';
import { Form } from '../form';

export const ApplicationInfoForm = ({ nonrelaible }) => {
  const number = Form.useWatch('number', { preserve: true });
  const num = Form.useWatch('num', { preserve: true });
  const rejectionReason = Form.useWatch('rejectionReason', { preserve: true });
  return (
    <FormSectionCard title={`Դիմում N ${nonrelaible ? num : number}`}>
      <FormItem name="num" hidden />
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <FormItem label="Դիմումի տեսակը" name={nonrelaible ? 'applicationType' : 'systemType'}>
            <Input />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <FormItem label="Դիմումի ենթատեսակը" name={nonrelaible ? 'applicationSubType' : 'applicationType'}>
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <FormItem label="Դիմումի ստացման ամսաթիվը" name="createdDate">
            <Input />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <FormItem label="Կարգավիճակ" name="status">
            <Input />
          </FormItem>
        </Col>
        {rejectionReason && (
          <Col span={24}>
            <FormItem label="Մերժման պատճառ" name="rejectionReason">
              <TextArea resize={'none'} />
            </FormItem>
          </Col>
        )}
      </Row>
    </FormSectionCard>
  );
};
