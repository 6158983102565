import { Divider, Form } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { DisabiityQuestionWithYesNo } from '../components/disability-question-with-yes-no';
import { DisabiityQuestionWithDetails } from '../components/disability-question-with-details';
import { DisabilityTrustProvide } from '../components/disability-trust-provide';
import { DisabilityTrustApprove } from '../components/disability-trust-approve';
import { useEffect } from 'react';
import { LEGAL_REPRESENTITIVE } from 'pages/application/constants';

export const DisabilityDailyRoutine = () => {
  const form = Form.useFormInstance();
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  const doYouWorkData = Form.useWatch(['deviceRequirement', 'doYouWork'], { preserve: true });
  const isLearning = Form.useWatch(['deviceRequirement', 'doYouStudy'], {
    preserve: true,
  });

  useEffect(() => {
    // if (isLearning) {
    //   form.resetFields([['deviceRequirement', 'doYouWork']]);
    // } else {
    form.resetFields([
      ['deviceRequirement', 'goInstituteEveryDay'],
      ['deviceRequirement', 'needToAdaptEduMaterial'],
      ['deviceRequirement', 'needHelpInAttendingInstitute', 'need'],
    ]);
    // }
  }, [form, isLearning]);
  useEffect(() => {
    if (doYouWorkData !== true) {

      form.setFieldsValue({
        deviceRequirement: {
          needToSetupWorkEnv: {
            need: '',
            whatKindOf: '',
          },
          haveWorkingHinders: {
            have: '',
            whatKindOf: '',
          },
          needVocationalTraining: ''
        },
      });
    }
  }, [form, doYouWorkData]);

  return (
    <VerticalSpace>
      <Divider />
      <DisabiityQuestionWithYesNo
        title={`Սովորո՞ւմ ${applicantType === LEGAL_REPRESENTITIVE ? 'է' : 'եք'}  որևէ կրթական հաստատությունում`}
        name={['deviceRequirement', 'doYouStudy']}
      />
      <Divider />
      {isLearning && (
        <>
          <DisabiityQuestionWithYesNo
            title={`Ամեն օր հաճախո՞ւմ ${applicantType === LEGAL_REPRESENTITIVE ? 'է' : 'եք'} կրթական հաստատություն`}
            name={['deviceRequirement', 'goInstituteEveryDay']}
          />
          <Divider />
          <DisabiityQuestionWithYesNo
            title={`  ${applicantType === LEGAL_REPRESENTITIVE ? 'Ունի՞' : 'Ունե՞ք'
              } ուսումնական նյութերի, գույքի, համակարգչային տեխնիկայի հարմարեցման անհրաժեշտություն`}
            name={['deviceRequirement', 'needToAdaptEduMaterial']}
          />
          <Divider />
          <DisabiityQuestionWithDetails
            name={['deviceRequirement', 'needHelpInAttendingInstitute', 'need']}
            infoName={['deviceRequirement', 'needHelpInAttendingInstitute', 'whatKindOf']}
            title="Կա՞ աջակցության անհրաժեշտություն կրթական հաստատություն հաճախելու կամ դասերը պատրաստելու հարցում"
            infoPlaceholder="ինչպիսի" />
          <Divider />
        </>
      )}
      {/* Initial value is undefined */}
      {/* {isLearning === false && ( */}
      <>
        <DisabiityQuestionWithYesNo
          title={`Աշխատո՞ւմ ${applicantType === LEGAL_REPRESENTITIVE ? 'է' : 'եք'}`}
          name={['deviceRequirement', 'doYouWork']}
        />
        <Divider />
      </>
      {/* )} */}
      {doYouWorkData === true &&
        <>
          <DisabiityQuestionWithDetails
            name={['deviceRequirement', 'needToSetupWorkEnv', 'need']}
            infoName={['deviceRequirement', 'needToSetupWorkEnv', 'whatKindOf']}
            title={`${applicantType === LEGAL_REPRESENTITIVE ? 'Ունի՞' : 'Ունե՞ք'
              } աշխատանքային միջավայրի հարմարեցման անհրաժեշտություն`}
            infoPlaceholder="ինչպիսի"
          />
          <Divider />

          <DisabiityQuestionWithDetails
            name={['deviceRequirement', 'haveWorkingHinders', 'have']}
            infoName={['deviceRequirement', 'haveWorkingHinders', 'whatKindOf']}
            title={`${applicantType === LEGAL_REPRESENTITIVE ? 'Ունի՞' : 'Ունե՞ք'} այլ խոչընդոտներ աշխատավայրում`}
            infoPlaceholder="ինչպիսի"
          />
          <Divider />

          <DisabiityQuestionWithYesNo
            title={`Կարիք ${applicantType === LEGAL_REPRESENTITIVE ? 'ունի՞' : 'ունե՞ք'
              } մասնագիտական ուսուցման կամ վերաորակավորման դասընթացների`}
            name={['deviceRequirement', 'needVocationalTraining']}
          />
          <Divider />
        </>
      }
      <DisabiityQuestionWithYesNo
        title={`Ֆինանսապես ինքնուրո՞ւյն ${applicantType === LEGAL_REPRESENTITIVE ? 'է' : 'եք'}`}
        name={['deviceRequirement', 'financiallyIndependent']}
      />
      <Divider />
      <DisabilityTrustProvide />
      <DisabilityTrustApprove />
    </VerticalSpace>
  );
};
