import { Divider, Form } from 'antd';
import { DisabiityQuestionWithOptions } from '../components/disability-question-with-options';
import {
  BODY_POSITION,
  COMMUNICATION_DIFFICULTIES,
  HOUSE_HOLD_ITEM,
  MOVEMENT_DIFFICULTIES,
  SELF_CARE_DIFFICULTIES,
  getTransportList,
} from '../constants';
import { VerticalSpace } from 'components/space/vertical-space';
import { DisabiityQuestionWithYesNo } from '../components/disability-question-with-yes-no';
import { DisabiityQuestionBiometric } from '../components/disability-question-biometric';
import { DisabiityQuestionSmoke } from '../components/disability-question-smoke';
import { APPLICATION_FOR_HIMSELF } from 'pages/application/constants';

export const DisabilityVitalityDifficulties = () => {
  const applicantType = Form.useWatch(['applicationForm', 'fillingApplication'], { preserve: true });
  return (
    <VerticalSpace>
      <DisabiityQuestionWithOptions
        items={SELF_CARE_DIFFICULTIES}
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք ինքնասպասարկման դժվարություններ'
            : 'Ունի՞ ինքնասպասարկման դժվարություններ'
        }
        selectName={['questionnaire', 'selfServiceDifficulties', 'have']}
        checkName={['questionnaire', 'selfServiceDifficulties', 'difficultiesType']}
      />
      <Divider />
      <DisabiityQuestionWithOptions
        items={HOUSE_HOLD_ITEM}
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք դժվարություններ կենցաղային գործերում'
            : 'Ունի՞ դժվարություններ կենցաղային գործերում'
        }
        selectName={['questionnaire', 'houseHoldDifficulties', 'have']}
        checkName={['questionnaire', 'houseHoldDifficulties', 'difficultiesType']}
      />
      <Divider />
      <DisabiityQuestionWithOptions
        items={MOVEMENT_DIFFICULTIES}
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք տեղաշարժման դժվարություններ'
            : 'Ունի՞ տեղաշարժման դժվարություններ'
        }
        selectName={['questionnaire', 'movementDifficulties', 'have']}
        checkName={['questionnaire', 'movementDifficulties', 'difficultiesType']}
      />
      <Divider />
      <DisabiityQuestionWithOptions
        items={BODY_POSITION}
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք դժվարություններ մարմնի դիրք փոխելիս'
            : 'Ունի՞ դժվարություններ մարմնի դիրք փոխելիս'
        }
        selectName={['questionnaire', 'changePositionDifficulties', 'have']}
        checkName={['questionnaire', 'changePositionDifficulties', 'difficultiesType']}
      />
      <Divider />
      <DisabiityQuestionWithYesNo
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք դժվարություններ ձեռքերն օգտագործելիս'
            : 'Ունի՞ դժվարություններ ձեռքերն օգտագործելիս'
        }
        name={['questionnaire', 'useHandsDifficulties']}
      />
      <Divider />
      <DisabiityQuestionWithYesNo
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք հաղորդակցման դժվարություններ'
            : 'Ունի՞ հաղորդակցման դժվարություններ'
        }
        name={['questionnaire', 'speechDifficulties']}
      />
      <Divider />
      <DisabiityQuestionWithYesNo
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք լսելու հետ կապված դժվարություններ'
            : 'Ունի՞ լսելու հետ կապված դժվարություններ'
        }
        name={['questionnaire', 'hearingDifficulties']}
      />
      <Divider />
      <DisabiityQuestionWithYesNo
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք տեսնելու հետ կապված դժվարություններ'
            : 'Ունի՞ տեսնելու հետ կապված դժվարություններ'
        }
        name={['questionnaire', 'visionDifficulties']}
      />
      <Divider />
      <DisabiityQuestionWithOptions
        items={COMMUNICATION_DIFFICULTIES}
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք մարդկանց հետ շփվելու դժվարություններ'
            : 'Ունի՞ մարդկանց հետ շփվելու դժվարություններ'
        }
        selectName={['questionnaire', 'communicationDifficulties', 'have']}
        checkName={['questionnaire', 'communicationDifficulties', 'difficultiesType']}
      />
      <Divider />
      <DisabiityQuestionWithOptions
        items={getTransportList(applicantType)}
        title={
          applicantType === APPLICATION_FOR_HIMSELF
            ? 'Ունե՞ք դժվարություններ տրանսպորտից օգտվելիս '
            : 'Ունի՞ դժվարություններ տրանսպորտից օգտվելիս '
        }
        selectName={['questionnaire', 'transportDifficulties', 'have']}
        checkName={['questionnaire', 'transportDifficulties', 'difficultiesType']}
      />
      <Divider />
      <DisabiityQuestionBiometric />
      <Divider />
      <DisabiityQuestionSmoke />
    </VerticalSpace>
  );
};
