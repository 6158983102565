import {Col, Form, Row} from "antd";
import {Input} from "../../components/input";

export const IdentificationDocument = () => {

    return (
        <Row gutter={16} justify={"center"}>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                    label="ԱՀՓ տեսակ"
                    name="documentType"
                    rules={[{required: true}]}
                >
                    <Input size="middle" disabled/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                    label="ԱՀՓ համար"
                    name="documentNumber"
                    rules={[{required: true}]}
                >
                    <Input size="middle" disabled/>
                </Form.Item>
            </Col>
        </Row>
    )
}