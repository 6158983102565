import { VerticalSpace } from 'components/space/vertical-space';
import { DIPersonDataForm } from '../components/person-data-form';
import { DIPersonContactForm } from '../components/person-contact-form';
import { useGetFfromBenefit } from 'api/benefit/use-get-dictionary';

import { Form, Radio, Spin } from 'antd';
import { APPLICATION_MODE, formItemActualAddress, formItemRegAddress, personMainData } from '../constants';
import { DIPersonAddress } from '../components/person-address-form';
import { FormSectionFullCard } from 'components/card/form-section-full-card';
import { Text } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { URL_GET_USER_DATA_FROM_BPR } from 'api/urrp/constants';
import { useMatches } from 'react-router';
import { ApplicationInfo } from '../components/application-info';

export const FirstStep = () => {
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;

  const { data: userData, isLoading } = useGetFfromBenefit(
    URL_GET_USER_DATA_FROM_BPR,
    {},
    {
      enabled: !!(type !== APPLICATION_MODE.VIEW),
    }
  );

  const doAddressesMatch = Form.useWatch([personMainData, 'isRegAddressActual'], {
    preserve: true,
  });

  return (
    <Spin spinning={isLoading && type !== APPLICATION_MODE.VIEW}>
      <VerticalSpace>
        {type === APPLICATION_MODE.VIEW && <ApplicationInfo />}
        <DIPersonDataForm disabled={true} personData={userData} isView={type === APPLICATION_MODE.VIEW} />
        {!isLoading && (
          <DIPersonContactForm
            disabled={true}
            phoneNumber={userData.phoneNumber}
            email={userData.email}
            isView={type === APPLICATION_MODE.VIEW}
          />
        )}
        <DIPersonAddress
          formItemAddress={formItemRegAddress}
          isActualAddress={false}
          viewMode={true}
          data={userData.registrationAddress}
          isView={type === APPLICATION_MODE.VIEW}
        />
        <FormSectionFullCard>
          <Form.Item
            label={
              <Text color={COLORS.PRIMARY.BLUE}>Հաշվառման հասցեն համընկնում է փաստացի բնակության հասցեի հետ </Text>
            }
            name={[personMainData, 'isRegAddressActual']}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio style={{ color: 'black' }} value={true}>Այո</Radio>
              <Radio style={{ color: 'black' }} value={false}>Ոչ</Radio>
            </Radio.Group>
          </Form.Item>
        </FormSectionFullCard>
        {/* Initial value in undefined */}
        {doAddressesMatch !== undefined && (
          <DIPersonAddress
            formItemAddress={
              doAddressesMatch && !type !== APPLICATION_MODE.VIEW ? formItemRegAddress : formItemActualAddress
            }
            isActualAddress={true}
            viewMode={doAddressesMatch || type === APPLICATION_MODE.VIEW ? true : false}
            title="Փաստացի բնակության հասցե"
            doAddressesMatch={doAddressesMatch && type !== APPLICATION_MODE.VIEW}
            isView={type === APPLICATION_MODE.VIEW}
          />
        )}
      </VerticalSpace>
    </Spin>
  );
};
