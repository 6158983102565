import { useNavigate, useOutletContext } from 'react-router-dom';
import { URL_HAS_INCOMPLETE_APPLICATION } from '../../api/application/constants';
import { useDataForApplication } from '../../api/application/use-get-data-for-application';
import { useApplication } from '../../context/applicaton-context';
import { PATHS } from '../../helpers/constants';
import { notification } from 'antd';

export const useCheckApplicationExists = () => {
  const context = useOutletContext();
  const { step, finishedSteps } = useApplication();
  const navigate = useNavigate();
  useDataForApplication(
    URL_HAS_INCOMPLETE_APPLICATION,
    {},
    {
      enabled: !!(step === 0 && !context.isEdit && !finishedSteps.length) && false,
      onSuccess: (data) => {
        if (data.message) {
          navigate(PATHS.APPLICATIONS);
          notification.info({
            message: 'Ուշադրություն',
            description: data.message,
          });
        }
      },
    }
  );
};
