import { ApplicationFormWrapper } from '../../components/form/application/application-form-wrapper';
import { ContentWrapper } from '../../components/layouts/content-wrapper';
import { ManagePage } from '../../components/layouts/manage-page';
import { ApplicationProvider } from '../../context/applicaton-context';
import { ManageSteps } from './manage-steps';

export const Application = () => {
  return (
    <ApplicationProvider>
      <ManagePage />
      <ContentWrapper style={{ padding: '40px 10px 64px' }} fullHeight>
        <ApplicationFormWrapper>
          <ManageSteps />
        </ApplicationFormWrapper>
      </ContentWrapper>
    </ApplicationProvider>
  );
};
