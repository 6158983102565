import { Col, Layout, Row } from 'antd';
import styled from 'styled-components';
import { PageTitle } from '../typography/title';
import { GerbSection } from './gerb-logo';
// import { SsaSection } from './ssa-logo';
import { screenSize } from '../../helpers/constants';

const { Header: HeaderComponent } = Layout;

const HeaderStyle = styled(HeaderComponent)`
  background-color: #ffffff;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 74px;

  @media only screen and (max-width: ${screenSize.lg}) {
    padding: 0;
    height: 220px;
  }
  @media only screen and (max-width: ${screenSize.sm}) {
    padding: 0;
    height: 250px;
  }
`;

export const Header = ({ nonreliable }) => {
  return (
    <HeaderStyle>
      <Row align={'middle'} justify={'center'}>
        <Col md={24} lg={2}>
          <GerbSection />
        </Col>
        <Col md={24} lg={22} style={{ textAlign: 'center' }}>
          <PageTitle nonreliable={nonreliable} />
        </Col>
        {/* <SsaSection /> */}
      </Row>
    </HeaderStyle>
  );
};
