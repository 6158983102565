import { Modal, Button, Form, Spin, Select } from 'antd';

import { PATHS } from 'helpers/constants';
import { useNavigate } from 'react-router-dom';

import { createOptionsFromArray } from 'helpers/utils';
import { VerticalSpace } from 'components/space/vertical-space';
import { useSaveApplication } from 'api/application/use-save-application';
import { URL_AGREEMENT_SUSPEND_REASONS, URL_AGREEMENT_SUSPEND } from 'api/application/constants';
import { useGetData } from 'api/dictionary/use-get-data';

export const RejectModal = ({ visible, setVisible, agreementId }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data, isLoading } = useGetData(URL_AGREEMENT_SUSPEND_REASONS);
  const { mutate } = useSaveApplication(URL_AGREEMENT_SUSPEND, {
    onSuccess: () => {
      setVisible(false);
      navigate(PATHS.AGREEEMENTS);
    },
  });

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        mutate({ reasonIds: values?.reasonIds, agreementId: +agreementId });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <Spin spinning={isLoading}>
      <Modal
        onOk={handleOk}
        width={'70%'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Չեղարկել
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Դադարեցնել
          </Button>,
        ]}
      >
        <VerticalSpace>
          <Form form={form} layout="vertical" name="agreement-rejection-form" initialValues={{ remember: true }}>
            <Form.Item name={'reasonIds'} label="Դադարեցման պատճառը" rules={[{ required: true }]}>
              <Select options={createOptionsFromArray(data)} mode="multiple" />
            </Form.Item>
          </Form>
        </VerticalSpace>
      </Modal>
    </Spin>
  );
};
