import { Col, Form, Row } from 'antd';

import { VerticalSpace } from 'components/space/vertical-space';

import { Input } from 'components/input';
import { FormSectionCard } from 'components/card/form-section-card';

export const PersonAddress = ({ isActualAddress, title, formItemAddress }) => {
  return (
    <FormSectionCard title={title}>
      <VerticalSpace>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Մարզ" name={formItemAddress.region.name}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Համայնք" name={formItemAddress.community.name}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Բնակավայր" name={formItemAddress.settlement.name}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Փողոց" {...formItemAddress.street}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Կառույցի տեսակ" name={formItemAddress.buildingType.name}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Կառույցի համար" {...formItemAddress.building}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Բնակարան" {...formItemAddress.apartment}>
              <Input />
            </Form.Item>
          </Col>
          {isActualAddress && (
            <>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="Փոստային դասիչ" {...formItemAddress.postIndex}>
                  <Input />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </VerticalSpace>
    </FormSectionCard>
  );
};
