import { Form } from 'antd';
import { Checkbox } from 'components/checkbox';
import { careOptions } from 'pages/rapid-need/constants';
import { NeedMatchCard } from '../need-match-card';
import { arePathsEqual } from 'pages/rapid-need/utils';
import { PATHS } from 'helpers/constants';
import { useMatches } from 'react-router-dom';

export const CareList = ({ parentFormItem, itemData = [], isView }) => {
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const validateAtLeastOneChecked = (_, value) => {
    const isAnyChecked = value && Object.values(value).some((v) => v === true);
    if (!isAnyChecked) {
      return Promise.reject(new Error('Խնդրում ենք ընտրել բացված արժեքներից առնվազն մեկը'));
    }
    return Promise.resolve();
  };

  return (
    <Form.Item name={parentFormItem} rules={[{ validator: validateAtLeastOneChecked }]}>
      {careOptions.map((option) => {
        const [currentCareData] = itemData.filter((item) => item?.careType[option.fieldName]);

        return (
          <>
            <Form.Item
              key={option.id}
              name={[...parentFormItem, option.fieldName]}
              valuePropName="checked"
              style={{ marginLeft: '20px', marginBottom: 0 }}
            >
              <Checkbox value={option.id}>{option.label}</Checkbox>
            </Form.Item>
            {currentCareData?.careType?.[option.fieldName] &&
              isView &&
              arePathsEqual(match.pathname, PATHS.RAPID_NEED_VIEW) && <NeedMatchCard itemData={currentCareData} />}
          </>
        );
      })}
    </Form.Item>
  );
};
