import { Col, Form, Row } from 'antd';

import { FormSectionCard } from 'components/card/form-section-card';
import { Datepicker } from 'components/datepicker';
import { Input } from 'components/input';

export const ApplicantInfo = () => {
  return (
    <FormSectionCard title={'Դիմումատուի անձնական տվյալներ'}>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Անուն'} name={['mainData', 'firstName']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Ազգանուն'} name={['mainData', 'lastName']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label={'Հայրանուն'} name={['mainData', 'patronymicName']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item label={'Ծննդյան ամսաթիվ'} name={['mainData', 'birthdate']}>
            <Datepicker format="DD/MM/YYYY" style={{ borderRadius: 10, width: '100%' }} disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item label={'Սեռ'} name={['mainData', 'gender']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
          <Form.Item label={'ՀԾՀ/ՀԾՀ չունենալու վերաբերյալ տեղեկանքի համար'} name={['mainData', 'ssn']}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={'Հեռախոսահամար'} name={['mainData', 'phoneNumber']}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={'Էլ․ փոստի հասցե'} name={['mainData', 'email']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item hidden name={['mainData', 'profileId']} />
    </FormSectionCard>
  );
};
