import { Form, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// import { PersonMainData } from './person-main-data';
import { useState } from 'react';

import { armenianLettersPattern } from 'helpers/constants';
import { dateConvert, errorMessage } from 'helpers/utils';
import { useGetValidateUser } from 'api/benefit/use-get-validate-user';
import { VerticalSpace } from 'components/space/vertical-space';
import { DisabilityButton } from 'components/button';
import { ErrorText } from 'components/typography/error-text';
import { SuccessText } from 'components/typography/success-text';
import { FormItem } from 'components/form/form-item';

const armenianPattern = [
  {
    pattern: armenianLettersPattern,
    message: 'Տեղեկատվությունը սխալ է լրացված',
  },
];

export const formItemProp = {
  fname: {
    name: ['applicationForm', 'firstName'],
    rules: armenianPattern,
  },
  lname: {
    name: ['applicationForm', 'lastName'],
    rules: armenianPattern,
  },
  patronymicName: {
    name: ['applicationForm', 'patronymicName'],
    rules: armenianPattern,
  },
  ssn: {
    name: ['applicationForm', 'ssn'],
    rules: [
      { required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (value === getFieldValue(['applicationForm', 'legalRepresentative', 'ssn'])) {
            return Promise.reject(new Error('Դիմումատուն և Օրինական ներկայուցուցչը չեն կարող լինել նույն մարդը'));
          }

          return Promise.resolve();
        },
      }),
    ],
  },
  birthdate: {
    name: ['applicationForm', 'birthDate'],
  },
  isApproved: {
    name: ['applicationForm', 'isApproved'],
  },
  phoneNumber: {
    name: ['applicationForm', 'phoneNumber'],
    rules: [{ required: true }],
  },
  email: {
    name: ['applicationForm', 'email'],
    rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
  },
  gender: {
    name: ['applicationForm', 'gender'],
  },
};

export const onReset = (form) => {
  form.resetFields(Object.values(formItemProp).map((item) => item.name));
};

export const DisabilityPersonDataSearchForm = ({ url, onSuccess, onSelect, parent = [] }) => {
  const [search, setSearch] = useState(false);
  const form = Form.useFormInstance();

  const isApproved = Form.useWatch([...parent, ...(formItemProp?.isApproved?.name || [])], { preserve: true });

  const onSearch = () => {
    form
      ?.validateFields([
        formItemProp.ssn.name,
        formItemProp.fname.name,
        formItemProp.lname.name,
        formItemProp.patronymicName.name,
        formItemProp.birthdate.name,
      ])
      .then((values) => {
        const ssn = values.applicationForm.ssn;
        const firstName = values.applicationForm.firstName;
        const lastName = values.applicationForm.lastName;
        const patronymicName = values.applicationForm.patronymicName;
        const birthdate = dateConvert(values.applicationForm.birthDate);

        if (ssn && firstName && lastName && birthdate) {
          setSearch({ ssn, firstName, lastName, patronymicName, birthdate });
        }
      });
  };

  const { isInitialLoading } = useGetValidateUser(url, search, {
    enabled: !!search.ssn,
    onSuccess: (data) => {
      const isSuccess = onSuccess(data);
      if (isSuccess) {
        formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], !!data);
        onSearch();
      }
    },
    select: (data) => onSelect?.(data) || data.data,
    onError: (error) => {
      errorMessage(error);
      formItemProp.isApproved && form.setFieldValue([...parent, ...formItemProp.isApproved.name], false);
      onSearch();
    },
  });

  return (
    <Spin spinning={isInitialLoading}>
      <VerticalSpace>
        {/* <PersonMainData formItemProp={formItemProp} /> */}

        <FormItem
          name={[...parent, ...(formItemProp?.isApproved?.name || [])]}
          label={false}
          rules={[
            {
              validator(_, value) {
                if (value === true) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Անձը չի հաջողվել նույնականացնել'));
              },
            },
          ]}
        >
          {isApproved === null || isApproved === undefined ? (
            <DisabilityButton block size="large" icon={<SearchOutlined />} type="default" onClick={onSearch}>
              Որոնել
            </DisabilityButton>
          ) : (
            <DisabilityButton block size="large" icon={<SearchOutlined />} type="default" onClick={() => onReset(form)}>
              Չեղարկել
            </DisabilityButton>
          )}
        </FormItem>

        {isApproved === false && <ErrorText text="Նույնականացումը չի հաջողվել" />}
        {isApproved === true && <SuccessText text="Անձը նույնականացվել է" />}
      </VerticalSpace>
    </Spin>
  );
};
