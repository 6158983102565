import { Col, Form, Modal, Row, Space, message } from 'antd';
import { useSaveApplication } from 'api/application/use-save-application';
import { URL_EDIT_DONOR_APPLICATION, URL_SAVE_DONOR_APPLICATION } from 'api/urrp/constants';
import { Button } from 'components/button';
import { useAuth } from 'context/auth-context';
import dayjs from 'dayjs';
import { COLORS, DATE_FORMAT_YYYY_MM_DD, PATHS } from 'helpers/constants';
import { errorMessage } from 'helpers/utils';
import { modifyFormListValues } from 'pages/rapid-need/utils';
import { useNavigate } from 'react-router-dom';

export const ActionButtons = ({ next, prev, step, isView, isEdit, applicationId, representativeProfileId }) => {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const {
    user: { profileId, companyId, isLegal, isRepresentative },
  } = useAuth();

  const { mutate, isLoading } = useSaveApplication(
    // Regular expression to remove Zero Width Space characters
    isEdit ? URL_EDIT_DONOR_APPLICATION.replace(/[\u200B-\u200D\uFEFF]/g, '') : URL_SAVE_DONOR_APPLICATION,
    {
      onSuccess: (response) => {
        const modal = Modal.success({
          title: 'Շնորհակալություն',
          content: isEdit ? (
            <p>Կատարված փոփոխությունները պահպանվել են։ Հայտի ընթացքի մասին կտեղեկացվեք:</p>
          ) : (
            <p>
              Աջակցության Ձեր հայտը հաստատված է: Հայտի համարն է {` `}
              <span
                style={{ textDecoration: 'underline', color: COLORS.PRIMARY.BLUE, cursor: 'pointer' }}
                onClick={() => {
                  navigate(PATHS.AGREEEMENTS);
                  modal.destroy();
                }}
              >
                {response?.data?.applicationNumber}
              </span>
              ։ <br /> Հետևեք Ձեր անձնական օգտահաշվին, որպեսզի ստանաք աջակցության տրամադրման որոշումների, ընթացքի և
              արդյունքների մասին անհրաժեշտ տվյալներ: Անհրաժեշտության դեպքում Ձեզ հետ կապ կհաստատի աջակցության
              կազմակերպման համար պատասխանատու սոցիալական աշխատողը կամ ԱՍՀՆ-ի մասնագետը: Լրացուցիչ հարցերի դեպքում կարող
              եք կապվել
              {` `}
              <a href="mailto:info@mlsa.am" target="_blank" rel="noreferrer noopener">
                info@mlsa.am
              </a>
              {` `}
              հասցեով:
            </p>
          ),
          footer: (
            <Row align="center" justify="center">
              <Col span={8}>
                <Space
                  size="middle"
                  align="center"
                  style={{ width: '100%', justifyContent: 'center', marginTop: '16px' }}
                >
                  <Button
                    size="medium"
                    type="primary"
                    onClick={() => {
                      navigate(PATHS.APPLICATIONS);
                      modal.destroy();
                    }}
                  >
                    Լավ
                  </Button>
                </Space>
              </Col>
            </Row>
          ),
          closable: true,
          onCancel: () => navigate(PATHS.APPLICATIONS),
        });
      },
      onError: errorMessage,
    }
  );

  const onNext = () => {
    const formData = form.getFieldsValue(true);
    if (isView) {
      next();
    }
    form
      .validateFields()
      .then((vals) => {
        if (step !== 1) {
          next();
        } else {
          const dataToSave = {
            ...(isEdit ? { applicationId } : {}),
            profileId: !isRepresentative && !isLegal ? profileId : null,
            companyId: isRepresentative ? companyId || representativeProfileId : isLegal ? companyId : null,
            representativeProfile: isRepresentative ? profileId : null,
            createdByRepresentative: isRepresentative || false,
            programDetails: {
              ...formData.programDetails,
              email: formData.programDetails?.email || null,
              phoneNumber: formData.programDetails?.phoneNumber || null,
              agreementDate: dayjs(formData.programDetails.agreementDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
              agreementDeadline: dayjs(formData.programDetails.agreementDeadline, 'DD.MM.YYYY').format('YYYY-MM-DD'),
              programStartDate: dayjs(formData.programDetails.programStartDate).format(DATE_FORMAT_YYYY_MM_DD),
              programEndDate: dayjs(formData.programDetails.programEndDate).format(DATE_FORMAT_YYYY_MM_DD),
            },
            support: {
              bed: null,
              bedClothes: null,
              householdSupplement: null,
              medicine: null,
              psychicalSupport: null,
              schoolSupplies: null,
              hygieneItems: null,
              ...formData.support,
              care: {
                inplace: formData.support?.care?.inplace || null,
                careCenter: formData.support?.care?.careCenter || null,
              },
              food: {
                rawFood: formData.support?.food?.rawFood || null,
                readyFood: formData.support?.food?.readyFood || null,
                babyFood: {
                  underSixMonths: formData.support?.food?.babyFood?.underSixMonths || null,
                  fromSixMonthToOneYear: formData.support?.food?.babyFood?.fromSixMonthToOneYear || null,
                  overOneYear: formData.support?.food?.babyFood?.overOneYear || null,
                },
              },
              clothes: formData.support?.clothes ? modifyFormListValues(formData.support.clothes) : null,
              shoes: formData.support?.shoes ? modifyFormListValues(formData.support.shoes) : null,
              swaddlers: formData.support?.swaddlers ? modifyFormListValues(formData.support.swaddlers) : null,
              supportInstruments: formData.support?.supportInstruments?.selections || [],
              other: formData.support?.other?.other || '',
            },
            contactDetails: {
              programHead: formData?.contactDetails?.programHead || null,
              contactPersonFullName: formData?.contactDetails?.contactPersonFullName || null,
              contactPersonEmail: formData?.contactDetails?.contactPersonEmail || null,
              contactPersonPhone: formData?.contactDetails?.contactPersonPhone || null,
            },
          };
          const plainSelections = Object.values(vals?.support || {}).filter((item) => !!item);
          if (plainSelections.length < 1) {
            message.error('Խնդրում ենք ցանկից ընտրել նվազագույնը 1 արժեք');
          } else {
            mutate(dataToSave);
          }
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        message.error('Կան սխալ լրացված դաշտեր');
      });
  };

  return (
    <Space style={{ justifyContent: 'center', display: 'flex' }}>
      {step > 0 && (
        <Button onClick={() => prev()} disabled={false}>
          Նախորդ
        </Button>
      )}
      {step < 1 && (
        <Button type="primary" onClick={onNext} disabled={false}>
          Հաջորդ
        </Button>
      )}
      {step === 1 && !isView && (
        <Button type="primary" htmlType="submit" onClick={onNext} disabled={false} loading={isLoading}>
          Հաստատել
        </Button>
      )}
    </Space>
  );
};
