/* eslint-disable no-unused-vars */
import { Form } from 'antd';
import { FormSectionCard } from 'components/card/form-section-card';
import { VerticalSpace } from 'components/space/vertical-space';
import { Text } from 'components/typography';
import { Checkbox } from 'components/checkbox';
import React from 'react';
import { TextArea } from 'components/input';
import { APPLICATION_MODE, needOptions, needs } from '../constants';
import { useMatches, useParams } from 'react-router';
import { URL_GET_NEED_STATUSES } from 'api/urrp/constants';
import { useGetData } from 'api/dictionary/use-get-data';
import { NeedMatchCard } from '../components/need-match-card';
import { arePathsEqual } from '../utils';
import { PATHS } from 'helpers/constants';

export const ThirdStep = () => {
  const checklist = Form.useWatch([needs], {
    preserve: true,
  });
  const { id } = useParams();
  const matches = useMatches();
  const match = matches.find((match) => match.handle);
  const type = match?.handle?.type;

  const { data, isLoading } = useGetData(
    URL_GET_NEED_STATUSES,
    {
      applicationId: id,
    },
    {
      enabled: type === APPLICATION_MODE.VIEW,
    }
  );

  return (
    <FormSectionCard title={'Կարիքի տվյալներ'}>
      <VerticalSpace>
        {type !== APPLICATION_MODE.VIEW && (
          <Text>Խնդրում ենք այս ցանկից ընտրել առավելագույնը 5 կարիք, որոնք ամենաառաջնայինն են</Text>
        )}
        <Form.Item name={needs}>
          {needOptions.map((option) => {
            const currentNeedData = data.filter((item) => item.needsType === option.id);
            return (
              <React.Fragment key={option.id}>
                <Form.Item name={[needs, option.fieldName]} valuePropName="checked" style={{ margin: 0 }}>
                  <Checkbox value={option.id}>{option.label}</Checkbox>
                </Form.Item>
                {type === APPLICATION_MODE.VIEW &&
                  arePathsEqual(match.pathname, PATHS.RAPID_NEED_VIEW) &&
                  checklist?.[option.fieldName] &&
                  !option?.subItem &&
                  !isLoading && <NeedMatchCard itemData={currentNeedData?.[0]} />}
                {checklist?.[option.fieldName] &&
                  option?.subItem &&
                  React.cloneElement(option.subItem, {
                    parentFormItem: [needs, option.fieldName],
                    itemData: currentNeedData,
                    isView: type === APPLICATION_MODE.VIEW,
                  })}
                {checklist?.[option.fieldName] && option?.hasComment && (
                  <Form.Item name={[needs, option.fieldName, 'other']} rules={[{ required: true }]}>
                    <TextArea />
                  </Form.Item>
                )}
              </React.Fragment>
            );
          })}
        </Form.Item>
      </VerticalSpace>
    </FormSectionCard>
  );
};
