import { DatePicker as DatePickerComponent } from 'antd';
import styled from 'styled-components';
import hy from 'antd/locale/hy_AM';
import 'dayjs/locale/hy-am';
import dayjs from 'dayjs';

const dateFormat = 'DD/MM/YYYY';
const mask = '__/__/____';

dayjs.locale('hy-am');

const StyledDatePicker = styled(DatePickerComponent)`
  &.ant-picker-disabled {
    .ant-picker-input > input {
      color: #091024; /* Change this to your desired color */
    }
  }
`;

export const Datepicker = (props) => (
  <StyledDatePicker locale={hy} format={dateFormat} placeholder={mask} {...props} />
);
